// @import "../../style/";

.appHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  height: 72px;

  .drop_down_img {
    transform: rotate(180deg);
    cursor: pointer;
  }

  .logo {
    max-width: 131px;
    height: 25px;
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    .search {
      z-index: 2;
      display: flex;
      width: 18rem;
      height: 40px;
      position: relative;
      transition: 0.3s ease-in linear;
      .searchBox {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;
        padding-right: 1rem;
        font-size: 16px;
        border: 1px solid #98a29d;
        border-radius: 2px;
        &:focus {
          outline: none;
        }
      }
      .searchResult {
        position: absolute;
        top: 45px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 5px 5px rgba(8, 32, 23, 0.03),
          0px 14px 14px rgba(8, 32, 23, 0.04),
          0px 34px 33px rgba(8, 32, 23, 0.04);
        padding: 0.4rem 12px;
        .propertyName {
          display: flex;
          padding: 8px;
          border-radius: 2px;
          gap: 1rem;
          align-items: center;
          margin-bottom: 10px;
          &:hover {
            cursor: pointer;
            background: #fafbfc;
          }
        }
      }
    }
    .search_overlay{
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .notification-btn {
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
    box-shadow: none;
    .ant-badge-dot {
      width: 10px;
      min-width: 10px;
      height: 10px;
      background: #ff4d4f;
    }
  }

  .userName {
    text-transform: capitalize;
    margin: 0 10px 0 24px;
    font-size: 16px;
    font-weight: 500;
    color: #42526e;
  }

  .salitaion-btn {
    margin-left: 10px;
  }
  .header_profile_img {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
  }
  .header_profile_drop_down_contaner {
    position: relative;
    a {
      text-decoration: none;
      color: #082017 !important;
    }
    .header_profile_drop_down {
      z-index: 10;
      position: absolute;
      background: #ffffff;
      border-radius: 0px;
      top: 56px;
      right: -36px;
      filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03))
        drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
        drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
      .upper_body {
        text-align: center;
        padding: 24px 16px;
        img {
          height: 72px;
          width: 72px;
          border-radius: 50%;
          margin-bottom: 16px;
        }
        .name {
          font-weight: 600;
          font-size: 20px;
        }
        .email {
          font-weight: 500;
          font-size: 14px;
          opacity: 0.5;
          margin-bottom: 18px;
        }
        .mng_btn {
          border: 1px solid #082017;
          border-radius: 2px;
          width: 214px;
          height: 36px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .lower_body {
        .user_options {
          cursor: pointer;
          padding: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          font-weight: 500;
          font-size: 14px;
          border: 0.8px solid rgba(8, 32, 23, 0.1);
        }
      }
    }
  }
  .header_notification_drop_down_contaner {
    position: relative;
    a {
      text-decoration: none;
      color: #082017 !important;
    }
    .header_notification_drop_down {
      z-index: 10px;
      position: absolute;
      background: #ffffff;
      border-radius: 0px;
      top: 37px;
      right: 60px;
      filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03))
        drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
        drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
      .notification_card {
        width: 468px;
        padding: 16px;
        display: flex;
        gap: 16px;
        border: 0.3px solid #98a29d;
        .card_body {
          padding: 0;
          flex-grow: 2;
          .header {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            color: #082017;
            opacity: 0.5;
            margin-bottom: 8px;
          }
          .title,
          .view_request {
            font-weight: 600;
            font-size: 16px;
            color: #082017;
          }
          .title {
            margin-bottom: 8px;
          }
          .view_request {
            color: #80a07d;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.overlay_heaer {
  position: fixed;
  top: 0;
  z-index: 7;
  left: 0;
  height: 100%;
  width: 100%;
}
