.drop_down_container {
  position: relative;
  .drop_down_selected {
    padding: 11px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #082017;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    .rotate_icon {
      transform: rotate(180deg);
    }
    &.dd_active {
      opacity: 1;
      border: 1px solid #80a07d;
    }
  }
  .drop_down_box {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(-8px 8px 16px rgba(0, 0, 0, 0.08));
    border-radius: 4px;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    z-index: 10;
    .scorll_box {
      height: 20rem;
      overflow: scroll;
      &::-webkit-resizer {
        height: 20px;
      }
      overflow-x: hidden;
    }
    .menu {
      padding: 12px;
      padding-right: 6px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      gap: 8px;
      color: #98a29d;
      cursor: pointer;
      .active_circle {
        display: none;
      }
      .inactive_circle {
        display: block;
      }
      &.active {
        color: #082017;
        .active_circle {
          display: block;
        }
        .inactive_circle {
          display: none;
        }
      }
    }
    .divider {
      margin: 0 12px;
      height: 0.3px;
      background-color: #98a29d;
    }
    .clear {
      padding: 12px;
      padding-right: 1rem;
      text-align: right;
      font-weight: 500;
      font-size: 16px;
      color: #082017;
      border-top: 0.3px solid #98a29d;
      cursor: pointer;
    }
  }
  .overlay {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;  z-index: 9;
  }
}
