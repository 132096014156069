.visit_cards {
  position: relative;
  width: 48%;
  padding: 24px 24px;
  // margin-top: 24px;
  height: 330px;
  background: rgba(201, 216, 223, 0.1);
  border-radius: 4px;
  border: 1px solid #c9d8df;
  .vist_head {
    display: flex;
    justify-content: space-between;
    .head_left {
      display: flex;
      gap: 12px;
      .profile_icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        background: #e5dbd3;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .head_name {
        position: relative;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        .head_name_part {
          width: 100px;
          position: absolute;
          top: 60%;
          left: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: center;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
          opacity: 0.5;
        }
      }
    }
    .head_right {
      position: relative;
      .menu_btn {
        cursor: pointer;
      }
      .menu_list_show {
        position: absolute;
        z-index: 12;
        top: 28px;
        right: 9px;
        background: #ffffff;
        filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03))
          drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
          drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
        border-radius: 4px;
        .menu_list {
          height: 40px;
          display: flex;
          padding: 16px;
          background: #ffffff;
          border-radius: 0px;

          color: #082017;
          span {
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 15px;
          }
          transition: 0.3s ease-in;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #082017;
          width: 190px;

          &:hover {
            background: rgb(214, 237, 248);
          }
        }
        .divider {
          opacity: 0.1;
          border: 0.8px solid #082017;
        }
      }
    }
  }
  .visit_container {
    margin-top: 32px;
    .container_head {
      font-size: 14px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
    .task {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: hsl(158, 60%, 8%);

      .task {
        margin: 16px 16px 16px 0;
        display: inline-flex;
        color: #08201752;
        gap: 10px;
        align-items: center;
      }
    }
  }
  .visit_footer {
    margin-top: 40px;
    display: flex;
    gap: 100px;
    position: absolute;
    bottom: 7%;
    .footer_left {
      .footer_name {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */

        display: flex;
        align-items: center;
        font-feature-settings: "pnum" on, "lnum" on;

        /* Primary/Deep Forest */

        color: #082017;
      }
      .star_icon {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        .star_container {
          .star_active {
            display: none;
          }
          .star_inactive {
            display: block;
          }
          &.active {
            .star_active {
              display: block;
            }
            .star_inactive {
              display: none;
            }
          }
        }
      }
    }
  }
}

.menu_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;  z-index: 9;
}

.modal_delete {
  text-align: center;
  padding: 8% 8%;
  .modal_header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .modal_content {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 32px;
  }
  .modal_btns {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .cancel,
    .delete {
      cursor: pointer;
      border: 1px solid #082017;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 8px;
    }
    .delete {
      background: #932a24;
      border-radius: 2px;
      color: #ffffff;
    }
  }
}
