.details_tab_main {
  padding: 22px 24px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  .tabs_top_container {
    display: flex;
    align-items: center;
    gap: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 18px;
    margin-bottom: 32px;
    .tabs {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      position: relative;
      cursor: pointer;
      opacity: 0.6;
      &::before {
        content: "";
        width: 0%;
        height: 4px;
        background: #80a07d;
        border-radius: 4px;
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s;
      }
      &.active {
        opacity: 1;
        &::before {
          content: "";
          width: 100%;
          transition: all 0.5s;
        }
      }
    }
  }
}
