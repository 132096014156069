.modal_delete {
  text-align: center;
  padding: 8% 8%;
  .modal_header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .modal_content {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 32px;
  }
  .modal_btns {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .cancel,
    .delete {
      cursor: pointer;
      border: 1px solid #082017;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 8px;
    }
    .delete {
      background: #932a24;
      border-radius: 2px;
      color: #ffffff;
    }
  }
}
