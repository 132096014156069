$p100: #ef6d2a;
$p75: #e06627;
$p50: #d15f25;
$p5: #fff7f4;

$s100: #1bbe75;
$s75: #54ce97;
$s50: #8cdeb9;
$s5: #f3fcf8;

$a100: #572bed;
$a75: #8160f1;
$a50: #aa94f6;
$a5: #eee9fd;

$n125: #42526e;
$n100: #091e42;
$n3: #7a869a;
$n4: #b3bac5;
$n75: #465671;
$n50: #838ea0;
$n5: #f2f3f5;

$e100: #e75547;
$e75: #ed7f75;
$e50: #f3a9a2;
$e5: #fef6f5;

$w100: #fab342;
$w75: #fef5e7;
$w50: #fcd9a0;
$w5: #fffbf5;

$blueLink: #0072e3;
$lightBlue: #e6f1fc;
$blueBorder: #cce3f9;
$darkBlueBorder: #7793d9;
$themeColor: #f2960d;
$textColor: #42526e;
$darkTextColor: #091e42;
$lightGrayColor: #082017;
$labelGrayColor: #5e6c84;
$borderColor: #dfe1e6;
$backgroundColor: #f4f5f7;

$colors: (
  n125: $n125,
  n100: $n100,
  n75: $n75,
  n50: $n50,
  n5: $n5,
  p100: $p100,
  p75: $p75,
  p50: $p50,
  p5: $p5,
  a100: $a100,
  a75: $a75,
  a50: $a50,
  a5: $a5,
  s100: $s100,
  s75: $s75,
  s50: $s50,
  s5: $s5,
  e100: $e100,
  e75: $e75,
  e50: $e50,
  e5: $e5,
  w100: $w100,
  w75: $w75,
  w50: $w50,
  w5: $w5,
  themeColor: $themeColor,
  blueLink: $blueLink,
  lightGrayColor: $lightGrayColor,
  darkTextColor: $darkTextColor,
  backgroundColor: $backgroundColor,
);

$white: #ffffff;

$weights: (400, 500, 600, 700, 900);

$headerHeight: 64px;

$font-sizes: (
  "32": 32,
  "24": 24,
  "20": 20,
  "18": 18,
  "16": 16,
  "14": 14,
  "12": 12,
  "10": 10,
);

$button-colors: (
  "primary": (
    "0": $p100,
    "1": $p50,
  ),
  "accent": (
    "0": $a100,
    "1": $a50,
  ),
  "warning": (
    "0": $w100,
    "1": $w50,
  ),
  "danger": (
    "0": $e100,
    "1": $e50,
  ),
);

$radius: 24px;
$default-weight: 400;

$transition: all 0.3s linear;

$shadow-large: 0 0 32px rgba($n100, 0.16);
$shadow-default: 0 12px 24px rgba($n100, 0.06);

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number/($number * 0 + 1);
  }

  @return $number;
}

@mixin styled-scrollbar($thumbColor: transparent, $trackColor: transparent, $width: 0px) {
  scrollbar-width: thin;
  scrollbar-color: $thumbColor $trackColor;

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    width: $width;
    background-color: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
    border-radius: 2px;
  }
}
