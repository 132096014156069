.gethelpmodal_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
  background: rgba(201, 216, 223, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  .get_help_modal {
    background: #f8f8f7;
    border-radius: 4px;
    width: 720px;

    padding: 24px 40px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 40px;
      font-family: 'FreightDisp Pro';
      cursor: pointer;
      img {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
    .action_container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 40px;
      .btn {
        padding: 10px 25px;
        border: 1px solid rgba(8, 32, 23, 0.2);
        border-radius: 2px;
        color: #082017;
        opacity: 0.5;
        background-color: #fff;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        &.active {
          background: #80a07d;
          color: #ffff;
          border: 1px solid #80a07d;
          border-radius: 2px;
          opacity: 1;
        }
      }
    }

    .body {
      height: 300px;
      // overflow-y: scroll;
      .accordian {
        margin-top: 24px;
        width: 592px;
        cursor: pointer;
        .header {
          margin-bottom: 12px;
          .text {
            font-weight: 600;
            font-size: 20px;
          }
          .minus_btn {
            display: none;
          }
        }
        .accordian_body {
          width: 530px;
          font-weight: 500;
          font-size: 18px;
          display: none;
        }
        &.active {
          .accordian_body {
            display: block;
          }
          .minus_btn {
            display: block;
          }
          .plus_btn {
            display: none;
          }
        }
        .divider {
          margin-top: 24px;
          width: 100%;
          border: 1px solid #082017;
          opacity: 0.2;
        }
      }
    }
  }
}
