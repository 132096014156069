.modal_container {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #082017;
    opacity: 0.5;
    z-index: 10;
  }
  padding: 30px;
  .modal_add_all_booking {
    position: fixed;
    height: 80%;
    overflow: scroll;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 4px;
    width: 496px;
    z-index: 12;
    padding: 24px 40px;

    .header {
      position: sticky;
      top: -25px;
      height: 60px;
      z-index: 999;
      background-color: #fff;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close_btn {
        cursor: pointer;
      }
    }

    .body {
    }
  }
}
