.owner_card {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 24px;
  cursor: pointer;
  /* Secondary/Khaki */
  border: 1px solid #c4b193;
  border-radius: 4px;
  .date_section {
    position: relative;
    width: 125px;
    .from {
      margin-bottom: 27px;
      position: relative;
      .line_down {
        position: absolute;
        content: "";
        opacity: 0.8;
        height: 28px;
        width: 1px;
        border: 0.5px dashed #082017;
        left: 6px;
      }
    }
    .date {
      font-weight: 600;
      font-size: 20px;
    }
    .year {
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .circle {
      position: relative;
      display: block;
      height: 12px;
      width: 12px;
    }
    .line {
      position: absolute;
      height: 100%;
      border-right: 1px solid #082017;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.06;
    }
    .circle_up,
    .circle_down {
      content: "";
      position: absolute;
      width: 17.9px;
      height: 17.9px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #c4b193;
    }
    .circle_up {
      top: -33px;
      right: -8px;
      &::before {
        position: absolute;
        content: "";
        width: 20px;
        height: 21px;
        background-color: #fff;
        top: -14px;
        left: -2px;
      }
    }
    .circle_down {
      bottom: -33px;
      right: -8px;
      &::before {
        position: absolute;
        content: "";
        width: 20px;
        height: 21px;
        background-color: #fff;
        bottom: -14px;
        left: -2px;
      }
    }
  }
  .owner_info {
    margin-left: 24px;
    .container_detail {
      .header {
        font-weight: 600;
        font-size: 16px;
        span {
          text-transform: capitalize;
        }
      }
      .para {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .divider {
      opacity: 0.5;
      border: 0.5px dashed #321712;
      width: 100%;
      margin: 16px auto;
    }
  }
}

.reason {
  margin-top: 31px;
  display: block;
  align-items: center;
  .reason_left {
    float: left;
    width: calc(100% - 90px);
    .reason_head {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
    .reason_content {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      width: 70%;
      color: #082017;
    }
    .reason_time {
      font-weight: 400;
      margin-top: 8px;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
  }
  .reason_right {
    float: right;
    width: 90px;
    .edit_btn {
      height: 40px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      line-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      color: #082017;
      border: 1px solid #082017;
      border-radius: 2px;
    }
  }
}
