.peroperty_detail_tab {
  display: flex;
  height: 400px;

  .side_tabs {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 13px;
    min-width: 190px;
    .tab {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 10px 8px;
      background: #fff;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
      &.active {
        background: rgba(128, 160, 125, 0.2);
      }
    }
  }
  .side_tab_body {
    width: 100%;
  }
}
