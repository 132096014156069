.home_container {
  display: flex;
  justify-content: space-between;
  .home_left_part {
    margin-left: 40px;
    width: 59%;
    margin-top: 40px;
    .home_heading {
      font-weight: 600;
      font-size: 32px;
      line-height: 150%;
      font-family: "FreightDisp Pro";
    }
    .home_sub_heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: rgba(8, 32, 23, 0.6);
      .home_sub_span {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
      }
    }
    .complaints_card {
      margin-top: 58px;
      width: 100%;
      margin-bottom: 173px;
      .card_header {
        // display: flex;
        // justify-content: space-between;

        margin-top: 70px;

        .card_main_head {
          float: left;
          font-weight: 600;
          font-size: 20px;
          line-height: 100%;
        }
        .card_slider_icons {
          float: right;
          margin-right: 5%;

          display: flex;
          font-weight: 700;
          justify-content: space-between;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
  .home_right_part {
    width: 36%;
    background: #ffffff;
    // height: 180vh;
    overflow-y: scroll;
    .heading_right {
      width: 100%;
      height: 56px;
      padding-left: 24px;
      border-bottom: 0.5px solid rgba(8, 32, 23, 0.1);
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #082017;
    }
    .container {
      display: flex;
      padding: 1.3rem;
      flex-wrap: wrap;
      .checkbox_drop_down {
        display: flex;
        margin-top: 24px;
        margin-bottom: 40px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        .checkbox {
          .choose_view {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            /* identical to box height, or 14px */

            display: flex;
            align-items: center;

            color: #082017;
          }
          .chekbox_list {
            display: flex;
            height: 30px;
            align-items: center;
            gap: 13px;

            .radio_btn_options {
              display: flex;
              align-items: center;
              gap: 10px;
              opacity: 0.5;
              cursor: pointer;
              .radio_btn_input_container {
                .radio_active {
                  display: none;
                }
              }
              .label {
                font-weight: 600;
                font-size: 16px;
                color: #082017;
                padding-top: 4px;
              }
              &.active {
                opacity: 1;
                .radio_active {
                  display: block;
                }
                .radio_inactive {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .booking_container {
    width: 100%;
    max-height: 138vh;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .booking_container_box {
    width: 100%;
    padding-bottom: 50px;
  }
}

.drawer {
  padding: 24px;
  .drawer_address {
    .address {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #082017;
    }
    .sub_address {
      font-weight: 400;
      margin-top: 12px;
      font-size: 16px;
      line-height: 19px;
      color: #082017;
      opacity: 0.5;
    }
  }
  .line {
    margin-top: 31px;
    opacity: 0.2;
    border: 0.3px dashed #082017;
  }
  .property_data {
    .property_booking {
      margin-top: 32px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      font-feature-settings: "pnum" on, "lnum" on;

      /* Primary/Deep Forest */

      color: #082017;
    }
    .booking_name {
      margin-top: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      /* identical to box height, or 27px */

      /* Primary/Deep Forest */

      color: #082017;
    }
    .total_guest {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      font-feature-settings: "pnum" on, "lnum" on;

      /* Primary/Deep Forest */

      color: #082017;
      margin-top: 8px;
      opacity: 0.5;
    }
    .booking_date_head {
      margin-top: 48px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      font-feature-settings: "pnum" on, "lnum" on;

      /* Primary/Deep Forest */

      color: #082017;
    }
    .booking_dates {
      margin-top: 11.61px;
      display: flex;
      align-items: center;
      gap: 16px;
      .start_date {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 27px */

        font-feature-settings: "pnum" on, "lnum" on;

        /* Primary/Deep Forest */

        color: #082017;
      }
      .total_stay {
        display: flex;
        padding: 0 5px;
        align-items: center;
        justify-content: center;
        height: 26.48px;
        left: 1038px;
        top: 445.76px;

        opacity: 0.5;
        /* Primary/Sage */

        border: 0.5px solid #80a07d;
      }
      .end_date {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
      }
    }
    .booking_time {
      margin-top: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
  }
  .special_request_head {
    margin-top: 32px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .special {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      font-feature-settings: "pnum" on, "lnum" on;

      /* Primary/Deep Forest */

      color: #082017;
    }
    .accepted {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Primary/Deep Forest */

      color: #082017;
      gap: 4px;
    }
  }
  .special_request_content {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    width: 85%;
    margin-top: 16px;

    color: #082017;
  }
  .request_time {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-top: 8px;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Primary/Deep Forest */

    color: #082017;

    opacity: 0.5;
  }
}

//footer_scss
.slide_footer_time {
  color: #082017;
  opacity: 0.5;
}
.slide_footer_ticket {
  color: #082017;
  opacity: 0.5;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
}
.swiper-pagination-fraction {
  width: initial !important;
}

.drop_down_custom {
  height: 40px !important;
  padding: 6px 12px !important;
}

.ant-drawer-mask-container {
  .ant-drawer-mask {
    background-color: transparent !important;
  }
}
