@import "./_variables";
@import "./_helpers";
@import "antd/dist/antd.css";
// @import "./mixins/grid";
@include set-font-family;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  font-stretch: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body * {
  font-family: "FreightNeo Pro", Arial, serif;
  font-feature-settings: "pnum" on, "lnum" on;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #082017;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input {
  &::-webkit-input-placeholder {
    color: #98a29d;
  }
  &:focus {
    border: 1px solid #80a07d !important;
    outline: none;
  }
  &:focus-visible {
    border: 1px solid #80a07d !important;
    outline: none;
  }
}
.ant-picker-panel-container {
  display: table;
}
.complaint_card_home {
  width: 100% !important;
  margin-top: 15px !important;
}
.complaint_card_main {
  width: 100% !important;
}
.complaint_card_property {
  width: 100% !important;
}
.request_card_main {
  width: 31.678% !important;
}
.home_request_card {
  margin-top: 15px !important;
}
.ant-drawer {
  top: 72px !important;
  z-index: 2;
}
.ant-drawer-body {
  background-color: #ffff;
}
.ant-drawer .ant-drawer-content {
  background-color: transparent;
  box-shadow: none;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  // box-shadow: none !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.01), -8px 0px 16px rgba(0, 0, 0, 0.02);
}

button {
  cursor: pointer;
}
