.input_group {
  margin-bottom: 32px;
  .title {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 16px;
  }
  .input_field {
    position: relative;
    input {
      padding: 11px 12px;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #e0e2e1;
      border-radius: 2px;
    }
  }
}

.error {
  margin-top: 8px;
  color: red;
  display: block;
}
