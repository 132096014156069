.main_title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
font-size: 32px;
}
.sub_title {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
  color: #082017;
  opacity: 0.5;
}
.edit_email {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .highlight {
    font-weight: 500;
    font-size: 14px;
    color: #80a07d;
    cursor: pointer;
  }
}
.input_container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 50px;
  position: relative;

  .otp_input_box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    .digit_box {
      height: 40px;
      width: 40px;
      border: 1px solid #e0e2e1;
      background-color: transparent;
      padding: 3px;
      text-align: center;
      border-radius: 2px;
      &.error {
        border-color: #932a24;
      }
      &::-webkit-input-placeholder {
        color: #98a29d;
      }
      &:focus {
        border: 1px solid #80a07d !important;
        outline: none;
      }
      &:focus-visible {
        border: 1px solid #80a07d !important;
        outline: none;
      }
    }
    &.error_otp {
      .digit_box {
        border: 1px solid #932a24;
        border-radius: 2px;
        &:focus {
          border: 1px solid #932a24 !important;
          outline: none;
        }
        &:focus-visible {
          border: 1px solid #932a24 !important;
          outline: none;
        }
      }
    }
  }

  .submit_btn {
    width: 347px;
    height: 40px;
    background: #082017;
    border: none;
    outline: none;
    border-radius: 2px;
    padding: 14px auto;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  .status_msg {
    position: absolute;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #80a07d;
  }
  .error {
    position: absolute;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #932a24;
  }
}
.resend_btn {
  cursor: pointer;
}
