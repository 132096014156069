.card_header {
  // display: flex;
  // justify-content: space-between;

  margin-top: 70px;

  .card_main_head {
    float: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
  }
  .card_slider_icons {
    float: right;
    margin-right: 5%;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    cursor: pointer;
    font-size: 16px;
  }
}
.cards_property {
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  .slider-card {
    width: 349px;
    text-align: initial;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: inline-block;
    margin-top: 26px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      height: 233px;
    }
    .slider_bookings {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      background: #ffffff;
      height: 62px;
      border-radius: 2px;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
      padding: 8px 0;
      .slider_data {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .slider_booking_name {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          gap: 9.75px;
          display: flex;
          align-items: center;
          color: rgba(8, 32, 23, 0.8);
          .booking_img {
            width: 14px;
            height: 14px;
          }
        }
      }
      .slider_data_request {
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 6px;
        .slider_booking_name {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          gap: 9.75px;
          display: flex;
          align-items: center;
          color: rgba(8, 32, 23, 0.8);
          .booking_img {
            width: 14px;
            height: 14px;
          }
        }
        border-right: 0.6px solid #08201733;
        border-left: 0.6px solid #08201733;
      }
      .slider_number {
        font-weight: 600;
        font-size: 24px; /* Primary/Deep Forest */

        color: #082017;
      }
    }
    .slider_heading {
      margin-left: 16px;
      margin-top: 16px;
      margin-right: 16px;
      .slider_head {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        align-items: center;
        color: #082017;
        margin-bottom: 8px;
      }
      .slider_sub_head {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        gap: 4.5px;
        align-items: center;
        color: #082017;
        margin-bottom: 8px;
        font-size: 14px;
        color: #082017;
        display: flex;
        align-items: center;
        .geo_location_pin {
          height: 12px;
          width: 12px;
          opacity: 1;
        }
        .place_name {
          opacity: 0.5;
        }
      }
    }
    .slider_footer {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      height: auto;
      border-top: 1px solid #08201749;
      .footer_care_taker {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #082017;
        opacity: 0.5;
        margin-top: 16px;
      }
      .footer_detail {
        display: flex;
        justify-content: space-between;
        .footer_care_taker_name {
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;
          color: #082017;
        }
        .footer_care_taker_num {
          display: flex;
          align-items: center;
          gap: 5.33px;
          .footer_img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
