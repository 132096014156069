.header_notification_drop_down_contaner {
  position: relative;
  a {
    text-decoration: none;
    color: #082017 !important;
  }
  .header_notification_drop_down {
    max-height: 365px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 8;
    position: absolute;
    background: #ffffff;
    border-radius: 0px;
    top: 37px;
    right: 60px;
    .noNotiData {
      width: 200px;
      margin-top: 30%;
      margin-bottom: 30%;
      padding-left: 38px;
      font-size: 20px;
      font-weight: 600;
    }
    filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03)) drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
      drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
    .notification_card {
      width: 468px;
      padding: 16px;
      display: flex;
      gap: 16px;
      border: 0.3px solid #98a29d;
      .card_body {
        padding: 0;
        flex-grow: 2;
        .header {
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          font-size: 14px;
          color: #082017;
          opacity: 0.5;
          margin-bottom: 8px;
        }
        .title,
        .view_request {
          font-weight: 600;
          font-size: 14px;

          color: #082017;
        }
        .title {
          margin-bottom: 8px;
        }
        .view_request {
          color: #80a07d;
          cursor: pointer;
          span {
            transition: 3s ease-in linear;
            &:hover {
              border-bottom: 2px solid #80a07d;
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      color: #80a07d;
      font-weight: 400;
      font-size: 14px;
      span {
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #80a07d;
        }
      }
    }
  }
}
