.body {

  .accordian {
    margin-top: 24px;
    width: 60%;
    cursor: pointer;
    .header {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
      .text {
        font-weight: 600;
        font-size: 20px;
      }
      .minus_btn{
        display: none;
      }
    }
    .accordian_body {
      width: 530px;
      font-weight: 500;
      font-size: 18px;
      display: none;
    }
    &.active{
        .accordian_body{
            display: block;
        }
        .minus_btn{
            display: block;
          }
        .plus_btn{
            display: none;
        }
    }
    .divider{
        margin-top: 24px;
        width: 100%;
        border: 1px solid #082017;
        opacity: 0.2;
    }
  }
}