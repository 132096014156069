.drop_down_container {
  position: relative;
  width: 100%;
  .drop_down_selected {
    width: 100%;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #80a07d;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    .rotate_icon {
      transform: rotate(180deg);
    }
    opacity: 0.5;
    color: #082017;
    .rotate_icon {
      transform: rotate(180deg);
    }
    &.dd_active {
      opacity: 1;
    }
  }
  .drop_down_box {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
      drop-shadow(-8px 8px 16px rgba(0, 0, 0, 0.08));
    border-radius: 4px;
    padding: 12px;
    padding-right: 6px;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    z-index: 10;
    .menu {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      gap: 8px;
      cursor: pointer;
      .active_circle {
        display: none;
      }
      .inactive_circle {
        display: block;
      }
      &.active {
        .active_circle {
          display: block;
        }
        .inactive_circle {
          display: none;
        }
      }
    }
    .divider {
      margin: 12px 0;
      height: 0.3px;
      background-color: #98a29d;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;  z-index: 9;
  }
}
