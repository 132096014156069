.request_card {
  .filter_container {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .property_detail_dd {
    // opacity: 0.3;
    padding: 7px 12px !important;
  }
  .cards_request {
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-height: 332px;
    overflow: scroll;
    overflow-x: hidden;
    flex-wrap: wrap;
    width: 101.5%;
    .slider_card {
      padding: 20px 20px;
      width: 438px;
      border: 1px solid #c9d8df;
      border-radius: 4px;
      background: rgba(201, 216, 223, 0.1);
      cursor: pointer;
      .slide_heading {
        display: flex;
        justify-content: space-between;
        .slide_header {
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
          .header_side_part {
            padding-left: 9px;
            width: 60px;
            border-left: 1px solid #0820174a;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
            opacity: 0.7;
          }
        }
        .slide_heading_ticket {
          font-weight: 400;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 100%;
          text-align: right;
          gap: 5px;
          color: #082017;
        }
      }
      .slide_name {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        margin-top: 10px;
        opacity: 0.5;
      }
      .slide_content {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: "pnum" on, "lnum" on;
        margin-top: 24px;
        color: #082017;
      }
    }
  }
}

.request_drawer {
  padding: 24px;
  .ticket {
    display: flex;
    justify-content: space-between;
    .ticket_no {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: right;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
    .ticket_time {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: right;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
  }
  .address_name {
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #082017;
  }
  .sub_address_name {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #082017;
    opacity: 0.5;
  }
  .name {
    margin-top: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #082017;
  }
  .number {
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .request_line {
    border: 0.3px dashed #08201745;
    margin-top: 32px;
  }
  .upcoming {
    margin-top: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .booking_dates {
    margin-top: 16.61px;
    display: flex;
    align-items: center;
    gap: 16px;
    .start_date {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
    .total_stay {
      display: flex;
      padding: 0 5px;
      align-items: center;
      justify-content: center;
      height: 26.48px;
      left: 1038px;
      top: 445.76px;
      opacity: 0.5;
      border: 0.5px solid #80a07d;
    }
    .end_date {
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
  }
  .request_time {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
    margin-top: 8px;
    opacity: 0.5;
  }
  .special_request {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    margin-top: 32px;
    color: #082017;
  }
  .content {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    margin-top: 8px;
    color: #082017;
  }

  .checkbox {
    .choose_view {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      display: flex;
      align-items: center;
      color: #082017;
    }
    .chekbox_list {
      display: flex;
      height: 30px;
      align-items: center;
      .owners {
        position: relative;
        padding: 20px 0 20px 28px;
        text-align: left;
        label {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #082017;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            border-radius: 50%;
          }
          &:before {
            left: 0;
            width: 18px;
            height: 18px;
            margin: -8px 0 0;
            border: 1px solid #80a07d;
          }
          &:after {
            left: 3.5px;
            width: 12px;
            height: 12px;
            margin: -5px 0 0;
            opacity: 0;
            background: #80a07d;
            transform: translate3d(-40px, 0, 0) scale(0.5);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }

        input[type="radio"],
        input[type="checkbox"] {
          position: absolute;
          top: 0;
          left: -9999px;
          visibility: hidden;

          &:checked + label {
            &:after {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
      .property {
        position: relative;
        padding: 20px 0 20px 28px;
        text-align: left;
        margin-left: 10px;

        label {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #082017;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            border-radius: 50%;
          }
          &:before {
            left: 0;
            width: 18px;
            height: 18px;
            margin: -8px 0 0;
            border: 1px solid #80a07d;
          }
          &:after {
            left: 3.5px;
            width: 12px;
            height: 12px;
            margin: -5px 0 0;
            opacity: 0;
            background: #80a07d;
            transform: translate3d(-40px, 0, 0) scale(0.5);
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }

        input[type="radio"],
        input[type="checkbox"] {
          position: absolute;
          top: 0;
          left: -9999px;
          visibility: hidden;

          &:checked + label {
            &:after {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .add_response {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    font-feature-settings: "pnum" on, "lnum" on;
    margin-top: 32px;
    color: #082017;
  }

  .textfield {
    width: 100%;
    height: 190px;
    margin-top: 20px;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #082017;
    opacity: 0.6;
    resize: none;
    padding: 16px;
    font-size: 16px;
  }

  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .requestbuttons {
    width: 190px;
    height: 40px;
  }
}

.modal_content {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  width: 60%;
  color: #082017;
}

.property_detail_date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}

.clear_selected {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-bottom: 1px solid #082017;
  color: #082017;
  opacity: 0.8;
  cursor: pointer;  z-index: 11;
  // width: 100px;
}
