.body {
  height: 300px;
  .contact_container {
    width: 640px;
    color: #082017;
    cursor: pointer;
    .rep_text {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 35px;
    }
    .header {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      .text {
        font-weight: 400;
        font-size: 16px;
      }
      .number {
        a {
          text-decoration: none;
          font-weight: 500;
          font-size: 18px;
          color: #082017;
          display: flex;
          justify-content: center;
          gap: 13px;
        }
      }
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 24px;
      width: 100%;
      border: 1px solid #082017;
      opacity: 0.2;
    }
  }
}
