.reason {
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .reason_left {
    // float: left;
    width: calc(100% - 90px);
    .reason_head {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
    .reason_content {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      width: 70%;
      color: #082017;
    }
    .reason_time {
      font-weight: 400;
      margin-top: 8px;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
  }
  .reason_right {
    // float: right;
    width: 90px;
    .edit_btn {
      height: 40px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      line-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      color: #082017;
      border: 1px solid #082017;
      border-radius: 2px;
    }
  }
}
.special_request {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 32px;
  color: #082017;
}
.response_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  .edit_res {
    padding: 10px 32px;
    font-weight: 600;
    font-size: 16px;
    color: #082017;
    border: 1px solid #082017;
    border-radius: 2px;
    cursor: pointer;
  }
}
.content {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-top: 8px;
  color: #082017;
}

.checkbox {
  margin-top: 1rem;
  .choose_view {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #082017;
  }
  .chekbox_list {
    display: flex;
    height: 30px;
    align-items: center;
    .owners {
      position: relative;
      padding: 20px 0 20px 28px;
      text-align: left;
      label {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #082017;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          border-radius: 50%;
        }
        &:before {
          left: 0;
          width: 18px;
          height: 18px;
          margin: -8px 0 0;
          border: 1px solid #80a07d;
        }
        &:after {
          left: 3.5px;
          width: 12px;
          height: 12px;
          margin: -5px 0 0;
          opacity: 0;
          background: #80a07d;
          transform: translate3d(-40px, 0, 0) scale(0.5);
          transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: -9999px;
        visibility: hidden;

        &:checked + label {
          &:after {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }
      }
    }
    .property {
      position: relative;
      padding: 20px 0 20px 28px;
      text-align: left;
      margin-left: 10px;

      label {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #082017;
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          border-radius: 50%;
        }
        &:before {
          left: 0;
          width: 18px;
          height: 18px;
          margin: -8px 0 0;
          border: 1px solid #80a07d;
        }
        &:after {
          left: 3.5px;
          width: 12px;
          height: 12px;
          margin: -5px 0 0;
          opacity: 0;
          background: #80a07d;
          transform: translate3d(-40px, 0, 0) scale(0.5);
          transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        }
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: -9999px;
        visibility: hidden;

        &:checked + label {
          &:after {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }
      }
    }
  }
}

.add_response {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 32px;
  color: #082017;
}

.textfield {
  width: 100%;
  height: 190px;
  margin-top: 20px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #082017;
  opacity: 0.6;
  resize: none;
  padding: 16px;
  font-size: 16px;
}

.buttongroup {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    font-weight: 600;
    font-size: 16px;
    background-color: #fff;
    cursor: pointer;
  }
}
.requestbuttons {
  width: 190px;
  height: 40px;
}

.requestbuttons {
  width: 190px;
  height: 40px;
}
.drawer_header {
  display: flex;
  gap: 12px;
  align-items: center;
  border: 0.5px solid rgba(8, 32, 23, 0.1);
  font-weight: 600;
  font-size: 20px;
  padding: 24px;
  .title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }
  .close_btn {
    i {
      font-size: 14px;
    }

    cursor: pointer;
  }
}
