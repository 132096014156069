.property_info {
  .input_group {
    margin-bottom: 32px;
    .title {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 16px;
    }
    .input_field {
      position: relative;
      input,
      textarea {
        padding: 11px 12px;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #e0e2e1;
        border-radius: 2px;
      }
      textarea {
        min-height: 50px;
        height: auto;
      }
      button {
        padding: 8px 0px;
        border: 1px solid #082017;
        border-radius: 2px;
        text-align: center;
        width: 100%;
        background: #fff;
        cursor: pointer;
      }
      &.flex_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        input {
          width: 70%;
        }
        button {
          width: 30%;
          background-color: transparent;
        }
      }
      .select_input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
      }
      .select_box {
        height: 91px;
        padding: 8px 0px;
        border: 1px dashed #082017;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .property_detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 9px auto;
        .img_box_uplaod {
          position: relative;
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed #e0e2e1;
          border-radius: 2px;
          font-size: 16px;
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        .detail_name {
          width: 183px;
          height: 44px;
          padding: 6px 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 1px solid #e0e2e1;
          border-radius: 2px;
          font-weight: 500;
          font-size: 16px;
        }
        input {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px 12px;
          gap: 251px;
          width: 72px;
          height: 44px;
          border: 1px solid #e0e2e1;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
        }
        .cross {
          font-weight: 400;
          font-size: 12px;
        }
        .remove {
          font-weight: 600;
          font-size: 16px;
          width: 54px;
          height: 18px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .custom_dd {
    padding: 11px 12px;
  }
  .highlight {
    color: #082017;
  }
}
.location_btn_grp {
  border-top: 0.8px solid rgba(0, 0, 0, 0.15);
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.15);
  padding: 30px 0;
  margin-bottom: 32px;
  .location_title {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .location_title.active {
    font-weight: 600;
    font-size: 18px;
  }
  button {
    margin-top: 12px;
    border: 1px solid #082017;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 0;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
  }
}
.error {
  margin-top: 8px;
  color: red;
  display: block;
}
