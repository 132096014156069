.upcoming_mainte {
  width: 100%;
  .dropdown_groups {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .add_monthly_btn {
      width: 180px;
      margin-right: 20px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #082017;
    }
  }
  .vist_card_group {
    margin-top: 24px;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 360px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .visit_cards {
      width: 48%;
      padding: 24px 0;
      margin-top: 24px;
      height: 330px;
      background: rgba(201, 216, 223, 0.1);
      border-radius: 4px;
      border: 1px solid #c9d8df;
      .vist_head {
        display: flex;
        padding: 0 24px;
        justify-content: space-between;
        height: 70px;
        border-bottom: 0.8px solid #08201738;
        .head_left {
          display: flex;
          gap: 12px;
          .profile_icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            background: #e5dbd3;
            align-items: center;
            justify-content: center;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .head_name {
            position: relative;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
            .head_name_part {
              width: 100px;
              position: absolute;
              top: 43%;
              left: 0;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              display: flex;
              align-items: center;
              font-feature-settings: "pnum" on, "lnum" on;
              color: #082017;
              opacity: 0.5;
            }
          }
        }
        .head_right {
          position: relative;
          .menu_btn {
            cursor: pointer;
          }
          .menu_list_show {
            position: absolute;
            z-index: 12;
            top: 40px;
            left: -170px;
            .menu_list {
              height: 40px;
              display: flex;
              padding: 16px;
              span {
                display: flex;
                cursor: pointer;
                align-items: center;
                gap: 15px;
              }
              transition: 0.3s ease-in;
              font-weight: 500;
              font-size: 18px;
              line-height: 17px;
              text-align: center;
              color: #082017;
              width: 190px;
              background: #ffffff;
              border-bottom: 1px solid #0000002e;
              &:hover {
                background: rgb(214, 237, 248);
              }
            }
          }
        }
      }
      .visit_container {
        margin-top: 24px;
        margin-left: 24px;
        padding: 22px 24px;
        width: 90%;
        background: rgba(201, 216, 223, 0.2);
        border-radius: 4px;
        height: 90px;
        display: flex;
        justify-content: center;
        gap: 10px;
        .upcoming_ {
          width: 100%;
          border-right: 1px solid #08201747;
          .name {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            display: flex;
            align-items: center;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
            opacity: 0.8;
          }
          .date {
            margin-top: 16px;
            display: flex;
            gap: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
          }
        }
        .contact {
          width: 100%;
          .name {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            /* identical to box height, or 14px */
            display: flex;
            align-items: center;
            font-feature-settings: "pnum" on, "lnum" on;
            /* Primary/Deep Forest */
            color: #082017;
            opacity: 0.8;
          }
          .number {
            display: flex;
            gap: 10px;
            margin-top: 16px;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
          }
        }
      }
      .visit_footer {
        margin-top: 30px;
        padding: 0 24px;
        gap: 16px;
        display: flex;
        .footer_left {
          width: 200px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #082017;
          border-radius: 2px;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #082017;
        }
        .footer_right {
          cursor: pointer;
          width: 200px;
          height: 40px;
          border-radius: 2px;
          background: #082017;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.check_box_dd {
  padding: 6px 12px !important;
}
.modal_head {
  font-size: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 135.19%;
  color: #082017;
}
.modal_content {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #082017;
}
.group_btn {
  margin-top: 32px;
  display: flex;
  gap: 12px;
  .cancel_btn {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 2px;
    cursor: pointer;
  }
  .com_btn {
    cursor: pointer;
    border-radius: 2px;
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #082017;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
  }
}
.group_btn_skip {
  margin-top: 32px;
  display: flex;
  gap: 12px;
  .cancel_btn {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 2px;
    cursor: pointer;
  }
  .com_btn {
    cursor: pointer;
    border-radius: 2px;
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #932a24;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
  }
}

.date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}
.service_dd {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
}
