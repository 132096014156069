.setting_contact_section {
  display: flex;
  gap: 10%;
  .left {
    width: 40%;
    .data_section {
      margin-bottom: 32px;
      .header {
        img {
          height: 24px;
          width: 24px;
        }
        font-weight: 600;
        font-size: 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
      }
      .data_body {
        .data_details,
        .mail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .data {
            font-weight: 500;
            font-size: 18px;
          }
          border-bottom: 0.8px solid rgba(8, 32, 23, 0.1);
          padding: 16px;
        }
        .mail {
          border: none;
        }
      }
    }
    .social_connection {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .right {
    width: 60%;
    margin-right: 70px;
    .msg_box_container {
      padding: 48px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));
      max-width: 100%;
      background-color: #fff;
      .header {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 32px;
      }
      .msg_text_area {
        color: #98a29d;
        font-weight: 400;
        font-size: 16px;
        padding: 8px 12px;
        border: 1px solid #e0e2e1;
        border-radius: 2px;
        height: 143px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .action_btn_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        .action_btn,
        .action_btnblk {
          font-weight: 600;
          font-size: 16px;
          border: 1px solid #082017;
          border-radius: 2px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 111px;
          height: 40px;
          cursor: pointer;
        }
        .action_btnblk{
            color: #fff;
            background-color: #082017;
        }
      }
    }
  }
}
