.booking_history {
  .filter_container {
    width: 97%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
    }
  }
  .property_detail_dd {
    opacity: 1;
    padding: 7px 12px !important;
  }
  .card {
    width: 98%;
    padding: 24px;
    border-radius: 2px;
    background: rgba(201, 216, 223, 0.1);
    border: 1px solid #c9d8df;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    .left {
      .upper {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 20px;
        .date {
          font-weight: 600;
          font-size: 20px;
        }
        .highpeek {
          display: flex;
          align-items: center;
          gap: 5px;
          .dot {
            border-radius: 50%;
            height: 10px;
            width: 10px;
            background: #932a24;
          }
          font-weight: 400;
          font-size: 14px;
        }
        .peek {
          display: flex;
          align-items: center;
          gap: 5px;
          .dot {
            border-radius: 50%;
            height: 10px;
            width: 10px;
            background: #937924;
          }
          font-weight: 400;
          font-size: 14px;
        }
      }
      .lower {
        font-weight: 600;
        font-size: 16px;
        opacity: 0.7;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 29px;
      .booking_detail {
        text-align: right;
        .status {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 16px;
          &.confirmed {
            color: #80a07d;
          }
          &.cancelled {
            color: #932a24;
          }
        }
        .booking_date {
          font-weight: 500;
          font-size: 16px;
          color: rgba(8, 32, 23, 0.6);
          span {
            color: rgba(8, 32, 23, 1);
          }
        }
      }
      i {
        color: #082017;
      }
    }
  }
}
.booking_history_card_container {
  max-height: 332px;
  overflow: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
}
.property_detail_date_picker {
  color: #98a29d !important;
  border: 1px solid #e0e2e1 !important;
  border-radius: 2px !important;
  width: 175px !important;
  padding: 8px 12px !important;
}
.clear_selected {
  font-style: normal;  z-index: 11;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-bottom: 1px solid #082017;
  color: #082017;
  opacity: 0.8;
  cursor: pointer;
}
