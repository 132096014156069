.cards_complaints {
  text-align: initial;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  padding-right: 1px;
  &.complaint_card_main {
    width: 32%;
  }
  &.complaint_card_property {
    width: calc(50% - 10px);
    border: 1px solid #c9d8df;
    border-radius: 4px;
  }
  .slider_card {
    width: 363.96px;
    height: 210px;
    padding-left: 25px;
    padding-right: 20px;
    padding-top: 20px;

    background: #ffffff;
    box-shadow: 3px 1px 4px #eee;
    border-radius: 4px;
    display: inline-block;
    margin: 4px 2px 4px 0px;
    .slide_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      .slide_header {
        max-width: 70%;
        border-radius: 2px;
        padding: 6px 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        background: #ede6e0;
      }
      .slide_ticket {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .slide_content {
      max-width: 309px;
      width: 100%;
      height: 66px;
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      white-space: normal;
    }
    .slide_footer {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .slide_footer_left {
        max-width: 80%;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: scroll;
        .slide_footer_property_name {
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
          margin-bottom: 8px;
        }
        .slide_footer_name {
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          /* identical to box height, or 16px */

          font-feature-settings: "pnum" on, "lnum" on;

          /* Primary/Deep Forest */

          color: #082017;

          opacity: 0.5;
        }
      }
      .slide_footer_right {
        font-weight: 500;
        font-size: 16px;
        .slide_footer_time {
          color: #082017;
          opacity: 0.5;
        }
        .slide_footer_ticket {
          color: #082017;
          opacity: 0.5;
        }
      }
    }
  }
}
