.ant-tooltip-inner {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  background: #082017;
  border-radius: 8px;
  box-shadow: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #082017;
  box-shadow: none;
}
