.user_management {
  .header {
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .left {
      .property_owner {
        font-weight: 600;
        font-size: 20px;
      }
    }
    .right {
      .add_owner {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border: 1px solid #082017;
        border-radius: 2px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    width: 100%;
    height: 478px;
    // gap: 4%;
  }
}
