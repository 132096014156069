.drawer_body_outer {
  position: relative;
  height: 100%;
  // box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%);
  background-color: #fff;
}
.drawer_body {
  height: 94vh;
  overflow-y: scroll;
  padding-bottom: 40px;

  .drawer_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid rgba(8, 32, 23, 0.1);
    padding: 22px 24px;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
    .close_btn {
      width: 24px;
      height: 24px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .tabs_container {
    padding: 5px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    .tabs {
      position: relative;
      cursor: pointer;
      color: #082017;
      opacity: 0.5;
      &::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        background: #082017;
        width: 0;
        transition: all 0.5s;
      }
      &.active_tab {
        font-weight: 600;
        opacity: 1;
        &::before {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          background: #082017;
          width: 100%;
          transition: all 0.5s;
        }
      }
    }
  }

  .property_tabpanel {
    display: none;
    padding: 24px;
    &.active_tab_pannel {
      display: block;
    }
  }
}
.drawer_footer {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  padding-bottom: 16px;
  gap: 16px;
  position: sticky;
  width: 100%;
  bottom: -25px;
  border-top: 1px solid #f0f0f0;
  padding-top: 15px;
  background: #fff;
  button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #082017;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .next_btn {
    background: #082017;
    color: #fff;
  }
}
