.profileimg_modal_container {
  .profileimg_modal {
    text-align: center;
    padding: 48px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 4px;
    width: 638px;
    z-index: 10;
    .header {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 32px;
    }
    .img_box {
      margin: 0 auto;
      width: 200px;
      height: 200px;
      margin-bottom: 24px;
      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .drag_drop_text {
      color: #082017;
      opacity: 0.5;
      font-weight: 400;
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 24px;
      width: 70%;
    }
    .or {
      margin: 0 auto;
      width: 310px;
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;
      .line {
        opacity: 0.2;
        width: 100%;
        border: 1px solid #082017;
      }
      .text {
        font-weight: 500;
        font-size: 20px;
        color: #082017;
        opacity: 0.4;
      }
    }
    .action_btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .btn,
      .btnblk,
      .btnupld {
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        border: 1px solid #082017;
        border-radius: 2px;
        width: 170px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btnblk {
        color: #ffff;
        background: #082017;
        border-radius: 2px;
      }
      .btnupld {
        background: #082017;
        border-radius: 2px;
        width: 266px;
        height: 40px;
        color: #ffff;
        background: #082017;
        border-radius: 2px;
      }
    }
    .close_icon {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }
  .overlay {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #082017;
    opacity: 0.5;
  }
}
