.login_page {
  height: 100vh;
  display: flex;
  align-items: center;
  .container_left {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f8f8f7;
    backdrop-filter: blur(4px);
    .main_title {
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 16px;
    }
    .sub_title {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 56px;
      opacity: 0.5;
    }
    .input_container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      margin-bottom: 50px;
      position: relative;
    }
    .help_container {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .highlight {
        font-weight: 500;
        font-size: 18px;
        color: #80a07d;
        cursor: pointer;
      }
    }
  }
  .container_right {
    height: 100%;
    width: 60%;
    .carousel_context {
      height: 100vh;
      width: 100%;
      background-color: transparent;
      color: black;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
      .context {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 115px;
        color: #f8f8f7;
        text-align: center;
        img {
          display: block;
          margin: 0 auto;
          width: 171px;
          height: 31px;
          margin-bottom: 22px;
        }
        .para {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
}
