.visit_cards {
  width: 48.5%;
  padding: 24px 0;
  // margin-top: 24px;
  height: 330px;

  border-radius: 4px;
  border: 1px solid #c9d8df;
  .vist_head {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    height: 70px;
    border-bottom: 0.8px solid #08201738;
    .head_left {
      display: flex;
      gap: 12px;
      .profile_icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        background: #e5dbd3;
        align-items: center;
        justify-content: center;
      }
      .head_name {
        position: relative;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        .head_name_part {
          width: 100px;
          position: absolute;
          top: 43%;
          left: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: center;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
          opacity: 0.5;
        }
      }
    }
    .head_right {
      position: relative;
      .menu_btn {
        cursor: pointer;
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 9;
        }
      }
      .menu_list_show {
        z-index: 8;
        position: absolute;
        z-index: 12;
        top: 40px;
        left: -170px;
        filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03))
          drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
          drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
        border-radius: 4px;
        .divider {
          opacity: 0.08;
          /* Primary/Deep Forest */

          border: 0.8px solid #082017;
        }
        .menu_list {
          height: 40px;
          display: flex;
          padding: 16px;
          span {
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 15px;
          }
          transition: 0.3s ease-in;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #082017;
          width: 190px;
          background: #ffffff;

          &:hover {
            background: rgb(214, 237, 248);
          }
        }
      }
    }
  }
  .visit_container {
    margin-top: 24px;
    margin-left: 24px;
    padding: 22px 24px;
    width: 90%;
    background: rgba(201, 216, 223, 0.1);
    border-radius: 4px;
    height: 90px;
    display: flex;
    justify-content: center;
    gap: 10px;
    .divider {
      border: 0.5px solid #082017;
      opacity: 0.2;
      margin: 0 20px;
    }
    .upcoming_ {
      width: 100%;

      .name {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        opacity: 0.8;
      }
      .date {
        margin-top: 16px;
        display: flex;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
      }
    }
    .contact {
      width: 100%;
      .name {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */
        display: flex;
        align-items: center;
        font-feature-settings: "pnum" on, "lnum" on;
        /* Primary/Deep Forest */
        color: #082017;
        opacity: 0.8;
      }
      .number {
        display: flex;
        gap: 10px;
        margin-top: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
      }
    }
  }
  .visit_footer {
    margin-top: 30px;
    padding: 0 24px;
    gap: 16px;
    display: flex;
    .footer_left {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #082017;
    }
    .footer_right {
      cursor: pointer;
      width: 100%;
      height: 40px;
      border-radius: 2px;
      background: #082017;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .modal_head {
    font-size: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 135.19%;
    color: #082017;
  }
  .modal_content {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #082017;
  }
  .group_btn {
    margin-top: 32px;
    display: flex;
    gap: 12px;
    .cancel_btn {
      width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
    }
    .com_btn {
      cursor: pointer;
      border-radius: 2px;
      width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      background: #082017;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;
    }
  }
  .group_btn_skip {
    margin-top: 32px;
    display: flex;
    gap: 12px;
    .cancel_btn {
      width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
    }
    .com_btn {
      cursor: pointer;
      border-radius: 2px;
      width: 110px;
      height: 40px;
      display: flex;
      align-items: center;
      background: #932a24;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      color: #ffffff;
    }
  }

  .date_picker {
    color: #98a29d;
    border: 1px solid #e0e2e1;
    border-radius: 2px;
    width: 175px !important;
    padding: 8px 12px;
  }
}

.modal_head {
  font-size: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 135.19%;
  color: #082017;
}
.modal_content {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #082017;
}
.group_btn {
  margin-top: 32px;
  display: flex;
  gap: 12px;
  .cancel_btn {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 2px;
    cursor: pointer;
  }
  .com_btn {
    cursor: pointer;
    border-radius: 2px;
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #082017;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
  }
}
.group_btn_skip {
  margin-top: 32px;
  display: flex;
  gap: 12px;
  .cancel_btn {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 2px;
    cursor: pointer;
  }
  .com_btn {
    cursor: pointer;
    border-radius: 2px;
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #932a24;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
  }
}

.date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}

.modal_delete {
  text-align: center;
  padding: 8% 8%;
  .modal_header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .modal_content {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 32px;
  }
  .modal_btns {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .cancel,
    .delete {
      cursor: pointer;
      border: 1px solid #082017;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 8px;
    }
    .delete {
      background: #932a24;
      border-radius: 2px;
      color: #ffffff;
    }
  }
}
