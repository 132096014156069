.drawer_body {
  .drawer_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid rgba(8, 32, 23, 0.1);
    padding: 22px 24px;
    .title {
      font-weight: 600;
      font-size: 20px;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
    }
    .close_btn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      font-weight: 600;
      font-size: 16px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .body {
    padding: 32px 24px;
    .ticket_details {
      .ticket_box,
      .location {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        opacity: 0.5;
        margin-bottom: 16px;
      }
      .place_name,
      .person_name,
      .contact {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
      }
      .contact {
        font-weight: 500;
      }
      .location {
        margin-bottom: 32px;
      }
      .contact {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 0.3px dashed #082017;
    }
    .complaint_details {
      .type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        .type_name {
          border-radius: 2px;
          padding: 6px 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: #ede6e0;
          font-weight: 400;
          font-size: 14px;
        }
        .status {
          padding: 6px 8px;
          display: inline-flex;
          font-weight: 400;
          font-size: 14px;
          gap: 8px;
          align-items: center;
        }
      }
      .name {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 16px;
      }
      .time {
        font-weight: 400;
        font-size: 16px;
        color: #082017;
        opacity: 0.5;
        margin-bottom: 32px;
      }
      .comment_box {
        .title {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 12px;
        }
        .cmt_box_body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 22px;
          .comment {
            flex-grow: 2;
            font-weight: 500;
            font-size: 18px;
          }
          .edit_btn {
            border: 1px solid #082017;
            border-radius: 2px;
            padding: 12px 32px;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
          }
        }
        .time {
          font-weight: 400;
          font-size: 16px;
          color: #082017;
          opacity: 0.5;
        }
      }
      .response_box {
        .title {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 12px;
        }
        .textfield {
          width: 100%;
          height: 190px;
          margin-top: 20px;
          outline: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #082017;
          opacity: 0.6;
          resize: none;
          padding: 16px;
          font-size: 16px;
        }
      }
    }
  }
  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    button {
      font-weight: 600;
      font-size: 16px;
      background-color: #fff;
      cursor: pointer;
    }
  }
  .requestbuttons {
    width: 190px;
    height: 40px;
  }
}
.drawer_footer {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  padding-bottom: 16px;
  gap: 16px;
  button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #082017;
    border-radius: 2px;
    background-color: transparent;
  }
  .next_btn {
    background: #082017;
    color: #fff;
  }
}

.modal_content {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  width: 60%;
  color: #082017;
}
