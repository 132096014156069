@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?66862677');
  src: url('../font/fontello.eot?66862677#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?66862677') format('woff2'),
       url('../font/fontello.woff?66862677') format('woff'),
       url('../font/fontello.ttf?66862677') format('truetype'),
       url('../font/fontello.svg?66862677#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?66862677#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-new:before { content: '\e800'; } /* '' */
.icon-script:before { content: '\e801'; } /* '' */
.icon-dms:before { content: '\e802'; } /* '' */
.icon-setup:before { content: '\e803'; } /* '' */
.icon-pulse:before { content: '\e804'; } /* '' */
.icon-move:before { content: '\e805'; } /* '' */
.icon-twitter20:before { content: '\e806'; } /* '' */
.icon-horizontal-menu:before { content: '\e807'; } /* '' */
.icon-vertical-menu:before { content: '\e808'; } /* '' */
.icon-obj208:before { content: '\e809'; } /* '' */
.icon-obj209:before { content: '\e80a'; } /* '' */
.icon-obj210:before { content: '\e80b'; } /* '' */
.icon-obj314:before { content: '\e80c'; } /* '' */
.icon-cardview:before { content: '\e80d'; } /* '' */
.icon-tabview:before { content: '\e80e'; } /* '' */
.icon-graphchange2:before { content: '\e80f'; } /* '' */
.icon-manageview:before { content: '\e810'; } /* '' */
.icon-rss:before { content: '\e811'; } /* '' */
.icon-comment:before { content: '\e812'; } /* '' */
.icon-favourite3:before { content: '\e813'; } /* '' */
.icon-gridview:before { content: '\e814'; } /* '' */
.icon-listview:before { content: '\e815'; } /* '' */
.icon-notes:before { content: '\e816'; } /* '' */
.icon-bulk:before { content: '\e817'; } /* '' */
.icon-demographic:before { content: '\e818'; } /* '' */
.icon-configure:before { content: '\e819'; } /* '' */
.icon-advanced:before { content: '\e81a'; } /* '' */
.icon-geography:before { content: '\e81b'; } /* '' */
.icon-customer:before { content: '\e81c'; } /* '' */
.icon-reject:before { content: '\e81d'; } /* '' */
.icon-test:before { content: '\e81e'; } /* '' */
.icon-format_painter:before { content: '\e81f'; } /* '' */
.icon-fullscreen:before { content: '\e820'; } /* '' */
.icon-applicationmanagement:before { content: '\e821'; } /* '' */
.icon-customize:before { content: '\e822'; } /* '' */
.icon-manage:before { content: '\e823'; } /* '' */
.icon-obj403:before { content: '\e824'; } /* '' */
.icon-process:before { content: '\e825'; } /* '' */
.icon-Area:before { content: '\e826'; } /* '' */
.icon-Barometer:before { content: '\e827'; } /* '' */
.icon-Composite:before { content: '\e828'; } /* '' */
.icon-custom-menu:before { content: '\e829'; } /* '' */
.icon-Menu:before { content: '\e82a'; } /* '' */
.icon-StackBar:before { content: '\e82b'; } /* '' */
.icon-StackColumn:before { content: '\e82c'; } /* '' */
.icon-drag-01:before { content: '\e82d'; } /* '' */
.icon-next2:before { content: '\e82e'; } /* '' */
.icon-palettesstylestab-01:before { content: '\e82f'; } /* '' */
.icon-screenstemplatestab:before { content: '\e830'; } /* '' */
.icon-previous:before { content: '\e831'; } /* '' */
.icon-and-01:before { content: '\e832'; } /* '' */
.icon-compressfile-01:before { content: '\e833'; } /* '' */
.icon-datawatcher-01:before { content: '\e834'; } /* '' */
.icon-deletefile-01:before { content: '\e835'; } /* '' */
.icon-export-01:before { content: '\e836'; } /* '' */
.icon-externalcall-01:before { content: '\e837'; } /* '' */
.icon-filetostream-01:before { content: '\e838'; } /* '' */
.icon-filewatcher-01:before { content: '\e839'; } /* '' */
.icon-flownotification-01:before { content: '\e83a'; } /* '' */
.icon-ftp-01:before { content: '\e83b'; } /* '' */
.icon-htmltopdf-01:before { content: '\e83c'; } /* '' */
.icon-loop:before { content: '\e83d'; } /* '' */
.icon-insertuserteams-01:before { content: '\e83e'; } /* '' */
.icon-insert-01:before { content: '\e83f'; } /* '' */
.icon-loggers-01:before { content: '\e840'; } /* '' */
.icon-movefile-01:before { content: '\e841'; } /* '' */
.icon-or:before { content: '\e842'; } /* '' */
.icon-parallel:before { content: '\e843'; } /* '' */
.icon-pass:before { content: '\e844'; } /* '' */
.icon-payments2:before { content: '\e845'; } /* '' */
.icon-pdftotiff:before { content: '\e846'; } /* '' */
.icon-pool-01:before { content: '\e847'; } /* '' */
.icon-readfromdatasource2-01:before { content: '\e848'; } /* '' */
.icon-sequencial-01:before { content: '\e849'; } /* '' */
.icon-swimlane-01:before { content: '\e84a'; } /* '' */
.icon-switch-01:before { content: '\e84b'; } /* '' */
.icon-task-01:before { content: '\e84c'; } /* '' */
.icon-transportfile-01:before { content: '\e84d'; } /* '' */
.icon-uncompressfile-01:before { content: '\e84e'; } /* '' */
.icon-uploadfile:before { content: '\e84f'; } /* '' */
.icon-verifyfile-01:before { content: '\e850'; } /* '' */
.icon-wait-01:before { content: '\e851'; } /* '' */
.icon-xor-01:before { content: '\e852'; } /* '' */
.icon-approval2:before { content: '\e853'; } /* '' */
.icon-bargraph:before { content: '\e854'; } /* '' */
.icon-blog:before { content: '\e855'; } /* '' */
.icon-book2:before { content: '\e856'; } /* '' */
.icon-cards:before { content: '\e857'; } /* '' */
.icon-collape:before { content: '\e858'; } /* '' */
.icon-document2:before { content: '\e859'; } /* '' */
.icon-expand:before { content: '\e85a'; } /* '' */
.icon-grid:before { content: '\e85b'; } /* '' */
.icon-lock:before { content: '\e85c'; } /* '' */
.icon-mobile3:before { content: '\e85d'; } /* '' */
.icon-pin:before { content: '\e85e'; } /* '' */
.icon-power:before { content: '\e85f'; } /* '' */
.icon-product2:before { content: '\e860'; } /* '' */
.icon-recent:before { content: '\e861'; } /* '' */
.icon-tag:before { content: '\e862'; } /* '' */
.icon-trophy:before { content: '\e863'; } /* '' */
.icon-productguideline:before { content: '\e864'; } /* '' */
.icon-document3:before { content: '\e865'; } /* '' */
.icon-member:before { content: '\e866'; } /* '' */
.icon-not-equal-to:before { content: '\e867'; } /* '' */
.icon-Edit:before { content: '\e868'; } /* '' */
.icon-edit_connections:before { content: '\e869'; } /* '' */
.icon-Linegraph:before { content: '\e86a'; } /* '' */
.icon-Pie:before { content: '\e86b'; } /* '' */
.icon-redo3:before { content: '\e86c'; } /* '' */
.icon-smiley:before { content: '\e86d'; } /* '' */
.icon-undo3:before { content: '\e86e'; } /* '' */
.icon-align:before { content: '\e86f'; } /* '' */
.icon-cal:before { content: '\e870'; } /* '' */
.icon-document4:before { content: '\e871'; } /* '' */
.icon-exclation:before { content: '\e872'; } /* '' */
.icon-export:before { content: '\e873'; } /* '' */
.icon-Fav:before { content: '\e874'; } /* '' */
.icon-icon1:before { content: '\e875'; } /* '' */
.icon-member4:before { content: '\e876'; } /* '' */
.icon-message:before { content: '\e877'; } /* '' */
.icon-play:before { content: '\e878'; } /* '' */
.icon-rupees:before { content: '\e879'; } /* '' */
.icon-addtofav:before { content: '\e87a'; } /* '' */
.icon-designer:before { content: '\e87b'; } /* '' */
.icon-equals2:before { content: '\e87c'; } /* '' */
.icon-excel:before { content: '\e87d'; } /* '' */
.icon-file2:before { content: '\e87e'; } /* '' */
.icon-file1:before { content: '\e87f'; } /* '' */
.icon-icon12:before { content: '\e880'; } /* '' */
.icon-icon2:before { content: '\e881'; } /* '' */
.icon-lisitng:before { content: '\e882'; } /* '' */
.icon-notequals:before { content: '\e883'; } /* '' */
.icon-nottaget:before { content: '\e884'; } /* '' */
.icon-pdf:before { content: '\e885'; } /* '' */
.icon-question2:before { content: '\e886'; } /* '' */
.icon-stamp:before { content: '\e887'; } /* '' */
.icon-target3:before { content: '\e888'; } /* '' */
.icon-word:before { content: '\e889'; } /* '' */
.icon-bargraph2:before { content: '\e88a'; } /* '' */
.icon-folder2:before { content: '\e88b'; } /* '' */
.icon-list3:before { content: '\e88c'; } /* '' */
.icon-additionalinfo:before { content: '\e88d'; } /* '' */
.icon-fileexport:before { content: '\e88e'; } /* '' */
.icon-file3:before { content: '\e88f'; } /* '' */
.icon-info2:before { content: '\e890'; } /* '' */
.icon-a-z:before { content: '\e891'; } /* '' */
.icon-favourite2:before { content: '\e892'; } /* '' */
.icon-file4:before { content: '\e893'; } /* '' */
.icon-graph:before { content: '\e894'; } /* '' */
.icon-mobile4:before { content: '\e895'; } /* '' */
.icon-notaligned:before { content: '\e896'; } /* '' */
.icon-pin2:before { content: '\e897'; } /* '' */
.icon-assignrule:before { content: '\e898'; } /* '' */
.icon-assign:before { content: '\e899'; } /* '' */
.icon-assignments:before { content: '\e89a'; } /* '' */
.icon-bringtofront:before { content: '\e89b'; } /* '' */
.icon-case2:before { content: '\e89c'; } /* '' */
.icon-clone2:before { content: '\e89d'; } /* '' */
.icon-conditions:before { content: '\e89e'; } /* '' */
.icon-connections1:before { content: '\e89f'; } /* '' */
.icon-connections2:before { content: '\e8a0'; } /* '' */
.icon-decision:before { content: '\e8a1'; } /* '' */
.icon-elseif:before { content: '\e8a2'; } /* '' */
.icon-else:before { content: '\e8a3'; } /* '' */
.icon-grid2:before { content: '\e8a4'; } /* '' */
.icon-horizontalalignment:before { content: '\e8a5'; } /* '' */
.icon-if:before { content: '\e8a6'; } /* '' */
.icon-lookup:before { content: '\e8a7'; } /* '' */
.icon-newfile:before { content: '\e8a8'; } /* '' */
.icon-panning:before { content: '\e8a9'; } /* '' */
.icon-payments:before { content: '\e8aa'; } /* '' */
.icon-pointer:before { content: '\e8ab'; } /* '' */
.icon-publish:before { content: '\e8ac'; } /* '' */
.icon-readdatasource:before { content: '\e8ad'; } /* '' */
.icon-createrequirement:before { content: '\e8ae'; } /* '' */
.icon-glyph:before { content: '\e8af'; } /* '' */
.icon-glyph-1:before { content: '\e8b0'; } /* '' */
.icon-glyph-2:before { content: '\e8b1'; } /* '' */
.icon-glyph-3:before { content: '\e8b2'; } /* '' */
.icon-glyph-4:before { content: '\e8b3'; } /* '' */
.icon-glyph-5:before { content: '\e8b4'; } /* '' */
.icon-glyph-6:before { content: '\e8b5'; } /* '' */
.icon-glyph-7:before { content: '\e8b6'; } /* '' */
.icon-glyph-8:before { content: '\e8b7'; } /* '' */
.icon-glyph-9:before { content: '\e8b8'; } /* '' */
.icon-redo4:before { content: '\e8b9'; } /* '' */
.icon-rulereferance:before { content: '\e8ba'; } /* '' */
.icon-saveflow:before { content: '\e8bb'; } /* '' */
.icon-score:before { content: '\e8bc'; } /* '' */
.icon-screen:before { content: '\e8bd'; } /* '' */
.icon-settoback:before { content: '\e8be'; } /* '' */
.icon-settings:before { content: '\e8bf'; } /* '' */
.icon-sleep:before { content: '\e8c0'; } /* '' */
.icon-sleep2:before { content: '\e8c1'; } /* '' */
.icon-smartalign:before { content: '\e8c2'; } /* '' */
.icon-snapping:before { content: '\e8c3'; } /* '' */
.icon-startevent:before { content: '\e8c4'; } /* '' */
.icon-switch2:before { content: '\e8c5'; } /* '' */
.icon-undo4:before { content: '\e8c6'; } /* '' */
.icon-verticalalignment:before { content: '\e8c7'; } /* '' */
.icon-xml:before { content: '\e8c8'; } /* '' */
.icon-addfile:before { content: '\e8c9'; } /* '' */
.icon-approved:before { content: '\e8ca'; } /* '' */
.icon-arrowup:before { content: '\e8cb'; } /* '' */
.icon-bin3:before { content: '\e8cc'; } /* '' */
.icon-calc:before { content: '\e8cd'; } /* '' */
.icon-close:before { content: '\e8ce'; } /* '' */
.icon-copy2:before { content: '\e8cf'; } /* '' */
.icon-document5:before { content: '\e8d0'; } /* '' */
.icon-excel2:before { content: '\e8d1'; } /* '' */
.icon-file5:before { content: '\e8d2'; } /* '' */
.icon-folder1:before { content: '\e8d3'; } /* '' */
.icon-graph2:before { content: '\e8d4'; } /* '' */
.icon-key3:before { content: '\e8d5'; } /* '' */
.icon-list4:before { content: '\e8d6'; } /* '' */
.icon-list22:before { content: '\e8d7'; } /* '' */
.icon-note2:before { content: '\e8d8'; } /* '' */
.icon-pdf2:before { content: '\e8d9'; } /* '' */
.icon-powerpoint:before { content: '\e8da'; } /* '' */
.icon-save-f:before { content: '\e8db'; } /* '' */
.icon-search3:before { content: '\e8dc'; } /* '' */
.icon-stamp2:before { content: '\e8dd'; } /* '' */
.icon-unvisible:before { content: '\e8de'; } /* '' */
.icon-upload4:before { content: '\e8df'; } /* '' */
.icon-visible:before { content: '\e8e0'; } /* '' */
.icon-word2:before { content: '\e8e1'; } /* '' */
.icon-glyph-10:before { content: '\e8e2'; } /* '' */
.icon-glyph-11:before { content: '\e8e3'; } /* '' */
.icon-glyph-12:before { content: '\e8e4'; } /* '' */
.icon-glyph-13:before { content: '\e8e5'; } /* '' */
.icon-glyph-14:before { content: '\e8e6'; } /* '' */
.icon-line2:before { content: '\e8e7'; } /* '' */
.icon-obj13:before { content: '\e8e8'; } /* '' */
.icon-obj316:before { content: '\e8e9'; } /* '' */
.icon-obj401:before { content: '\e8ea'; } /* '' */
.icon-facebook2:before { content: '\e8eb'; } /* '' */
.icon-googlebase2:before { content: '\e8ec'; } /* '' */
.icon-newtab:before { content: '\e8ed'; } /* '' */
.icon-obj366:before { content: '\e8ee'; } /* '' */
.icon-obj395:before { content: '\e8ef'; } /* '' */
.icon-testcase:before { content: '\e8f0'; } /* '' */
.icon-obj44:before { content: '\e8f1'; } /* '' */
.icon-obj132:before { content: '\e8f2'; } /* '' */
.icon-obj202:before { content: '\e8f3'; } /* '' */
.icon-obj267:before { content: '\e8f4'; } /* '' */
.icon-obj340:before { content: '\e8f5'; } /* '' */
.icon-contentlib:before { content: '\e8f6'; } /* '' */
.icon-doc:before { content: '\e8f7'; } /* '' */
.icon-forecastmanagement:before { content: '\e8f8'; } /* '' */
.icon-newtab_13:before { content: '\e8f9'; } /* '' */
.icon-poolmanagement:before { content: '\e8fa'; } /* '' */
.icon-wealthmanagement:before { content: '\e8fb'; } /* '' */
.icon-bar:before { content: '\e8fc'; } /* '' */
.icon-doughnut:before { content: '\e8fd'; } /* '' */
.icon-funnel:before { content: '\e8fe'; } /* '' */
.icon-gauge:before { content: '\e8ff'; } /* '' */
.icon-line:before { content: '\e900'; } /* '' */
.icon-pie:before { content: '\e901'; } /* '' */
.icon-tutorials:before { content: '\e902'; } /* '' */
.icon-twitter4:before { content: '\e903'; } /* '' */
.icon-unlike:before { content: '\e904'; } /* '' */
.icon-viewall:before { content: '\e905'; } /* '' */
.icon-wealth:before { content: '\e906'; } /* '' */
.icon-worknext:before { content: '\e907'; } /* '' */
.icon-workspace:before { content: '\e908'; } /* '' */
.icon-status:before { content: '\e909'; } /* '' */
.icon-stock-audit:before { content: '\e90a'; } /* '' */
.icon-summary:before { content: '\e90b'; } /* '' */
.icon-publishsurvey:before { content: '\e90c'; } /* '' */
.icon-switcher-active:before { content: '\e90d'; } /* '' */
.icon-switcher-non-active:before { content: '\e90e'; } /* '' */
.icon-target2:before { content: '\e90f'; } /* '' */
.icon-task:before { content: '\e910'; } /* '' */
.icon-task-new:before { content: '\e911'; } /* '' */
.icon-template:before { content: '\e912'; } /* '' */
.icon-timesheet:before { content: '\e913'; } /* '' */
.icon-toolbox:before { content: '\e914'; } /* '' */
.icon-training:before { content: '\e915'; } /* '' */
.icon-review:before { content: '\e916'; } /* '' */
.icon-sales:before { content: '\e917'; } /* '' */
.icon-save:before { content: '\e918'; } /* '' */
.icon-scheduling:before { content: '\e919'; } /* '' */
.icon-scheme:before { content: '\e91a'; } /* '' */
.icon-service:before { content: '\e91b'; } /* '' */
.icon-set-filter:before { content: '\e91c'; } /* '' */
.icon-share3:before { content: '\e91d'; } /* '' */
.icon-smart-filter:before { content: '\e91e'; } /* '' */
.icon-social-crm:before { content: '\e91f'; } /* '' */
.icon-solution:before { content: '\e920'; } /* '' */
.icon-product:before { content: '\e921'; } /* '' */
.icon-product-holding:before { content: '\e922'; } /* '' */
.icon-project:before { content: '\e923'; } /* '' */
.icon-quota:before { content: '\e924'; } /* '' */
.icon-quote:before { content: '\e925'; } /* '' */
.icon-recent-activities:before { content: '\e926'; } /* '' */
.icon-re-order-point:before { content: '\e927'; } /* '' */
.icon-report:before { content: '\e928'; } /* '' */
.icon-managereportcategories:before { content: '\e929'; } /* '' */
.icon-generatereport01:before { content: '\e92a'; } /* '' */
.icon-requirement:before { content: '\e92b'; } /* '' */
.icon-password:before { content: '\e92c'; } /* '' */
.icon-payment:before { content: '\e92d'; } /* '' */
.icon-planning:before { content: '\e92e'; } /* '' */
.icon-poll:before { content: '\e92f'; } /* '' */
.icon-pool:before { content: '\e930'; } /* '' */
.icon-portfolio:before { content: '\e931'; } /* '' */
.icon-post:before { content: '\e932'; } /* '' */
.icon-post-button:before { content: '\e933'; } /* '' */
.icon-pricebook:before { content: '\e934'; } /* '' */
.icon-procurement:before { content: '\e935'; } /* '' */
.icon-obj349:before { content: '\e936'; } /* '' */
.icon-obj350:before { content: '\e937'; } /* '' */
.icon-obj351:before { content: '\e938'; } /* '' */
.icon-obj359:before { content: '\e939'; } /* '' */
.icon-obj361:before { content: '\e93a'; } /* '' */
.icon-obj362:before { content: '\e93b'; } /* '' */
.icon-obj363:before { content: '\e93c'; } /* '' */
.icon-obj367:before { content: '\e93d'; } /* '' */
.icon-obj390:before { content: '\e93e'; } /* '' */
.icon-obj397:before { content: '\e93f'; } /* '' */
.icon-offers:before { content: '\e940'; } /* '' */
.icon-opportunity:before { content: '\e941'; } /* '' */
.icon-order:before { content: '\e942'; } /* '' */
.icon-obj278:before { content: '\e943'; } /* '' */
.icon-obj288:before { content: '\e944'; } /* '' */
.icon-obj290:before { content: '\e945'; } /* '' */
.icon-obj291:before { content: '\e946'; } /* '' */
.icon-obj292:before { content: '\e947'; } /* '' */
.icon-obj293:before { content: '\e948'; } /* '' */
.icon-obj299:before { content: '\e949'; } /* '' */
.icon-obj307:before { content: '\e94a'; } /* '' */
.icon-glyph-15:before { content: '\e94b'; } /* '' */
.icon-glyph-16:before { content: '\e94c'; } /* '' */
.icon-glyph-17:before { content: '\e94d'; } /* '' */
.icon-glyph-18:before { content: '\e94e'; } /* '' */
.icon-glyph-19:before { content: '\e94f'; } /* '' */
.icon-glyph-20:before { content: '\e950'; } /* '' */
.icon-glyph-21:before { content: '\e951'; } /* '' */
.icon-glyph-22:before { content: '\e952'; } /* '' */
.icon-glyph-23:before { content: '\e953'; } /* '' */
.icon-glyph-24:before { content: '\e954'; } /* '' */
.icon-glyph-25:before { content: '\e955'; } /* '' */
.icon-glyph-26:before { content: '\e956'; } /* '' */
.icon-glyph-27:before { content: '\e957'; } /* '' */
.icon-glyph-28:before { content: '\e958'; } /* '' */
.icon-obj322:before { content: '\e959'; } /* '' */
.icon-obj324:before { content: '\e95a'; } /* '' */
.icon-obj326:before { content: '\e95b'; } /* '' */
.icon-obj334:before { content: '\e95c'; } /* '' */
.icon-obj196:before { content: '\e95d'; } /* '' */
.icon-obj199:before { content: '\e95e'; } /* '' */
.icon-obj200:before { content: '\e95f'; } /* '' */
.icon-obj203:before { content: '\e960'; } /* '' */
.icon-obj205:before { content: '\e961'; } /* '' */
.icon-obj206:before { content: '\e962'; } /* '' */
.icon-obj207:before { content: '\e963'; } /* '' */
.icon-obj227:before { content: '\e964'; } /* '' */
.icon-obj242:before { content: '\e965'; } /* '' */
.icon-obj258:before { content: '\e966'; } /* '' */
.icon-obj259:before { content: '\e967'; } /* '' */
.icon-obj276:before { content: '\e968'; } /* '' */
.icon-obj277:before { content: '\e969'; } /* '' */
.icon-obj56:before { content: '\e96a'; } /* '' */
.icon-obj58:before { content: '\e96b'; } /* '' */
.icon-obj67:before { content: '\e96c'; } /* '' */
.icon-obj74:before { content: '\e96d'; } /* '' */
.icon-obj84:before { content: '\e96e'; } /* '' */
.icon-obj90:before { content: '\e96f'; } /* '' */
.icon-obj96:before { content: '\e970'; } /* '' */
.icon-obj97:before { content: '\e971'; } /* '' */
.icon-obj108:before { content: '\e972'; } /* '' */
.icon-obj162:before { content: '\e973'; } /* '' */
.icon-obj175:before { content: '\e974'; } /* '' */
.icon-obj186:before { content: '\e975'; } /* '' */
.icon-obj188:before { content: '\e976'; } /* '' */
.icon-obj9:before { content: '\e977'; } /* '' */
.icon-obj10:before { content: '\e978'; } /* '' */
.icon-obj11:before { content: '\e979'; } /* '' */
.icon-obj12:before { content: '\e97a'; } /* '' */
.icon-glyph-29:before { content: '\e97b'; } /* '' */
.icon-glyph-30:before { content: '\e97c'; } /* '' */
.icon-glyph-31:before { content: '\e97d'; } /* '' */
.icon-obj19:before { content: '\e97e'; } /* '' */
.icon-obj22:before { content: '\e97f'; } /* '' */
.icon-obj28:before { content: '\e980'; } /* '' */
.icon-obj34:before { content: '\e981'; } /* '' */
.icon-obj36:before { content: '\e982'; } /* '' */
.icon-obj38:before { content: '\e983'; } /* '' */
.icon-obj41:before { content: '\e984'; } /* '' */
.icon-obj52:before { content: '\e985'; } /* '' */
.icon-note:before { content: '\e986'; } /* '' */
.icon-not-equal:before { content: '\e987'; } /* '' */
.icon-notify-message:before { content: '\e988'; } /* '' */
.icon-obj2:before { content: '\e989'; } /* '' */
.icon-obj3:before { content: '\e98a'; } /* '' */
.icon-obj4:before { content: '\e98b'; } /* '' */
.icon-obj5:before { content: '\e98c'; } /* '' */
.icon-obj6:before { content: '\e98d'; } /* '' */
.icon-obj7:before { content: '\e98e'; } /* '' */
.icon-obj8:before { content: '\e98f'; } /* '' */
.icon-mass-print:before { content: '\e990'; } /* '' */
.icon-mass-unshare:before { content: '\e991'; } /* '' */
.icon-merge:before { content: '\e992'; } /* '' */
.icon-more-links:before { content: '\e993'; } /* '' */
.icon-my-file:before { content: '\e994'; } /* '' */
.icon-my-notifications:before { content: '\e995'; } /* '' */
.icon-my-reports:before { content: '\e996'; } /* '' */
.icon-generatereport:before { content: '\e997'; } /* '' */
.icon-listing:before { content: '\e998'; } /* '' */
.icon-list-view:before { content: '\e999'; } /* '' */
.icon-location3:before { content: '\e99a'; } /* '' */
.icon-log:before { content: '\e99b'; } /* '' */
.icon-logout-icon:before { content: '\e99c'; } /* '' */
.icon-manage-category:before { content: '\e99d'; } /* '' */
.icon-marketing:before { content: '\e99e'; } /* '' */
.icon-home:before { content: '\e99f'; } /* '' */
.icon-inventory:before { content: '\e9a0'; } /* '' */
.icon-Issue:before { content: '\e9a1'; } /* '' */
.icon-key-info:before { content: '\e9a2'; } /* '' */
.icon-key-information:before { content: '\e9a3'; } /* '' */
.icon-keypad:before { content: '\e9a4'; } /* '' */
.icon-lead:before { content: '\e9a5'; } /* '' */
.icon-lead-pipeline:before { content: '\e9a6'; } /* '' */
.icon-less-than:before { content: '\e9a7'; } /* '' */
.icon-like:before { content: '\e9a8'; } /* '' */
.icon-file:before { content: '\e9a9'; } /* '' */
.icon-follow-up-info:before { content: '\e9aa'; } /* '' */
.icon-forecast:before { content: '\e9ab'; } /* '' */
.icon-goal:before { content: '\e9ac'; } /* '' */
.icon-goal-chart:before { content: '\e9ad'; } /* '' */
.icon-greaterthan:before { content: '\e9ae'; } /* '' */
.icon-grid-view:before { content: '\e9af'; } /* '' */
.icon-group:before { content: '\e9b0'; } /* '' */
.icon-help:before { content: '\e9b1'; } /* '' */
.icon-doublearrowdown:before { content: '\e9b2'; } /* '' */
.icon-doublearrowleft:before { content: '\e9b3'; } /* '' */
.icon-doublearrowright:before { content: '\e9b4'; } /* '' */
.icon-doublearrowtop:before { content: '\e9b5'; } /* '' */
.icon-drop-chart-compare:before { content: '\e9b6'; } /* '' */
.icon-equal:before { content: '\e9b7'; } /* '' */
.icon-expense:before { content: '\e9b8'; } /* '' */
.icon-facebook4:before { content: '\e9b9'; } /* '' */
.icon-favourite:before { content: '\e9ba'; } /* '' */
.icon-favourite-list:before { content: '\e9bb'; } /* '' */
.icon-feedback:before { content: '\e9bc'; } /* '' */
.icon-content-library:before { content: '\e9bd'; } /* '' */
.icon-courier:before { content: '\e9be'; } /* '' */
.icon-currency-convertor:before { content: '\e9bf'; } /* '' */
.icon-custom-dashboard:before { content: '\e9c0'; } /* '' */
.icon-dashboard:before { content: '\e9c1'; } /* '' */
.icon-detail:before { content: '\e9c2'; } /* '' */
.icon-dispatch:before { content: '\e9c3'; } /* '' */
.icon-document:before { content: '\e9c4'; } /* '' */
.icon-call-script:before { content: '\e9c5'; } /* '' */
.icon-campaign:before { content: '\e9c6'; } /* '' */
.icon-case:before { content: '\e9c7'; } /* '' */
.icon-charge:before { content: '\e9c8'; } /* '' */
.icon-chart:before { content: '\e9c9'; } /* '' */
.icon-clone:before { content: '\e9ca'; } /* '' */
.icon-comments:before { content: '\e9cb'; } /* '' */
.icon-competition:before { content: '\e9cc'; } /* '' */
.icon-contactsheet:before { content: '\e9cd'; } /* '' */
.icon-contact-info:before { content: '\e9ce'; } /* '' */
.icon-content:before { content: '\e9cf'; } /* '' */
.icon-arrow-left3:before { content: '\e9d0'; } /* '' */
.icon-arrow-right3:before { content: '\e9d1'; } /* '' */
.icon-arrow-top:before { content: '\e9d2'; } /* '' */
.icon-assets:before { content: '\e9d3'; } /* '' */
.icon-glyph-32:before { content: '\e9d4'; } /* '' */
.icon-glyph-33:before { content: '\e9d5'; } /* '' */
.icon-glyph-34:before { content: '\e9d6'; } /* '' */
.icon-glyph-35:before { content: '\e9d7'; } /* '' */
.icon-glyph-36:before { content: '\e9d8'; } /* '' */
.icon-glyph-37:before { content: '\e9d9'; } /* '' */
.icon-glyph-38:before { content: '\e9da'; } /* '' */
.icon-glyph-39:before { content: '\e9db'; } /* '' */
.icon-glyph-40:before { content: '\e9dc'; } /* '' */
.icon-glyph-41:before { content: '\e9dd'; } /* '' */
.icon-glyph-42:before { content: '\e9de'; } /* '' */
.icon-glyph-43:before { content: '\e9df'; } /* '' */
.icon-glyph-44:before { content: '\e9e0'; } /* '' */
.icon-glyph-45:before { content: '\e9e1'; } /* '' */
.icon-badge:before { content: '\e9e2'; } /* '' */
.icon-big-deal-alert:before { content: '\e9e3'; } /* '' */
.icon-Biometric:before { content: '\e9e4'; } /* '' */
.icon-budget:before { content: '\e9e5'; } /* '' */
.icon-build:before { content: '\e9e6'; } /* '' */
.icon-bulletempty:before { content: '\e9e7'; } /* '' */
.icon-bulletfilled:before { content: '\e9e8'; } /* '' */
.icon-atozsorter:before { content: '\e9e9'; } /* '' */
.icon-account:before { content: '\e9ea'; } /* '' */
.icon-achievement:before { content: '\e9eb'; } /* '' */
.icon-additional-info:before { content: '\e9ec'; } /* '' */
.icon-add-to-Fav:before { content: '\e9ed'; } /* '' */
.icon-add-to-quick-link:before { content: '\e9ee'; } /* '' */
.icon-newexternalreport:before { content: '\e9ef'; } /* '' */
.icon-reports:before { content: '\e9f0'; } /* '' */
.icon-appointments:before { content: '\e9f1'; } /* '' */
.icon-approval:before { content: '\e9f2'; } /* '' */
.icon-arrow-bottom:before { content: '\e9f3'; } /* '' */
.icon-archivedata:before { content: '\e9f4'; } /* '' */
.icon-bars:before { content: '\e9f5'; } /* '' */
.icon-cibilenquiry:before { content: '\e9f6'; } /* '' */
.icon-clear:before { content: '\e9f7'; } /* '' */
.icon-command:before { content: '\e9f8'; } /* '' */
.icon-delay:before { content: '\e9f9'; } /* '' */
.icon-deleteuserproduct:before { content: '\e9fa'; } /* '' */
.icon-Deleteuserteams:before { content: '\e9fb'; } /* '' */
.icon-deleteuserterritorry:before { content: '\e9fc'; } /* '' */
.icon-disableindexes:before { content: '\e9fd'; } /* '' */
.icon-externaldataservices:before { content: '\e9fe'; } /* '' */
.icon-ForecastRebuild:before { content: '\e9ff'; } /* '' */
.icon-importCFData:before { content: '\ea00'; } /* '' */
.icon-importobject:before { content: '\ea01'; } /* '' */
.icon-insertuserterritorry:before { content: '\ea02'; } /* '' */
.icon-jobs:before { content: '\ea03'; } /* '' */
.icon-mailblaster:before { content: '\ea04'; } /* '' */
.icon-method:before { content: '\ea05'; } /* '' */
.icon-objectsharing:before { content: '\ea06'; } /* '' */
.icon-processcibilrequest:before { content: '\ea07'; } /* '' */
.icon-Purgeobject:before { content: '\ea08'; } /* '' */
.icon-readmessagequeue:before { content: '\ea09'; } /* '' */
.icon-rebuildhierarchy:before { content: '\ea0a'; } /* '' */
.icon-reportschedule:before { content: '\ea0b'; } /* '' */
.icon-sendreportscheduler:before { content: '\ea0c'; } /* '' */
.icon-synchronizeDB:before { content: '\ea0d'; } /* '' */
.icon-writemessagequeue:before { content: '\ea0e'; } /* '' */
.icon-administration_setup:before { content: '\ea0f'; } /* '' */
.icon-customization2:before { content: '\ea10'; } /* '' */
.icon-data_management:before { content: '\ea11'; } /* '' */
.icon-scissors:before { content: '\ea12'; } /* '' */
.icon-newspaper:before { content: '\ea13'; } /* '' */
.icon-droplet:before { content: '\ea14'; } /* '' */
.icon-image:before { content: '\ea15'; } /* '' */
.icon-image-group:before { content: '\ea16'; } /* '' */
.icon-connection:before { content: '\ea17'; } /* '' */
.icon-podcast:before { content: '\ea18'; } /* '' */
.icon-feed:before { content: '\ea19'; } /* '' */
.icon-mic:before { content: '\ea1a'; } /* '' */
.icon-book:before { content: '\ea1b'; } /* '' */
.icon-books:before { content: '\ea1c'; } /* '' */
.icon-library:before { content: '\ea1d'; } /* '' */
.icon-file-text:before { content: '\ea1e'; } /* '' */
.icon-profile:before { content: '\ea1f'; } /* '' */
.icon-file-empty:before { content: '\ea20'; } /* '' */
.icon-files-empty:before { content: '\ea21'; } /* '' */
.icon-file-picture:before { content: '\ea22'; } /* '' */
.icon-file-music:before { content: '\ea23'; } /* '' */
.icon-file-play:before { content: '\ea24'; } /* '' */
.icon-file-video:before { content: '\ea25'; } /* '' */
.icon-file-zip:before { content: '\ea26'; } /* '' */
.icon-copy:before { content: '\ea27'; } /* '' */
.icon-paste:before { content: '\ea28'; } /* '' */
.icon-stack:before { content: '\ea29'; } /* '' */
.icon-folder:before { content: '\ea2a'; } /* '' */
.icon-folder-open:before { content: '\ea2b'; } /* '' */
.icon-folder-plus:before { content: '\ea2c'; } /* '' */
.icon-folder-minus:before { content: '\ea2d'; } /* '' */
.icon-folder-download:before { content: '\ea2e'; } /* '' */
.icon-folder-upload:before { content: '\ea2f'; } /* '' */
.icon-price-tag:before { content: '\ea30'; } /* '' */
.icon-price-tags:before { content: '\ea31'; } /* '' */
.icon-barcode:before { content: '\ea32'; } /* '' */
.icon-credit-card:before { content: '\ea33'; } /* '' */
.icon-phone:before { content: '\ea34'; } /* '' */
.icon-phone-hang-up:before { content: '\ea35'; } /* '' */
.icon-pushpin:before { content: '\ea36'; } /* '' */
.icon-location:before { content: '\ea37'; } /* '' */
.icon-location2:before { content: '\ea38'; } /* '' */
.icon-compass:before { content: '\ea39'; } /* '' */
.icon-compass2:before { content: '\ea3a'; } /* '' */
.icon-map:before { content: '\ea3b'; } /* '' */
.icon-map2:before { content: '\ea3c'; } /* '' */
.icon-history:before { content: '\ea3d'; } /* '' */
.icon-clock:before { content: '\ea3e'; } /* '' */
.icon-alarm:before { content: '\ea3f'; } /* '' */
.icon-stopwatch:before { content: '\ea40'; } /* '' */
.icon-keyboard:before { content: '\ea41'; } /* '' */
.icon-display:before { content: '\ea42'; } /* '' */
.icon-laptop:before { content: '\ea43'; } /* '' */
.icon-mobile:before { content: '\ea44'; } /* '' */
.icon-tablet:before { content: '\ea45'; } /* '' */
.icon-tv:before { content: '\ea46'; } /* '' */
.icon-drawer:before { content: '\ea47'; } /* '' */
.icon-box-add:before { content: '\ea48'; } /* '' */
.icon-box-remove:before { content: '\ea49'; } /* '' */
.icon-download:before { content: '\ea4a'; } /* '' */
.icon-upload:before { content: '\ea4b'; } /* '' */
.icon-floppy-disk:before { content: '\ea4c'; } /* '' */
.icon-drive:before { content: '\ea4d'; } /* '' */
.icon-database:before { content: '\ea4e'; } /* '' */
.icon-undo2:before { content: '\ea4f'; } /* '' */
.icon-redo2:before { content: '\ea50'; } /* '' */
.icon-forward:before { content: '\ea51'; } /* '' */
.icon-reply:before { content: '\ea52'; } /* '' */
.icon-bubble:before { content: '\ea53'; } /* '' */
.icon-bubbles:before { content: '\ea54'; } /* '' */
.icon-bubbles2:before { content: '\ea55'; } /* '' */
.icon-bubble2:before { content: '\ea56'; } /* '' */
.icon-bubbles3:before { content: '\ea57'; } /* '' */
.icon-bubbles4:before { content: '\ea58'; } /* '' */
.icon-users:before { content: '\ea59'; } /* '' */
.icon-user-plus:before { content: '\ea5a'; } /* '' */
.icon-user-minus:before { content: '\ea5b'; } /* '' */
.icon-user-check:before { content: '\ea5c'; } /* '' */
.icon-user-tie:before { content: '\ea5d'; } /* '' */
.icon-quotes-left:before { content: '\ea5e'; } /* '' */
.icon-quotes-right:before { content: '\ea5f'; } /* '' */
.icon-hour-glass:before { content: '\ea60'; } /* '' */
.icon-spinner5:before { content: '\ea61'; } /* '' */
.icon-binoculars:before { content: '\ea62'; } /* '' */
.icon-search:before { content: '\ea63'; } /* '' */
.icon-zoom-in:before { content: '\ea64'; } /* '' */
.icon-zoom-out:before { content: '\ea65'; } /* '' */
.icon-enlarge:before { content: '\ea66'; } /* '' */
.icon-shrink:before { content: '\ea67'; } /* '' */
.icon-key:before { content: '\ea68'; } /* '' */
.icon-key2:before { content: '\ea69'; } /* '' */
.icon-locked:before { content: '\ea6a'; } /* '' */
.icon-unlocked:before { content: '\ea6b'; } /* '' */
.icon-wrench:before { content: '\ea6c'; } /* '' */
.icon-equalizer:before { content: '\ea6d'; } /* '' */
.icon-equalizer2:before { content: '\ea6e'; } /* '' */
.icon-cog:before { content: '\ea6f'; } /* '' */
.icon-cogs:before { content: '\ea70'; } /* '' */
.icon-hammer:before { content: '\ea71'; } /* '' */
.icon-aid-kit:before { content: '\ea72'; } /* '' */
.icon-pie-chart:before { content: '\ea73'; } /* '' */
.icon-stats-dots:before { content: '\ea74'; } /* '' */
.icon-stats-bars:before { content: '\ea75'; } /* '' */
.icon-stats-bars2:before { content: '\ea76'; } /* '' */
.icon-meter:before { content: '\ea77'; } /* '' */
.icon-meter2:before { content: '\ea78'; } /* '' */
.icon-hammer2:before { content: '\ea79'; } /* '' */
.icon-magnet:before { content: '\ea7a'; } /* '' */
.icon-briefcase:before { content: '\ea7b'; } /* '' */
.icon-target:before { content: '\ea7c'; } /* '' */
.icon-shield:before { content: '\ea7d'; } /* '' */
.icon-switch:before { content: '\ea7e'; } /* '' */
.icon-clipboard:before { content: '\ea7f'; } /* '' */
.icon-list-numbered:before { content: '\ea80'; } /* '' */
.icon-list:before { content: '\ea81'; } /* '' */
.icon-list2:before { content: '\ea82'; } /* '' */
.icon-tree:before { content: '\ea83'; } /* '' */
.icon-menu-1:before { content: '\ea84'; } /* '' */
.icon-menu2:before { content: '\ea85'; } /* '' */
.icon-menu3:before { content: '\ea86'; } /* '' */
.icon-menu4:before { content: '\ea87'; } /* '' */
.icon-cloud:before { content: '\ea88'; } /* '' */
.icon-cloud-download:before { content: '\ea89'; } /* '' */
.icon-cloud-upload:before { content: '\ea8a'; } /* '' */
.icon-cloud-check:before { content: '\ea8b'; } /* '' */
.icon-download2:before { content: '\ea8c'; } /* '' */
.icon-upload2:before { content: '\ea8d'; } /* '' */
.icon-download3:before { content: '\ea8e'; } /* '' */
.icon-upload3:before { content: '\ea8f'; } /* '' */
.icon-sphere:before { content: '\ea90'; } /* '' */
.icon-link:before { content: '\ea91'; } /* '' */
.icon-flag:before { content: '\ea92'; } /* '' */
.icon-viewattachment:before { content: '\ea93'; } /* '' */
.icon-eye:before { content: '\ea94'; } /* '' */
.icon-eye-blocked:before { content: '\ea95'; } /* '' */
.icon-bookmark:before { content: '\ea96'; } /* '' */
.icon-bookmarks:before { content: '\ea97'; } /* '' */
.icon-sun:before { content: '\ea98'; } /* '' */
.icon-contrast:before { content: '\ea99'; } /* '' */
.icon-brightness-contrast:before { content: '\ea9a'; } /* '' */
.icon-star-empty:before { content: '\ea9b'; } /* '' */
.icon-star-half:before { content: '\ea9c'; } /* '' */
.icon-star-full:before { content: '\ea9d'; } /* '' */
.icon-warning:before { content: '\ea9e'; } /* '' */
.icon-notification:before { content: '\ea9f'; } /* '' */
.icon-question3:before { content: '\eaa0'; } /* '' */
.icon-minus:before { content: '\eaa1'; } /* '' */
.icon-info:before { content: '\eaa2'; } /* '' */
.icon-blocked:before { content: '\eaa3'; } /* '' */
.icon-spell-check:before { content: '\eaa4'; } /* '' */
.icon-enter:before { content: '\eaa5'; } /* '' */
.icon-volume-high:before { content: '\eaa6'; } /* '' */
.icon-volume-medium:before { content: '\eaa7'; } /* '' */
.icon-volume-low:before { content: '\eaa8'; } /* '' */
.icon-volume-mute:before { content: '\eaa9'; } /* '' */
.icon-update:before { content: '\eaaa'; } /* '' */
.icon-infinite:before { content: '\eaab'; } /* '' */
.icon-arrow-up-left:before { content: '\eaac'; } /* '' */
.icon-arrow-up:before { content: '\eaad'; } /* '' */
.icon-arrow-up-right:before { content: '\eaae'; } /* '' */
.icon-arrow-right:before { content: '\eaaf'; } /* '' */
.icon-arrow-down-right:before { content: '\eab0'; } /* '' */
.icon-arrow-down:before { content: '\eab1'; } /* '' */
.icon-arrow-down-left:before { content: '\eab2'; } /* '' */
.icon-arrow-left:before { content: '\eab3'; } /* '' */
.icon-arrow-up-left2:before { content: '\eab4'; } /* '' */
.icon-arrow-up2:before { content: '\eab5'; } /* '' */
.icon-arrow-up-right2:before { content: '\eab6'; } /* '' */
.icon-arrow-right2:before { content: '\eab7'; } /* '' */
.icon-arrow-down-right2:before { content: '\eab8'; } /* '' */
.icon-arrow-down2:before { content: '\eab9'; } /* '' */
.icon-arrow-down-left2:before { content: '\eaba'; } /* '' */
.icon-arrow-left2:before { content: '\eabb'; } /* '' */
.icon-tab:before { content: '\eabc'; } /* '' */
.icon-sort-amount-asc:before { content: '\eabd'; } /* '' */
.icon-sort-amount-desc:before { content: '\eabe'; } /* '' */
.icon-shift:before { content: '\eabf'; } /* '' */
.icon-ctrl:before { content: '\eac0'; } /* '' */
.icon-checkbox-checked:before { content: '\eac1'; } /* '' */
.icon-checkbox-unchecked:before { content: '\eac2'; } /* '' */
.icon-radio-checked:before { content: '\eac3'; } /* '' */
.icon-radio-checked2:before { content: '\eac4'; } /* '' */
.icon-radio-unchecked:before { content: '\eac5'; } /* '' */
.icon-table:before { content: '\eac6'; } /* '' */
.icon-table2:before { content: '\eac7'; } /* '' */
.icon-insert-template:before { content: '\eac8'; } /* '' */
.icon-paragraph-left:before { content: '\eac9'; } /* '' */
.icon-paragraph-center:before { content: '\eaca'; } /* '' */
.icon-paragraph-right:before { content: '\eacb'; } /* '' */
.icon-paragraph-justify:before { content: '\eacc'; } /* '' */
.icon-indent-increase:before { content: '\eacd'; } /* '' */
.icon-indent-decrease:before { content: '\eace'; } /* '' */
.icon-share:before { content: '\eacf'; } /* '' */
.icon-new-tab:before { content: '\ead0'; } /* '' */
.icon-embed:before { content: '\ead1'; } /* '' */
.icon-terminal:before { content: '\ead2'; } /* '' */
.icon-youtube:before { content: '\ead3'; } /* '' */
.icon-file-openoffice:before { content: '\ead4'; } /* '' */
.icon-libreoffice:before { content: '\ead5'; } /* '' */
.icon-question:before { content: '\ead6'; } /* '' */
.icon-AdvanceAssignmentsRule_op2-01:before { content: '\ead7'; } /* '' */
.icon-AdvanceAssignmentsRule-01:before { content: '\ead8'; } /* '' */
.icon-AssignmentsRule_op2-01:before { content: '\ead9'; } /* '' */
.icon-AssignmentsRule-01:before { content: '\eada'; } /* '' */
.icon-AutoResponse_op2-01:before { content: '\eadb'; } /* '' */
.icon-AutoResponse-01:before { content: '\eadc'; } /* '' */
.icon-ConditionalAccess_op2-01:before { content: '\eadd'; } /* '' */
.icon-ConditionalAccess-01:before { content: '\eade'; } /* '' */
.icon-ConditionalAlerts_op2-01:before { content: '\eadf'; } /* '' */
.icon-ConditionalAlerts-01:before { content: '\eae0'; } /* '' */
.icon-De-dupeRule_op2-01:before { content: '\eae1'; } /* '' */
.icon-De-dupeRule-01:before { content: '\eae2'; } /* '' */
.icon-externaldataservice_op2-01:before { content: '\eae3'; } /* '' */
.icon-product2-1:before { content: '\eae4'; } /* '' */
.icon-fittodiagram_op2-01:before { content: '\eae5'; } /* '' */
.icon-fullscreencollapsemode_op2-01:before { content: '\eae6'; } /* '' */
.icon-htmltopdf_op2-01:before { content: '\eae7'; } /* '' */
.icon-insertuserproduct_op2-01:before { content: '\eae8'; } /* '' */
.icon-ManageAlertsRule_op2-01:before { content: '\eae9'; } /* '' */
.icon-ManageAlertsRule-01:before { content: '\eaea'; } /* '' */
.icon-pdftotiff_op2-01:before { content: '\eaeb'; } /* '' */
.icon-recorddeletion_op2-01:before { content: '\eaec'; } /* '' */
.icon-runflow_op2-01:before { content: '\eaed'; } /* '' */
.icon-screenflowicon_op2-01:before { content: '\eaee'; } /* '' */
.icon-validate_op2-01:before { content: '\eaef'; } /* '' */
.icon-widget_op2-01:before { content: '\eaf0'; } /* '' */
.icon-slider:before { content: '\eaf1'; } /* '' */
.icon-slider_bar:before { content: '\eaf2'; } /* '' */
.icon-zoom_in:before { content: '\eaf3'; } /* '' */
.icon-zoom_out:before { content: '\eaf4'; } /* '' */
.icon-word3:before { content: '\eaf5'; } /* '' */
.icon-Cricket2:before { content: '\eaf6'; } /* '' */
.icon-Badminton:before { content: '\eaf7'; } /* '' */
.icon-Cricket:before { content: '\eaf8'; } /* '' */
.icon-Football:before { content: '\eaf9'; } /* '' */
.icon-Chess:before { content: '\eafa'; } /* '' */
.icon-Smoking:before { content: '\eafb'; } /* '' */
.icon-Car:before { content: '\eafc'; } /* '' */
.icon-PDF:before { content: '\eafd'; } /* '' */
.icon-glyph-46:before { content: '\eafe'; } /* '' */
.icon-glyph-47:before { content: '\eaff'; } /* '' */
.icon-Shop:before { content: '\eb00'; } /* '' */
.icon-Road:before { content: '\eb01'; } /* '' */
.icon-restaurent:before { content: '\eb02'; } /* '' */
.icon-obj269:before { content: '\eb03'; } /* '' */
.icon-show:before { content: '\eb04'; } /* '' */
.icon-initiate:before { content: '\eb05'; } /* '' */
.icon-job:before { content: '\eb06'; } /* '' */
.icon-allocation:before { content: '\eb07'; } /* '' */
.icon-obj248:before { content: '\eb08'; } /* '' */
.icon-House2:before { content: '\eb09'; } /* '' */
.icon-gif:before { content: '\eb0a'; } /* '' */
.icon-jpg:before { content: '\eb0b'; } /* '' */
.icon-media:before { content: '\eb0c'; } /* '' */
.icon-PDF2:before { content: '\eb0d'; } /* '' */
.icon-png:before { content: '\eb0e'; } /* '' */
.icon-tiff:before { content: '\eb0f'; } /* '' */
.icon-ICON_Businessenforcementrule:before { content: '\eb10'; } /* '' */
.icon-ICON_Escalationrule:before { content: '\eb11'; } /* '' */
.icon-glyph-48:before { content: '\eb12'; } /* '' */
.icon-glyph-49:before { content: '\eb13'; } /* '' */
.icon-glyph-50:before { content: '\eb14'; } /* '' */
.icon-glyph-51:before { content: '\eb15'; } /* '' */
.icon-glyph-52:before { content: '\eb16'; } /* '' */
.icon-glyph-53:before { content: '\eb17'; } /* '' */
.icon-glyph-54:before { content: '\eb18'; } /* '' */
.icon-glyph-55:before { content: '\eb19'; } /* '' */
.icon-ICON_SLA:before { content: '\eb1a'; } /* '' */
.icon-glyph-56:before { content: '\eb1b'; } /* '' */
.icon-glyph-57:before { content: '\eb1c'; } /* '' */
.icon-glyph-58:before { content: '\eb1d'; } /* '' */
.icon-glyph-59:before { content: '\eb1e'; } /* '' */
.icon-glyph-60:before { content: '\eb1f'; } /* '' */
.icon-glyph-61:before { content: '\eb20'; } /* '' */
.icon-glyph-62:before { content: '\eb21'; } /* '' */
.icon-autointelligence:before { content: '\eb22'; } /* '' */
.icon-customerdelight:before { content: '\eb23'; } /* '' */
.icon-Interaction:before { content: '\eb24'; } /* '' */
.icon-glyph-63:before { content: '\eb25'; } /* '' */
.icon-glyph-64:before { content: '\eb26'; } /* '' */
.icon-glyph-65:before { content: '\eb27'; } /* '' */
.icon-glyph-66:before { content: '\eb28'; } /* '' */
.icon-productholding:before { content: '\eb29'; } /* '' */
.icon-Car2:before { content: '\eb2a'; } /* '' */
.icon-Plane:before { content: '\eb2b'; } /* '' */
.icon-CustomerDelight:before { content: '\eb2c'; } /* '' */
.icon-glyph-67:before { content: '\eb2d'; } /* '' */
.icon-glyph-68:before { content: '\eb2e'; } /* '' */
.icon-glyph-69:before { content: '\eb2f'; } /* '' */
.icon-glyph-70:before { content: '\eb30'; } /* '' */
.icon-glyph-71:before { content: '\eb31'; } /* '' */
.icon-glyph-72:before { content: '\eb32'; } /* '' */
.icon-glyph-73:before { content: '\eb33'; } /* '' */
.icon-glyph-74:before { content: '\eb34'; } /* '' */
.icon-glyph-75:before { content: '\eb35'; } /* '' */
.icon-glyph-76:before { content: '\eb36'; } /* '' */
.icon-glyph-77:before { content: '\eb37'; } /* '' */
.icon-glyph-78:before { content: '\eb38'; } /* '' */
.icon-glyph-79:before { content: '\eb39'; } /* '' */
.icon-glyph-80:before { content: '\eb3a'; } /* '' */
.icon-glyph-81:before { content: '\eb3b'; } /* '' */
.icon-pproduct:before { content: '\eb3c'; } /* '' */
.icon-product3:before { content: '\eb3d'; } /* '' */
.icon-interactions:before { content: '\eb3e'; } /* '' */
.icon-lifecycle:before { content: '\eb3f'; } /* '' */
.icon-glyph-82:before { content: '\eb40'; } /* '' */
.icon-glyph-83:before { content: '\eb41'; } /* '' */
.icon-glyph-84:before { content: '\eb42'; } /* '' */
.icon-glyph-85:before { content: '\eb43'; } /* '' */
.icon-glyph-86:before { content: '\eb44'; } /* '' */
.icon-glyph-87:before { content: '\eb45'; } /* '' */
.icon-glyph-88:before { content: '\eb46'; } /* '' */
.icon-glyph-89:before { content: '\eb47'; } /* '' */
.icon-glyph-90:before { content: '\eb48'; } /* '' */
.icon-glyph-91:before { content: '\eb49'; } /* '' */
.icon-glyph-92:before { content: '\eb4a'; } /* '' */
.icon-glyph-93:before { content: '\eb4b'; } /* '' */
.icon-glyph-94:before { content: '\eb4c'; } /* '' */
.icon-glyph-95:before { content: '\eb4d'; } /* '' */
.icon-glyph-96:before { content: '\eb4e'; } /* '' */
.icon-wallet_Share:before { content: '\eb4f'; } /* '' */
.icon-stop:before { content: '\eb50'; } /* '' */
.icon-accesspermission2:before { content: '\eb51'; } /* '' */
.icon-accesspermission:before { content: '\eb52'; } /* '' */
.icon-adddocument:before { content: '\eb53'; } /* '' */
.icon-glyph-97:before { content: '\eb54'; } /* '' */
.icon-glyph-98:before { content: '\eb55'; } /* '' */
.icon-glyph-99:before { content: '\eb56'; } /* '' */
.icon-glyph-100:before { content: '\eb57'; } /* '' */
.icon-DocumentLibrary:before { content: '\eb58'; } /* '' */
.icon-Listing:before { content: '\eb59'; } /* '' */
.icon-transferdata:before { content: '\eb5a'; } /* '' */
.icon-delete:before { content: '\eb5b'; } /* '' */
.icon-send-mom:before { content: '\eb5c'; } /* '' */
.icon-check-out:before { content: '\eb5d'; } /* '' */
.icon-check-in:before { content: '\eb5e'; } /* '' */
.icon-absent:before { content: '\eb5f'; } /* '' */
.icon-default-user-icon:before { content: '\eb60'; } /* '' */
.icon-present:before { content: '\eb61'; } /* '' */
.icon-category:before { content: '\eb62'; } /* '' */
.icon-apis:before { content: '\eb63'; } /* '' */
.icon-chooseexisting:before { content: '\eb64'; } /* '' */
.icon-database2:before { content: '\eb65'; } /* '' */
.icon-dataconnection:before { content: '\eb66'; } /* '' */
.icon-datatransformation:before { content: '\eb67'; } /* '' */
.icon-external-widget:before { content: '\eb68'; } /* '' */
.icon-form:before { content: '\eb69'; } /* '' */
.icon-graph3:before { content: '\eb6a'; } /* '' */
.icon-KnowMoreArrow:before { content: '\eb6b'; } /* '' */
.icon-lisitng2:before { content: '\eb6c'; } /* '' */
.icon-msmq:before { content: '\eb6d'; } /* '' */
.icon-PlusIcon:before { content: '\eb6e'; } /* '' */
.icon-edit:before { content: '\eb6f'; } /* '' */
.icon-validate:before { content: '\eb70'; } /* '' */
.icon-widget2:before { content: '\eb71'; } /* '' */
.icon-invite:before { content: '\eb72'; } /* '' */
.icon-Cogentmeeting:before { content: '\eb73'; } /* '' */
.icon-InternalConnections:before { content: '\eb74'; } /* '' */
.icon-compare:before { content: '\eb75'; } /* '' */
.icon-Matrix:before { content: '\eb76'; } /* '' */
.icon-masssms:before { content: '\eb77'; } /* '' */
.icon-Tabular:before { content: '\eb78'; } /* '' */
.icon-glyph-101:before { content: '\eb79'; } /* '' */
.icon-glyph-102:before { content: '\eb7a'; } /* '' */
.icon-glyph-103:before { content: '\eb7b'; } /* '' */
.icon-glyph-104:before { content: '\eb7c'; } /* '' */
.icon-glyph-105:before { content: '\eb7d'; } /* '' */
.icon-glyph-106:before { content: '\eb7e'; } /* '' */
.icon-glyph-107:before { content: '\eb7f'; } /* '' */
.icon-glyph-108:before { content: '\eb80'; } /* '' */
.icon-glyph-109:before { content: '\eb81'; } /* '' */
.icon-glyph-110:before { content: '\eb82'; } /* '' */
.icon-glyph-111:before { content: '\eb83'; } /* '' */
.icon-glyph-112:before { content: '\eb84'; } /* '' */
.icon-glyph-113:before { content: '\eb85'; } /* '' */
.icon-glyph-114:before { content: '\eb86'; } /* '' */
.icon-glyph-115:before { content: '\eb87'; } /* '' */
.icon-glyph-116:before { content: '\eb88'; } /* '' */
.icon-glyph-117:before { content: '\eb89'; } /* '' */
.icon-glyph-118:before { content: '\eb8a'; } /* '' */
.icon-widget-Form:before { content: '\eb8b'; } /* '' */
.icon-widget-Listing:before { content: '\eb8c'; } /* '' */
.icon-DataMining:before { content: '\eb8d'; } /* '' */
.icon-Importcopy:before { content: '\eb8e'; } /* '' */
.icon-Milestone:before { content: '\eb8f'; } /* '' */
.icon-parallelactivity:before { content: '\eb90'; } /* '' */
.icon-Publish:before { content: '\eb91'; } /* '' */
.icon-Recommendations:before { content: '\eb92'; } /* '' */
.icon-SelectJob:before { content: '\eb93'; } /* '' */
.icon-state:before { content: '\eb94'; } /* '' */
.icon-qualitycheck:before { content: '\eb95'; } /* '' */
.icon-coachtext:before { content: '\eb96'; } /* '' */
.icon-Whitespace:before { content: '\eb97'; } /* '' */
.icon-XOR:before { content: '\eb98'; } /* '' */
.icon-MiningModel:before { content: '\eb99'; } /* '' */
.icon-ReferenceModel:before { content: '\eb9a'; } /* '' */
.icon-SelectAttributes:before { content: '\eb9b'; } /* '' */
.icon-integration2:before { content: '\eb9c'; } /* '' */
.icon-broadcast:before { content: '\eb9d'; } /* '' */
.icon-runcallscript:before { content: '\eb9e'; } /* '' */
.icon-massdelete:before { content: '\eb9f'; } /* '' */
.icon-glyph-119:before { content: '\eba0'; } /* '' */
.icon-glyph-120:before { content: '\eba1'; } /* '' */
.icon-glyph-121:before { content: '\eba2'; } /* '' */
.icon-glyph-122:before { content: '\eba3'; } /* '' */
.icon-gpsstart:before { content: '\eba4'; } /* '' */
.icon-glyph-123:before { content: '\eba5'; } /* '' */
.icon-glyph-124:before { content: '\eba6'; } /* '' */
.icon-glyph-125:before { content: '\eba7'; } /* '' */
.icon-glyph-126:before { content: '\eba8'; } /* '' */
.icon-glyph-127:before { content: '\eba9'; } /* '' */
.icon-glyph-128:before { content: '\ebaa'; } /* '' */
.icon-lead2:before { content: '\ebab'; } /* '' */
.icon-glyph-130:before { content: '\ebac'; } /* '' */
.icon-glyph-131:before { content: '\ebad'; } /* '' */
.icon-glyph-132:before { content: '\ebae'; } /* '' */
.icon-glyph-133:before { content: '\ebaf'; } /* '' */
.icon-glyph-134:before { content: '\ebb0'; } /* '' */
.icon-glyph-135:before { content: '\ebb1'; } /* '' */
.icon-glyph-136:before { content: '\ebb2'; } /* '' */
.icon-glyph-137:before { content: '\ebb3'; } /* '' */
.icon-glyph-138:before { content: '\ebb4'; } /* '' */
.icon-glyph-139:before { content: '\ebb5'; } /* '' */
.icon-glyph-140:before { content: '\ebb6'; } /* '' */
.icon-glyph-141:before { content: '\ebb7'; } /* '' */
.icon-glyph-142:before { content: '\ebb8'; } /* '' */
.icon-glyph-143:before { content: '\ebb9'; } /* '' */
.icon-glyph-144:before { content: '\ebba'; } /* '' */
.icon-glyph-145:before { content: '\ebbb'; } /* '' */
.icon-glyph-146:before { content: '\ebbc'; } /* '' */
.icon-glyph-147:before { content: '\ebbd'; } /* '' */
.icon-glyph-148:before { content: '\ebbe'; } /* '' */
.icon-glyph-149:before { content: '\ebbf'; } /* '' */
.icon-glyph-150:before { content: '\ebc0'; } /* '' */
.icon-glyph-151:before { content: '\ebc1'; } /* '' */
.icon-glyph-152:before { content: '\ebc2'; } /* '' */
.icon-glyph-153:before { content: '\ebc3'; } /* '' */
.icon-glyph-154:before { content: '\ebc4'; } /* '' */
.icon-glyph-155:before { content: '\ebc5'; } /* '' */
.icon-glyph-156:before { content: '\ebc6'; } /* '' */
.icon-datapresenter:before { content: '\ebc7'; } /* '' */
.icon-massupdate:before { content: '\ebc8'; } /* '' */
.icon-qualify:before { content: '\ebc9'; } /* '' */
.icon-glyph-157:before { content: '\ebca'; } /* '' */
.icon-glyph-158:before { content: '\ebcb'; } /* '' */
.icon-validatewidget:before { content: '\ebcc'; } /* '' */
.icon-findscript:before { content: '\ebcd'; } /* '' */
.icon-AmazonDrive:before { content: '\ebce'; } /* '' */
.icon-dropbox:before { content: '\ebcf'; } /* '' */
.icon-GoogleDrive:before { content: '\ebd0'; } /* '' */
.icon-OneDrive:before { content: '\ebd1'; } /* '' */
.icon-ActionButton:before { content: '\ebd2'; } /* '' */
.icon-CrossSellAnalyzer2:before { content: '\ebd3'; } /* '' */
.icon-datapresenter2:before { content: '\ebd4'; } /* '' */
.icon-FB:before { content: '\ebd5'; } /* '' */
.icon-print:before { content: '\ebd6'; } /* '' */
.icon-massemail:before { content: '\ebd7'; } /* '' */
.icon-GooglePlus:before { content: '\ebd8'; } /* '' */
.icon-glyph-159:before { content: '\ebd9'; } /* '' */
.icon-glyph-160:before { content: '\ebda'; } /* '' */
.icon-glyph-161:before { content: '\ebdb'; } /* '' */
.icon-Key:before { content: '\ebdc'; } /* '' */
.icon-glyph-162:before { content: '\ebdd'; } /* '' */
.icon-glyph-163:before { content: '\ebde'; } /* '' */
.icon-linkedin:before { content: '\ebdf'; } /* '' */
.icon-glyph-164:before { content: '\ebe0'; } /* '' */
.icon-glyph-165:before { content: '\ebe1'; } /* '' */
.icon-glyph-166:before { content: '\ebe2'; } /* '' */
.icon-glyph-167:before { content: '\ebe3'; } /* '' */
.icon-glyph-168:before { content: '\ebe4'; } /* '' */
.icon-glyph-169:before { content: '\ebe5'; } /* '' */
.icon-glyph-170:before { content: '\ebe6'; } /* '' */
.icon-glyph-171:before { content: '\ebe7'; } /* '' */
.icon-glyph-172:before { content: '\ebe8'; } /* '' */
.icon-glyph-173:before { content: '\ebe9'; } /* '' */
.icon-glyph-174:before { content: '\ebea'; } /* '' */
.icon-glyph-175:before { content: '\ebeb'; } /* '' */
.icon-glyph-176:before { content: '\ebec'; } /* '' */
.icon-glyph-177:before { content: '\ebed'; } /* '' */
.icon-glyph-178:before { content: '\ebee'; } /* '' */
.icon-MiningModel2:before { content: '\ebef'; } /* '' */
.icon-OneDrive2:before { content: '\ebf0'; } /* '' */
.icon-OneSection:before { content: '\ebf1'; } /* '' */
.icon-glyph-179:before { content: '\ebf2'; } /* '' */
.icon-glyph-180:before { content: '\ebf3'; } /* '' */
.icon-ReferenceModel2:before { content: '\ebf4'; } /* '' */
.icon-cancel:before { content: '\ebf5'; } /* '' */
.icon-SelectAttributes2:before { content: '\ebf6'; } /* '' */
.icon-sendoffers:before { content: '\ebf7'; } /* '' */
.icon-glyph-181:before { content: '\ebf8'; } /* '' */
.icon-glyph-182:before { content: '\ebf9'; } /* '' */
.icon-glyph-183:before { content: '\ebfa'; } /* '' */
.icon-glyph-184:before { content: '\ebfb'; } /* '' */
.icon-glyph-185:before { content: '\ebfc'; } /* '' */
.icon-glyph-186:before { content: '\ebfd'; } /* '' */
.icon-glyph-187:before { content: '\ebfe'; } /* '' */
.icon-glyph-188:before { content: '\ebff'; } /* '' */
.icon-glyph-189:before { content: '\ec00'; } /* '' */
.icon-glyph-190:before { content: '\ec01'; } /* '' */
.icon-glyph-191:before { content: '\ec02'; } /* '' */
.icon-glyph-192:before { content: '\ec03'; } /* '' */
.icon-glyph-193:before { content: '\ec04'; } /* '' */
.icon-StepProcess:before { content: '\ec05'; } /* '' */
.icon-Tab-OneSection:before { content: '\ec06'; } /* '' */
.icon-Tab-ThreeSection:before { content: '\ec07'; } /* '' */
.icon-Tab-TwoSection:before { content: '\ec08'; } /* '' */
.icon-ThreeSection:before { content: '\ec09'; } /* '' */
.icon-TwoSection:before { content: '\ec0a'; } /* '' */
.icon-user:before { content: '\ec0b'; } /* '' */
.icon-validatewidget2:before { content: '\ec0c'; } /* '' */
.icon-saveandnew:before { content: '\ec0d'; } /* '' */
.icon-mobile5:before { content: '\ec0e'; } /* '' */
.icon-monitor:before { content: '\ec0f'; } /* '' */
.icon-tablet2:before { content: '\ec10'; } /* '' */
.icon-DragButton:before { content: '\ec11'; } /* '' */
.icon-Blank:before { content: '\ec12'; } /* '' */
.icon-card-1:before { content: '\ec13'; } /* '' */
.icon-card-2:before { content: '\ec14'; } /* '' */
.icon-gpstracking4:before { content: '\ec15'; } /* '' */
.icon-addxslt:before { content: '\ec16'; } /* '' */
.icon-organized:before { content: '\ec17'; } /* '' */
.icon-thinkingmodel:before { content: '\ec18'; } /* '' */
.icon-Cross:before { content: '\ec19'; } /* '' */
.icon-viewhistory:before { content: '\ec1a'; } /* '' */
.icon-massshare:before { content: '\ec1b'; } /* '' */
.icon-mailinglist:before { content: '\ec1c'; } /* '' */
.icon-Summary:before { content: '\ec1d'; } /* '' */
.icon-okay:before { content: '\ec1e'; } /* '' */
.icon-alert:before { content: '\ec1f'; } /* '' */
.icon-asset:before { content: '\ec20'; } /* '' */
.icon-event:before { content: '\ec21'; } /* '' */
.icon-issue:before { content: '\ec22'; } /* '' */
.icon-logout:before { content: '\ec23'; } /* '' */
.icon-reportexecute:before { content: '\ec24'; } /* '' */
.icon-glyph-194:before { content: '\ec25'; } /* '' */
.icon-glyph-195:before { content: '\ec26'; } /* '' */
.icon-Design:before { content: '\ec27'; } /* '' */
.icon-DMS_Icon1:before { content: '\ec28'; } /* '' */
.icon-DMS_Icon2:before { content: '\ec29'; } /* '' */
.icon-glyph-196:before { content: '\ec2a'; } /* '' */
.icon-glyph-197:before { content: '\ec2b'; } /* '' */
.icon-glyph-198:before { content: '\ec2c'; } /* '' */
.icon-glyph-199:before { content: '\ec2d'; } /* '' */
.icon-glyph-200:before { content: '\ec2e'; } /* '' */
.icon-logacall:before { content: '\ec2f'; } /* '' */
.icon-Subtract:before { content: '\ec30'; } /* '' */
.icon-Survey-close:before { content: '\ec31'; } /* '' */
.icon-Survey-draft:before { content: '\ec32'; } /* '' */
.icon-survey-open:before { content: '\ec33'; } /* '' */
.icon-Subtract2:before { content: '\ec34'; } /* '' */
.icon-Groups:before { content: '\ec35'; } /* '' */
.icon-content-library2:before { content: '\ec36'; } /* '' */
.icon-filter-constraint-1:before { content: '\ec37'; } /* '' */
.icon-Matrix2:before { content: '\ec38'; } /* '' */
.icon-reportcategory2:before { content: '\ec39'; } /* '' */
.icon-glyph-201:before { content: '\ec3a'; } /* '' */
.icon-glyph-202:before { content: '\ec3b'; } /* '' */
.icon-glyph-203:before { content: '\ec3c'; } /* '' */
.icon-glyph-204:before { content: '\ec3d'; } /* '' */
.icon-VersionIcon:before { content: '\ec3e'; } /* '' */
.icon-reportscheduling:before { content: '\ec3f'; } /* '' */
.icon-Tabular2:before { content: '\ec40'; } /* '' */
.icon-Template2:before { content: '\ec41'; } /* '' */
.icon-glyph-205:before { content: '\ec42'; } /* '' */
.icon-glyph-206:before { content: '\ec43'; } /* '' */
.icon-glyph-207:before { content: '\ec44'; } /* '' */
.icon-glyph-208:before { content: '\ec45'; } /* '' */
.icon-BillPay_Icon:before { content: '\ec46'; } /* '' */
.icon-ChannelBehaviour_Icon:before { content: '\ec47'; } /* '' */
.icon-Deposit_Icon:before { content: '\ec48'; } /* '' */
.icon-glyph-209:before { content: '\ec49'; } /* '' */
.icon-glyph-210:before { content: '\ec4a'; } /* '' */
.icon-glyph-211:before { content: '\ec4b'; } /* '' */
.icon-glyph-212:before { content: '\ec4c'; } /* '' */
.icon-Mobile_Icon:before { content: '\ec4d'; } /* '' */
.icon-Teller_Icon:before { content: '\ec4e'; } /* '' */
.icon-Back:before { content: '\ec4f'; } /* '' */
.icon-Dialer:before { content: '\ec50'; } /* '' */
.icon-EndCall:before { content: '\ec51'; } /* '' */
.icon-videochat:before { content: '\ec52'; } /* '' */
.icon-administrative-settings:before { content: '\ec53'; } /* '' */
.icon-card-temp:before { content: '\ec54'; } /* '' */
.icon-customization22:before { content: '\ec55'; } /* '' */
.icon-changeowner:before { content: '\ec56'; } /* '' */
.icon-import2:before { content: '\ec57'; } /* '' */
.icon-personal-settings:before { content: '\ec58'; } /* '' */
.icon-synchronization:before { content: '\ec59'; } /* '' */
.icon-add-section1:before { content: '\ec5a'; } /* '' */
.icon-add-tab1:before { content: '\ec5b'; } /* '' */
.icon-copy-to-other:before { content: '\ec5c'; } /* '' */
.icon-Ecosystem:before { content: '\ec5d'; } /* '' */
.icon-Remove-Customization:before { content: '\ec5e'; } /* '' */
.icon-Reset-Icon:before { content: '\ec5f'; } /* '' */
.icon-Trinity-Cross-Sell-Analyser:before { content: '\ec60'; } /* '' */
.icon-field-info:before { content: '\ec61'; } /* '' */
.icon-follower-icon:before { content: '\ec62'; } /* '' */
.icon-followers:before { content: '\ec63'; } /* '' */
.icon-validate2:before { content: '\ec64'; } /* '' */
.icon-Table_CheckSome1:before { content: '\ec65'; } /* '' */
.icon-Deight1:before { content: '\ec66'; } /* '' */
.icon-Deight2:before { content: '\ec67'; } /* '' */
.icon-recordupdateDesn:before { content: '\ec68'; } /* '' */
.icon-obj1:before { content: '\ec69'; } /* '' */
.icon-obj275:before { content: '\ec6a'; } /* '' */
.icon-dms-tileview:before { content: '\ec6b'; } /* '' */
.icon-Wait_Object1:before { content: '\ec6c'; } /* '' */
.icon-Wait_Object2:before { content: '\ec6d'; } /* '' */
.icon-GPS:before { content: '\ec6e'; } /* '' */
.icon-multiaxis:before { content: '\ec6f'; } /* '' */
.icon-pyramidgraph:before { content: '\ec70'; } /* '' */
.icon-singleaxis:before { content: '\ec71'; } /* '' */
.icon-twoaxis:before { content: '\ec72'; } /* '' */
.icon-filter-display-1:before { content: '\ec73'; } /* '' */
.icon-integration:before { content: '\ec74'; } /* '' */
.icon-disqualify:before { content: '\ec75'; } /* '' */
.icon-screenflow:before { content: '\ec76'; } /* '' */
.icon-Pool-Transfer:before { content: '\ec77'; } /* '' */
.icon-Reports:before { content: '\ec78'; } /* '' */
.icon-delightmanagement:before { content: '\ec79'; } /* '' */
.icon-obj426:before { content: '\ec7a'; } /* '' */
.icon-delight:before { content: '\ec7b'; } /* '' */
.icon-Dropdown_Hollow:before { content: '\ec7c'; } /* '' */
.icon-Paragraph_Filled:before { content: '\ec7d'; } /* '' */
.icon-Paragraph_Hollow:before { content: '\ec7e'; } /* '' */
.icon-favourite-filled:before { content: '\ec7f'; } /* '' */
.icon-backward:before { content: '\ec80'; } /* '' */
.icon-forward2:before { content: '\ec81'; } /* '' */
.icon-Actionable:before { content: '\ec82'; } /* '' */
.icon-Anniversary-Birthday:before { content: '\ec83'; } /* '' */
.icon-Conditional:before { content: '\ec84'; } /* '' */
.icon-Externals:before { content: '\ec85'; } /* '' */
.icon-Reminder:before { content: '\ec86'; } /* '' */
.icon-System:before { content: '\ec87'; } /* '' */
.icon-Like_Empty:before { content: '\ec88'; } /* '' */
.icon-Like_Filled:before { content: '\ec89'; } /* '' */
.icon-patternmanagement:before { content: '\ec8a'; } /* '' */
.icon-planmanagement:before { content: '\ec8b'; } /* '' */
.icon-mergemail:before { content: '\ec8c'; } /* '' */
.icon-screenflowjourney:before { content: '\ec8d'; } /* '' */
.icon-obj432:before { content: '\ec8e'; } /* '' */
.icon-obj433:before { content: '\ec8f'; } /* '' */
.icon-statuscode-order:before { content: '\ec90'; } /* '' */
.icon-Import2:before { content: '\ec91'; } /* '' */
.icon-action-message:before { content: '\ec92'; } /* '' */
.icon-notification-message:before { content: '\ec93'; } /* '' */
.icon-ManageCustomColumn:before { content: '\ec94'; } /* '' */
.icon-SetParameters:before { content: '\ec95'; } /* '' */
.icon-SharedCalendar:before { content: '\ec96'; } /* '' */
.icon-sort:before { content: '\ec97'; } /* '' */
.icon-leads-conversions:before { content: '\ec98'; } /* '' */
.icon-call-autoflow:before { content: '\ec99'; } /* '' */
.icon-next:before { content: '\ec9a'; } /* '' */
.icon-saveproceed:before { content: '\ec9b'; } /* '' */
.icon-Reset:before { content: '\ec9c'; } /* '' */
.icon-AutomatchA:before { content: '\ec9d'; } /* '' */
.icon-AutomatchB:before { content: '\ec9e'; } /* '' */
.icon-trigger-02:before { content: '\ec9f'; } /* '' */
.icon-trigger_01:before { content: '\eca0'; } /* '' */
.icon-trigger_data-source:before { content: '\eca1'; } /* '' */
.icon-trigger_prepare-safe:before { content: '\eca2'; } /* '' */
.icon-trigger_prepopulate-01:before { content: '\eca3'; } /* '' */
.icon-trigger_validate_svg:before { content: '\eca4'; } /* '' */
.icon-roster1:before { content: '\eca5'; } /* '' */
.icon-roster2:before { content: '\eca6'; } /* '' */
.icon-roster3-3:before { content: '\eca7'; } /* '' */
.icon-roster3:before { content: '\eca8'; } /* '' */
.icon-obj441:before { content: '\eca9'; } /* '' */
.icon-icon_Audience:before { content: '\ecaa'; } /* '' */
.icon-icon_bar_graph:before { content: '\ecab'; } /* '' */
.icon-icon_Boxed_text:before { content: '\ecac'; } /* '' */
.icon-icon_button:before { content: '\ecad'; } /* '' */
.icon-calander:before { content: '\ecae'; } /* '' */
.icon-icon_chart:before { content: '\ecaf'; } /* '' */
.icon-icon_Condition:before { content: '\ecb0'; } /* '' */
.icon-icon_Conference:before { content: '\ecb1'; } /* '' */
.icon-icon_crop:before { content: '\ecb2'; } /* '' */
.icon-icon_Custom:before { content: '\ecb3'; } /* '' */
.icon-icon_Decision:before { content: '\ecb4'; } /* '' */
.icon-icon_Divider:before { content: '\ecb5'; } /* '' */
.icon-icon_download:before { content: '\ecb6'; } /* '' */
.icon-icon_Download_PDF:before { content: '\ecb7'; } /* '' */
.icon-icon_Everyone:before { content: '\ecb8'; } /* '' */
.icon-icon_expand:before { content: '\ecb9'; } /* '' */
.icon-icon_export:before { content: '\ecba'; } /* '' */
.icon-filter:before { content: '\ecbb'; } /* '' */
.icon-icon_grid_view:before { content: '\ecbc'; } /* '' */
.icon-icon_Image_caption:before { content: '\ecbd'; } /* '' */
.icon-icon_Image_card:before { content: '\ecbe'; } /* '' */
.icon-icon_line_graph:before { content: '\ecbf'; } /* '' */
.icon-weburl:before { content: '\ecc0'; } /* '' */
.icon-menu:before { content: '\ecc1'; } /* '' */
.icon-icon_new_file:before { content: '\ecc2'; } /* '' */
.icon-icon_Newslettes:before { content: '\ecc3'; } /* '' */
.icon-icon_none:before { content: '\ecc4'; } /* '' */
.icon-icon_Notification:before { content: '\ecc5'; } /* '' */
.icon-icon_pan:before { content: '\ecc6'; } /* '' */
.icon-icon_pie_graph:before { content: '\ecc7'; } /* '' */
.icon-glyph-213:before { content: '\ecc8'; } /* '' */
.icon-glyph-214:before { content: '\ecc9'; } /* '' */
.icon-icon_pointer:before { content: '\ecca'; } /* '' */
.icon-icon_preview:before { content: '\eccb'; } /* '' */
.icon-icon_redo:before { content: '\eccc'; } /* '' */
.icon-icon_selection:before { content: '\eccd'; } /* '' */
.icon-icon_Social:before { content: '\ecce'; } /* '' */
.icon-icon_Social_share:before { content: '\eccf'; } /* '' */
.icon-icon_start:before { content: '\ecd0'; } /* '' */
.icon-icon_tap_here:before { content: '\ecd1'; } /* '' */
.icon-icon_transform:before { content: '\ecd2'; } /* '' */
.icon-icon_undo:before { content: '\ecd3'; } /* '' */
.icon-icon_Wait:before { content: '\ecd4'; } /* '' */
.icon-icon_Webinar:before { content: '\ecd5'; } /* '' */
.icon-name:before { content: '\ecd6'; } /* '' */
.icon-set-parameters:before { content: '\ecd7'; } /* '' */
.icon-assignment:before { content: '\ecd8'; } /* '' */
.icon-decision22:before { content: '\ecd9'; } /* '' */
.icon-evaluate:before { content: '\ecda'; } /* '' */
.icon-if-condition:before { content: '\ecdb'; } /* '' */
.icon-parallel2:before { content: '\ecdc'; } /* '' */
.icon-rule-reference:before { content: '\ecdd'; } /* '' */
.icon-start:before { content: '\ecde'; } /* '' */
.icon-switchprocess:before { content: '\ecdf'; } /* '' */
.icon-switch-case:before { content: '\ece0'; } /* '' */
.icon-customization:before { content: '\ece1'; } /* '' */
.icon-decision1:before { content: '\ece2'; } /* '' */
.icon-glyph-215:before { content: '\ece3'; } /* '' */
.icon-glyph-216:before { content: '\ece4'; } /* '' */
.icon-data-format:before { content: '\ece5'; } /* '' */
.icon-highlight:before { content: '\ece6'; } /* '' */
.icon-link_round:before { content: '\ece7'; } /* '' */
.icon-multi-assign:before { content: '\ece8'; } /* '' */
.icon-setsolid:before { content: '\ece9'; } /* '' */
.icon-sortby__arrowdwn:before { content: '\ecea'; } /* '' */
.icon-summation:before { content: '\eceb'; } /* '' */
.icon-upload_up:before { content: '\ecec'; } /* '' */
.icon-vertical_bar:before { content: '\eced'; } /* '' */
.icon-clicked-2:before { content: '\ecee'; } /* '' */
.icon-cmpimage:before { content: '\ecef'; } /* '' */
.icon-cmpnimg:before { content: '\ecf0'; } /* '' */
.icon-getready:before { content: '\ecf1'; } /* '' */
.icon-asset_01:before { content: '\ecf2'; } /* '' */
.icon-asset_04:before { content: '\ecf3'; } /* '' */
.icon-asset_05:before { content: '\ecf4'; } /* '' */
.icon-Back2:before { content: '\ecf5'; } /* '' */
.icon-aggregate:before { content: '\ecf6'; } /* '' */
.icon-glyph-217:before { content: '\ecf7'; } /* '' */
.icon-glyph-218:before { content: '\ecf8'; } /* '' */
.icon-glyph-219:before { content: '\ecf9'; } /* '' */
.icon-glyph-220:before { content: '\ecfa'; } /* '' */
.icon-glyph-221:before { content: '\ecfb'; } /* '' */
.icon-glyph-222:before { content: '\ecfc'; } /* '' */
.icon-customize-colour:before { content: '\ecfd'; } /* '' */
.icon-hide-details:before { content: '\ecfe'; } /* '' */
.icon-show-in-template:before { content: '\ecff'; } /* '' */
.icon-target-mobile-feild:before { content: '\ed00'; } /* '' */
.icon-target-mobile-feild1:before { content: '\ed01'; } /* '' */
.icon-target-mobile-feild2:before { content: '\ed02'; } /* '' */
.icon-filter-constraint:before { content: '\ed03'; } /* '' */
.icon-filter-display:before { content: '\ed04'; } /* '' */
.icon-percentage:before { content: '\ed05'; } /* '' */
.icon-glyph-223:before { content: '\ed06'; } /* '' */
.icon-pause:before { content: '\ed07'; } /* '' */
.icon-dashedfile:before { content: '\ed08'; } /* '' */
.icon-joined:before { content: '\ed09'; } /* '' */
.icon-sound:before { content: '\ed0a'; } /* '' */
.icon-opencalendar:before { content: '\ed0b'; } /* '' */
.icon-migrate:before { content: '\ed0c'; } /* '' */
.icon-obj6-1:before { content: '\ed0d'; } /* '' */
.icon-declined:before { content: '\ed0e'; } /* '' */
.icon-delivered:before { content: '\ed0f'; } /* '' */
.icon-dudupe-element:before { content: '\ed10'; } /* '' */
.icon-join:before { content: '\ed11'; } /* '' */
.icon-open:before { content: '\ed12'; } /* '' */
.icon-read:before { content: '\ed13'; } /* '' */
.icon-subscribe:before { content: '\ed14'; } /* '' */
.icon-undelivered:before { content: '\ed15'; } /* '' */
.icon-unread:before { content: '\ed16'; } /* '' */
.icon-unsubscribe:before { content: '\ed17'; } /* '' */
.icon-liabilitymanagement:before { content: '\ed18'; } /* '' */
.icon-deviationmanagement:before { content: '\ed19'; } /* '' */
.icon-limits:before { content: '\ed1a'; } /* '' */
.icon-subsidiary:before { content: '\ed1b'; } /* '' */
.icon-plus:before { content: '\ed1c'; } /* '' */
.icon-tick:before { content: '\ed1d'; } /* '' */
.icon-checked:before { content: '\ed1e'; } /* '' */
.icon-calendar:before { content: '\ed1f'; } /* '' */
.icon-bulkgeneration:before { content: '\ed20'; } /* '' */
.icon-surveymanagement:before { content: '\ed21'; } /* '' */
.icon-col-sum-title:before { content: '\ed22'; } /* '' */
.icon-remv-field:before { content: '\ed23'; } /* '' */
.icon-row-sum-title:before { content: '\ed24'; } /* '' */
.icon-shw-row-sumnew:before { content: '\ed25'; } /* '' */
.icon-shw-col-sumnew:before { content: '\ed26'; } /* '' */
.icon-call-center-setup:before { content: '\ed27'; } /* '' */
.icon-config-authentication:before { content: '\ed28'; } /* '' */
.icon-config-DMS:before { content: '\ed29'; } /* '' */
.icon-config-email:before { content: '\ed2a'; } /* '' */
.icon-config-integration:before { content: '\ed2b'; } /* '' */
.icon-config-listing:before { content: '\ed2c'; } /* '' */
.icon-dashedfile-1:before { content: '\ed2d'; } /* '' */
.icon-filtersolid:before { content: '\ed2e'; } /* '' */
.icon-config-SSP:before { content: '\ed2f'; } /* '' */
.icon-config-web-api:before { content: '\ed30'; } /* '' */
.icon-directory-path:before { content: '\ed31'; } /* '' */
.icon-email-management:before { content: '\ed32'; } /* '' */
.icon-email-syndication:before { content: '\ed33'; } /* '' */
.icon-show_template:before { content: '\ed34'; } /* '' */
.icon-style:before { content: '\ed35'; } /* '' */
.icon-summary_title:before { content: '\ed36'; } /* '' */
.icon-Aggregate:before { content: '\ed37'; } /* '' */
.icon-Ascending:before { content: '\ed38'; } /* '' */
.icon-joined-1:before { content: '\ed39'; } /* '' */
.icon-sound-1:before { content: '\ed3a'; } /* '' */
.icon-spark:before { content: '\ed3b'; } /* '' */
.icon-bounced:before { content: '\ed3c'; } /* '' */
.icon-dudupe-element-1:before { content: '\ed3d'; } /* '' */
.icon-join-1:before { content: '\ed3e'; } /* '' */
.icon-open-1:before { content: '\ed3f'; } /* '' */
.icon-read-1:before { content: '\ed40'; } /* '' */
.icon-subscribe-1:before { content: '\ed41'; } /* '' */
.icon-tick3:before { content: '\ed42'; } /* '' */
.icon-undelivered-1:before { content: '\ed43'; } /* '' */
.icon-unread-1:before { content: '\ed44'; } /* '' */
.icon-unsubscribe-1:before { content: '\ed45'; } /* '' */
.icon-CURRENCY-FORMAT:before { content: '\ed46'; } /* '' */
.icon-DATE-AND-TIME-FORMAT:before { content: '\ed47'; } /* '' */
.icon-Descending:before { content: '\ed48'; } /* '' */
.icon-New-Section:before { content: '\ed49'; } /* '' */
.icon-SORTING:before { content: '\ed4a'; } /* '' */
.icon-obj8-1:before { content: '\ed4b'; } /* '' */
.icon-icon_delete:before { content: '\ed4c'; } /* '' */
.icon-icon_Divider-1:before { content: '\ed4d'; } /* '' */
.icon-icon_download-1:before { content: '\ed4e'; } /* '' */
.icon-icon_Download_PDF-1:before { content: '\ed4f'; } /* '' */
.icon-icon_link:before { content: '\ed50'; } /* '' */
.icon-icon_menu:before { content: '\ed51'; } /* '' */
.icon-icon_Message:before { content: '\ed52'; } /* '' */
.icon-icon_new_file-1:before { content: '\ed53'; } /* '' */
.icon-icon_tap_here-1:before { content: '\ed54'; } /* '' */
.icon-icon_transform-1:before { content: '\ed55'; } /* '' */
.icon-icon_undo-1:before { content: '\ed56'; } /* '' */
.icon-icon_Wait-1:before { content: '\ed57'; } /* '' */
.icon-customization-1:before { content: '\ed58'; } /* '' */
.icon-decision1-1:before { content: '\ed59'; } /* '' */
.icon-glyph-129:before { content: '\ed5a'; } /* '' */
.icon-glyph-224:before { content: '\ed5b'; } /* '' */
.icon-asset_01-1:before { content: '\ed5c'; } /* '' */
.icon-asset_02:before { content: '\ed5d'; } /* '' */
.icon-asset_03:before { content: '\ed5e'; } /* '' */
.icon-asset_04-1:before { content: '\ed5f'; } /* '' */
.icon-target-mobile-feild-1:before { content: '\ed60'; } /* '' */
.icon-target-mobile-feild1-1:before { content: '\ed61'; } /* '' */
.icon-target-mobile-feild2-1:before { content: '\ed62'; } /* '' */
.icon-target-mobile-feild2A:before { content: '\ed63'; } /* '' */
.icon-config-outlook:before { content: '\ed64'; } /* '' */
.icon-config-screenflow:before { content: '\ed65'; } /* '' */
.icon-config-sms:before { content: '\ed66'; } /* '' */
.icon-config-social:before { content: '\ed67'; } /* '' */
.icon-clicked:before { content: '\ed68'; } /* '' */
.icon-closed:before { content: '\ed69'; } /* '' */
.icon-declined-1:before { content: '\ed6a'; } /* '' */
.icon-delivered-1:before { content: '\ed6b'; } /* '' */
.icon-trigger_validate_svg-1:before { content: '\ed6c'; } /* '' */
.icon-roster1-1:before { content: '\ed6d'; } /* '' */
.icon-roster2-1:before { content: '\ed6e'; } /* '' */
.icon-roster3-2:before { content: '\ed6f'; } /* '' */
.icon-glyph-257:before { content: '\ed70'; } /* '' */
.icon-glyph-258:before { content: '\ed71'; } /* '' */
.icon-glyph-259:before { content: '\ed72'; } /* '' */
.icon-glyph-260:before { content: '\ed73'; } /* '' */
.icon-glyph-261:before { content: '\ed74'; } /* '' */
.icon-glyph-262:before { content: '\ed75'; } /* '' */
.icon-glyph-263:before { content: '\ed76'; } /* '' */
.icon-validatewidget-1:before { content: '\ed77'; } /* '' */
.icon-widgetsearch:before { content: '\ed78'; } /* '' */
.icon-AmazonDrive-1:before { content: '\ed79'; } /* '' */
.icon-DropBox:before { content: '\ed7a'; } /* '' */
.icon-GoogleDrive-1:before { content: '\ed7b'; } /* '' */
.icon-roster3-1:before { content: '\ed7c'; } /* '' */
.icon-obj441-1:before { content: '\ed7d'; } /* '' */
.icon-glyph-225:before { content: '\ed7e'; } /* '' */
.icon-glyph-226:before { content: '\ed7f'; } /* '' */
.icon-icon_Audience-1:before { content: '\ed80'; } /* '' */
.icon-icon_bar_graph-1:before { content: '\ed81'; } /* '' */
.icon-icon_Boxed_text-1:before { content: '\ed82'; } /* '' */
.icon-icon_button-1:before { content: '\ed83'; } /* '' */
.icon-icon_calander:before { content: '\ed84'; } /* '' */
.icon-icon_chart-1:before { content: '\ed85'; } /* '' */
.icon-icon_close:before { content: '\ed86'; } /* '' */
.icon-icon_Condition-1:before { content: '\ed87'; } /* '' */
.icon-icon_Conference-1:before { content: '\ed88'; } /* '' */
.icon-icon_crop-1:before { content: '\ed89'; } /* '' */
.icon-icon_Custom-1:before { content: '\ed8a'; } /* '' */
.icon-icon_Decision-1:before { content: '\ed8b'; } /* '' */
.icon-icon_Drop_image:before { content: '\ed8c'; } /* '' */
.icon-icon_dropdown_arrow:before { content: '\ed8d'; } /* '' */
.icon-icon_edit:before { content: '\ed8e'; } /* '' */
.icon-icon_Email:before { content: '\ed8f'; } /* '' */
.icon-icon_End:before { content: '\ed90'; } /* '' */
.icon-icon_Events:before { content: '\ed91'; } /* '' */
.icon-icon_Everyone-1:before { content: '\ed92'; } /* '' */
.icon-icon_expand-1:before { content: '\ed93'; } /* '' */
.icon-icon_export-1:before { content: '\ed94'; } /* '' */
.icon-icon_filter:before { content: '\ed95'; } /* '' */
.icon-icon_grid_view-1:before { content: '\ed96'; } /* '' */
.icon-icon_Image:before { content: '\ed97'; } /* '' */
.icon-icon_Image_caption-1:before { content: '\ed98'; } /* '' */
.icon-icon_Image_card-1:before { content: '\ed99'; } /* '' */
.icon-icon_Image_group:before { content: '\ed9a'; } /* '' */
.icon-icon_line_graph-1:before { content: '\ed9b'; } /* '' */
.icon-icon_Newslettes-1:before { content: '\ed9c'; } /* '' */
.icon-icon_none-1:before { content: '\ed9d'; } /* '' */
.icon-icon_Notification-1:before { content: '\ed9e'; } /* '' */
.icon-icon_pan-1:before { content: '\ed9f'; } /* '' */
.icon-icon_pie_graph-1:before { content: '\eda0'; } /* '' */
.icon-glyph-227:before { content: '\eda1'; } /* '' */
.icon-glyph-228:before { content: '\eda2'; } /* '' */
.icon-icon_pointer-1:before { content: '\eda3'; } /* '' */
.icon-icon_preview-1:before { content: '\eda4'; } /* '' */
.icon-icon_redo-1:before { content: '\eda5'; } /* '' */
.icon-icon_search:before { content: '\eda6'; } /* '' */
.icon-icon_selection-1:before { content: '\eda7'; } /* '' */
.icon-icon_settings:before { content: '\eda8'; } /* '' */
.icon-icon_Social-1:before { content: '\eda9'; } /* '' */
.icon-icon_Social_share-1:before { content: '\edaa'; } /* '' */
.icon-icon_start-1:before { content: '\edab'; } /* '' */
.icon-icon_Webinar-1:before { content: '\edac'; } /* '' */
.icon-name-1:before { content: '\edad'; } /* '' */
.icon-set-parameters-1:before { content: '\edae'; } /* '' */
.icon-arrow-round-down:before { content: '\edaf'; } /* '' */
.icon-arrow-round-up:before { content: '\edb0'; } /* '' */
.icon-assignment-1:before { content: '\edb1'; } /* '' */
.icon-circle-cross:before { content: '\edb2'; } /* '' */
.icon-decision22-1:before { content: '\edb3'; } /* '' */
.icon-evaluate-1:before { content: '\edb4'; } /* '' */
.icon-if-condition-1:before { content: '\edb5'; } /* '' */
.icon-lookup2:before { content: '\edb6'; } /* '' */
.icon-parallel2-1:before { content: '\edb7'; } /* '' */
.icon-rule-reference-1:before { content: '\edb8'; } /* '' */
.icon-start-1:before { content: '\edb9'; } /* '' */
.icon-switch3:before { content: '\edba'; } /* '' */
.icon-switch-case-1:before { content: '\edbb'; } /* '' */
.icon-data-format-1:before { content: '\edbc'; } /* '' */
.icon-highlight-1:before { content: '\edbd'; } /* '' */
.icon-link_round-1:before { content: '\edbe'; } /* '' */
.icon-multi-assign-1:before { content: '\edbf'; } /* '' */
.icon-setsolid-1:before { content: '\edc0'; } /* '' */
.icon-sortby__arrowdwn-1:before { content: '\edc1'; } /* '' */
.icon-summation-1:before { content: '\edc2'; } /* '' */
.icon-upload_up-1:before { content: '\edc3'; } /* '' */
.icon-vertical_bar-1:before { content: '\edc4'; } /* '' */
.icon-calendarpick:before { content: '\edc5'; } /* '' */
.icon-clicked-1:before { content: '\edc6'; } /* '' */
.icon-cmpimage-1:before { content: '\edc7'; } /* '' */
.icon-cmpnimg-1:before { content: '\edc8'; } /* '' */
.icon-getready-1:before { content: '\edc9'; } /* '' */
.icon-previous-1:before { content: '\edca'; } /* '' */
.icon-finish:before { content: '\edcb'; } /* '' */
.icon-asset_05-1:before { content: '\edcc'; } /* '' */
.icon-Back2-1:before { content: '\edcd'; } /* '' */
.icon-aggregate-1:before { content: '\edce'; } /* '' */
.icon-glyph-229:before { content: '\edcf'; } /* '' */
.icon-glyph-230:before { content: '\edd0'; } /* '' */
.icon-glyph-231:before { content: '\edd1'; } /* '' */
.icon-glyph-232:before { content: '\edd2'; } /* '' */
.icon-glyph-233:before { content: '\edd3'; } /* '' */
.icon-glyph-234:before { content: '\edd4'; } /* '' */
.icon-CUSTOMIZE-COLOR-2:before { content: '\edd5'; } /* '' */
.icon-CUSTOMIZE-COLOR-3:before { content: '\edd6'; } /* '' */
.icon-customize-colour-1:before { content: '\edd7'; } /* '' */
.icon-hide-details-1:before { content: '\edd8'; } /* '' */
.icon-mailing-list:before { content: '\edd9'; } /* '' */
.icon-show-in-template-1:before { content: '\edda'; } /* '' */
.icon-target-mail:before { content: '\eddb'; } /* '' */
.icon-glyph-235:before { content: '\eddc'; } /* '' */
.icon-glyph-236:before { content: '\eddd'; } /* '' */
.icon-glyph-237:before { content: '\edde'; } /* '' */
.icon-reportschedule2:before { content: '\eddf'; } /* '' */
.icon-Accept:before { content: '\ede0'; } /* '' */
.icon-Add:before { content: '\ede1'; } /* '' */
.icon-Reject:before { content: '\ede2'; } /* '' */
.icon-Subtract-1:before { content: '\ede3'; } /* '' */
.icon-Survey-close-1:before { content: '\ede4'; } /* '' */
.icon-Survey-draft-1:before { content: '\ede5'; } /* '' */
.icon-survey-open-1:before { content: '\ede6'; } /* '' */
.icon-add-new:before { content: '\ede7'; } /* '' */
.icon-comment1:before { content: '\ede8'; } /* '' */
.icon-comment2:before { content: '\ede9'; } /* '' */
.icon-Subtract2-1:before { content: '\edea'; } /* '' */
.icon-Reject2:before { content: '\edeb'; } /* '' */
.icon-Groups-1:before { content: '\edec'; } /* '' */
.icon-content-library2-1:before { content: '\eded'; } /* '' */
.icon-Add2:before { content: '\edee'; } /* '' */
.icon-AddMember:before { content: '\edef'; } /* '' */
.icon-Accept2:before { content: '\edf0'; } /* '' */
.icon-externalreport2:before { content: '\edf1'; } /* '' */
.icon-Matrix2-1:before { content: '\edf2'; } /* '' */
.icon-reportcategory2-1:before { content: '\edf3'; } /* '' */
.icon-glyph-238:before { content: '\edf4'; } /* '' */
.icon-glyph-239:before { content: '\edf5'; } /* '' */
.icon-glyph-240:before { content: '\edf6'; } /* '' */
.icon-glyph-241:before { content: '\edf7'; } /* '' */
.icon-glyph-242:before { content: '\edf8'; } /* '' */
.icon-glyph-243:before { content: '\edf9'; } /* '' */
.icon-glyph-244:before { content: '\edfa'; } /* '' */
.icon-glyph-245:before { content: '\edfb'; } /* '' */
.icon-glyph-246:before { content: '\edfc'; } /* '' */
.icon-glyph-247:before { content: '\edfd'; } /* '' */
.icon-VersionIcon-1:before { content: '\edfe'; } /* '' */
.icon-glyph-248:before { content: '\edff'; } /* '' */
.icon-reportschedule3:before { content: '\ee00'; } /* '' */
.icon-Tabular2-1:before { content: '\ee01'; } /* '' */
.icon-Template2-1:before { content: '\ee02'; } /* '' */
.icon-glyph-249:before { content: '\ee03'; } /* '' */
.icon-glyph-250:before { content: '\ee04'; } /* '' */
.icon-glyph-251:before { content: '\ee05'; } /* '' */
.icon-glyph-252:before { content: '\ee06'; } /* '' */
.icon-BillPay_Icon-1:before { content: '\ee07'; } /* '' */
.icon-ChannelBehaviour_Icon-1:before { content: '\ee08'; } /* '' */
.icon-Coach_Icon:before { content: '\ee09'; } /* '' */
.icon-Deposit_Icon-1:before { content: '\ee0a'; } /* '' */
.icon-glyph-253:before { content: '\ee0b'; } /* '' */
.icon-glyph-254:before { content: '\ee0c'; } /* '' */
.icon-glyph-255:before { content: '\ee0d'; } /* '' */
.icon-glyph-256:before { content: '\ee0e'; } /* '' */
.icon-Mobile_Icon-1:before { content: '\ee0f'; } /* '' */
.icon-Teller_Icon-1:before { content: '\ee10'; } /* '' */
.icon-Back-1:before { content: '\ee11'; } /* '' */
.icon-Chat:before { content: '\ee12'; } /* '' */
.icon-Dialer-1:before { content: '\ee13'; } /* '' */
.icon-EndCall-1:before { content: '\ee14'; } /* '' */
.icon-VideoChat1:before { content: '\ee15'; } /* '' */
.icon-VideoChat2:before { content: '\ee16'; } /* '' */
.icon-administrative-settings-1:before { content: '\ee17'; } /* '' */
.icon-card-temp-1:before { content: '\ee18'; } /* '' */
.icon-customization22-1:before { content: '\ee19'; } /* '' */
.icon-changeowner-1:before { content: '\ee1a'; } /* '' */
.icon-import2-1:before { content: '\ee1b'; } /* '' */
.icon-personal-settings-1:before { content: '\ee1c'; } /* '' */
.icon-Qualify:before { content: '\ee1d'; } /* '' */
.icon-synchronization-1:before { content: '\ee1e'; } /* '' */
.icon-add-section1-1:before { content: '\ee1f'; } /* '' */
.icon-add-tab1-1:before { content: '\ee20'; } /* '' */
.icon-copy-to-other-1:before { content: '\ee21'; } /* '' */
.icon-Ecosystem-1:before { content: '\ee22'; } /* '' */
.icon-Remove-Customization-1:before { content: '\ee23'; } /* '' */
.icon-Reset-Icon-1:before { content: '\ee24'; } /* '' */
.icon-Trinity-Cross-Sell-Analyser-1:before { content: '\ee25'; } /* '' */
.icon-Download-NewIcon:before { content: '\ee26'; } /* '' */
.icon-field-info-1:before { content: '\ee27'; } /* '' */
.icon-follower-icon-1:before { content: '\ee28'; } /* '' */
.icon-followers-1:before { content: '\ee29'; } /* '' */
.icon-validate2-1:before { content: '\ee2a'; } /* '' */
.icon-Table_CheckSome1-1:before { content: '\ee2b'; } /* '' */
.icon-Deight1-1:before { content: '\ee2c'; } /* '' */
.icon-Deight2-1:before { content: '\ee2d'; } /* '' */
.icon-recordupdateDesn-1:before { content: '\ee2e'; } /* '' */
.icon-obj1-1:before { content: '\ee2f'; } /* '' */
.icon-obj275-1:before { content: '\ee30'; } /* '' */
.icon-dms-tile1:before { content: '\ee31'; } /* '' */
.icon-dms-tileview-1:before { content: '\ee32'; } /* '' */
.icon-Wait_Object1-1:before { content: '\ee33'; } /* '' */
.icon-Wait_Object2-1:before { content: '\ee34'; } /* '' */
.icon-Calendar:before { content: '\ee35'; } /* '' */
.icon-Delete:before { content: '\ee36'; } /* '' */
.icon-Folder:before { content: '\ee37'; } /* '' */
.icon-GPS-1:before { content: '\ee38'; } /* '' */
.icon-multiaxis-1:before { content: '\ee39'; } /* '' */
.icon-pyramidgraph-1:before { content: '\ee3a'; } /* '' */
.icon-singleaxis-1:before { content: '\ee3b'; } /* '' */
.icon-twoaxis-1:before { content: '\ee3c'; } /* '' */
.icon-Approve-Request:before { content: '\ee3d'; } /* '' */
.icon-Bulk-Generation:before { content: '\ee3e'; } /* '' */
.icon-Import:before { content: '\ee3f'; } /* '' */
.icon-Integration:before { content: '\ee40'; } /* '' */
.icon-disqualify-1:before { content: '\ee41'; } /* '' */
.icon-flows:before { content: '\ee42'; } /* '' */
.icon-Pool-Transfer-1:before { content: '\ee43'; } /* '' */
.icon-Print:before { content: '\ee44'; } /* '' */
.icon-Reports-1:before { content: '\ee45'; } /* '' */
.icon-delightmanagement-1:before { content: '\ee46'; } /* '' */
.icon-obj426-1:before { content: '\ee47'; } /* '' */
.icon-delight-1:before { content: '\ee48'; } /* '' */
.icon-Dropdown_Filled:before { content: '\ee49'; } /* '' */
.icon-Dropdown_Hollow-1:before { content: '\ee4a'; } /* '' */
.icon-Paragraph_Filled-1:before { content: '\ee4b'; } /* '' */
.icon-Paragraph_Hollow-1:before { content: '\ee4c'; } /* '' */
.icon-favourite-filled-1:before { content: '\ee4d'; } /* '' */
.icon-backward-1:before { content: '\ee4e'; } /* '' */
.icon-forward2-1:before { content: '\ee4f'; } /* '' */
.icon-Actionable-1:before { content: '\ee50'; } /* '' */
.icon-Anniversary-Birthday-1:before { content: '\ee51'; } /* '' */
.icon-Conditional-1:before { content: '\ee52'; } /* '' */
.icon-Externals-1:before { content: '\ee53'; } /* '' */
.icon-Reminder-1:before { content: '\ee54'; } /* '' */
.icon-System-1:before { content: '\ee55'; } /* '' */
.icon-Like_Empty-1:before { content: '\ee56'; } /* '' */
.icon-Like_Filled-1:before { content: '\ee57'; } /* '' */
.icon-mass-delete:before { content: '\ee58'; } /* '' */
.icon-mass-email:before { content: '\ee59'; } /* '' */
.icon-mass-sms:before { content: '\ee5a'; } /* '' */
.icon-patternmanagement-1:before { content: '\ee5b'; } /* '' */
.icon-planmanagement-1:before { content: '\ee5c'; } /* '' */
.icon-Mass-Update:before { content: '\ee5d'; } /* '' */
.icon-Merge:before { content: '\ee5e'; } /* '' */
.icon-work_flows:before { content: '\ee5f'; } /* '' */
.icon-obj432-1:before { content: '\ee60'; } /* '' */
.icon-obj433-1:before { content: '\ee61'; } /* '' */
.icon-statuscode-order-1:before { content: '\ee62'; } /* '' */
.icon-SwitchProcess:before { content: '\ee63'; } /* '' */
.icon-Import2-1:before { content: '\ee64'; } /* '' */
.icon-action-message-1:before { content: '\ee65'; } /* '' */
.icon-notification-message-1:before { content: '\ee66'; } /* '' */
.icon-ManageCustomColumn-1:before { content: '\ee67'; } /* '' */
.icon-OneDrive-1:before { content: '\ee68'; } /* '' */
.icon-ActionButton-1:before { content: '\ee69'; } /* '' */
.icon-AmazonDrive2:before { content: '\ee6a'; } /* '' */
.icon-glyph-264:before { content: '\ee6b'; } /* '' */
.icon-glyph-265:before { content: '\ee6c'; } /* '' */
.icon-glyph-266:before { content: '\ee6d'; } /* '' */
.icon-glyph-267:before { content: '\ee6e'; } /* '' */
.icon-CrossSellAnalyzer2-1:before { content: '\ee6f'; } /* '' */
.icon-datapresenter2-1:before { content: '\ee70'; } /* '' */
.icon-DropBox2:before { content: '\ee71'; } /* '' */
.icon-glyph-268:before { content: '\ee72'; } /* '' */
.icon-glyph-269:before { content: '\ee73'; } /* '' */
.icon-FB-1:before { content: '\ee74'; } /* '' */
.icon-glyph-270:before { content: '\ee75'; } /* '' */
.icon-glyph-271:before { content: '\ee76'; } /* '' */
.icon-GoogleDrive2:before { content: '\ee77'; } /* '' */
.icon-GooglePlus-1:before { content: '\ee78'; } /* '' */
.icon-gpsstart2:before { content: '\ee79'; } /* '' */
.icon-glyph-272:before { content: '\ee7a'; } /* '' */
.icon-glyph-273:before { content: '\ee7b'; } /* '' */
.icon-glyph-274:before { content: '\ee7c'; } /* '' */
.icon-Key-1:before { content: '\ee7d'; } /* '' */
.icon-glyph-275:before { content: '\ee7e'; } /* '' */
.icon-glyph-276:before { content: '\ee7f'; } /* '' */
.icon-linkedin4:before { content: '\ee80'; } /* '' */
.icon-glyph-277:before { content: '\ee81'; } /* '' */
.icon-glyph-278:before { content: '\ee82'; } /* '' */
.icon-glyph-279:before { content: '\ee83'; } /* '' */
.icon-glyph-280:before { content: '\ee84'; } /* '' */
.icon-glyph-281:before { content: '\ee85'; } /* '' */
.icon-glyph-282:before { content: '\ee86'; } /* '' */
.icon-glyph-283:before { content: '\ee87'; } /* '' */
.icon-glyph-284:before { content: '\ee88'; } /* '' */
.icon-glyph-285:before { content: '\ee89'; } /* '' */
.icon-glyph-286:before { content: '\ee8a'; } /* '' */
.icon-glyph-287:before { content: '\ee8b'; } /* '' */
.icon-glyph-288:before { content: '\ee8c'; } /* '' */
.icon-glyph-289:before { content: '\ee8d'; } /* '' */
.icon-glyph-290:before { content: '\ee8e'; } /* '' */
.icon-glyph-291:before { content: '\ee8f'; } /* '' */
.icon-glyph-292:before { content: '\ee90'; } /* '' */
.icon-glyph-293:before { content: '\ee91'; } /* '' */
.icon-glyph-294:before { content: '\ee92'; } /* '' */
.icon-MiningModel2-1:before { content: '\ee93'; } /* '' */
.icon-offers3:before { content: '\ee94'; } /* '' */
.icon-OneDrive2-1:before { content: '\ee95'; } /* '' */
.icon-OneSection-1:before { content: '\ee96'; } /* '' */
.icon-glyph-295:before { content: '\ee97'; } /* '' */
.icon-glyph-296:before { content: '\ee98'; } /* '' */
.icon-ReferenceModel2-1:before { content: '\ee99'; } /* '' */
.icon-runcallscript2:before { content: '\ee9a'; } /* '' */
.icon-SelectAttributes2-1:before { content: '\ee9b'; } /* '' */
.icon-sendoffers2:before { content: '\ee9c'; } /* '' */
.icon-glyph-297:before { content: '\ee9d'; } /* '' */
.icon-glyph-298:before { content: '\ee9e'; } /* '' */
.icon-glyph-299:before { content: '\ee9f'; } /* '' */
.icon-glyph-300:before { content: '\eea0'; } /* '' */
.icon-glyph-301:before { content: '\eea1'; } /* '' */
.icon-glyph-302:before { content: '\eea2'; } /* '' */
.icon-glyph-303:before { content: '\eea3'; } /* '' */
.icon-glyph-304:before { content: '\eea4'; } /* '' */
.icon-glyph-305:before { content: '\eea5'; } /* '' */
.icon-glyph-306:before { content: '\eea6'; } /* '' */
.icon-glyph-307:before { content: '\eea7'; } /* '' */
.icon-glyph-308:before { content: '\eea8'; } /* '' */
.icon-glyph-309:before { content: '\eea9'; } /* '' */
.icon-StepProcess-1:before { content: '\eeaa'; } /* '' */
.icon-Tab-OneSection-1:before { content: '\eeab'; } /* '' */
.icon-Tab-ThreeSection-1:before { content: '\eeac'; } /* '' */
.icon-Tab-TwoSection-1:before { content: '\eead'; } /* '' */
.icon-ThreeSection-1:before { content: '\eeae'; } /* '' */
.icon-twitter3:before { content: '\eeaf'; } /* '' */
.icon-TwoSection-1:before { content: '\eeb0'; } /* '' */
.icon-User:before { content: '\eeb1'; } /* '' */
.icon-validatewidget2-1:before { content: '\eeb2'; } /* '' */
.icon-widgetsearch2:before { content: '\eeb3'; } /* '' */
.icon-mobile5-1:before { content: '\eeb4'; } /* '' */
.icon-monitor-1:before { content: '\eeb5'; } /* '' */
.icon-tablet2-1:before { content: '\eeb6'; } /* '' */
.icon-DragButton-1:before { content: '\eeb7'; } /* '' */
.icon-Blank-1:before { content: '\eeb8'; } /* '' */
.icon-card-3:before { content: '\eeb9'; } /* '' */
.icon-card-4:before { content: '\eeba'; } /* '' */
.icon-gpstracking4-1:before { content: '\eebb'; } /* '' */
.icon-XSLT:before { content: '\eebc'; } /* '' */
.icon-organized-1:before { content: '\eebd'; } /* '' */
.icon-thinkingmodel-1:before { content: '\eebe'; } /* '' */
.icon-Cross-1:before { content: '\eebf'; } /* '' */
.icon-Details:before { content: '\eec0'; } /* '' */
.icon-History:before { content: '\eec1'; } /* '' */
.icon-NewEdit:before { content: '\eec2'; } /* '' */
.icon-Search:before { content: '\eec3'; } /* '' */
.icon-Summary-1:before { content: '\eec4'; } /* '' */
.icon-Tick:before { content: '\eec5'; } /* '' */
.icon-alert-1:before { content: '\eec6'; } /* '' */
.icon-asset-1:before { content: '\eec7'; } /* '' */
.icon-event-1:before { content: '\eec8'; } /* '' */
.icon-issue-1:before { content: '\eec9'; } /* '' */
.icon-logout-1:before { content: '\eeca'; } /* '' */
.icon-pulsecontrol:before { content: '\eecb'; } /* '' */
.icon-reportexecute-1:before { content: '\eecc'; } /* '' */
.icon-glyph-310:before { content: '\eecd'; } /* '' */
.icon-glyph-311:before { content: '\eece'; } /* '' */
.icon-Design-1:before { content: '\eecf'; } /* '' */
.icon-DMS_Icon1-1:before { content: '\eed0'; } /* '' */
.icon-DMS_Icon2-1:before { content: '\eed1'; } /* '' */
.icon-glyph-312:before { content: '\eed2'; } /* '' */
.icon-glyph-313:before { content: '\eed3'; } /* '' */
.icon-glyph-314:before { content: '\eed4'; } /* '' */
.icon-glyph-315:before { content: '\eed5'; } /* '' */
.icon-glyph-316:before { content: '\eed6'; } /* '' */
.icon-glyph-317:before { content: '\eed7'; } /* '' */
.icon-glyph-318:before { content: '\eed8'; } /* '' */
.icon-glyph-319:before { content: '\eed9'; } /* '' */
.icon-glyph-320:before { content: '\eeda'; } /* '' */
.icon-glyph-321:before { content: '\eedb'; } /* '' */
.icon-glyph-322:before { content: '\eedc'; } /* '' */
.icon-glyph-323:before { content: '\eedd'; } /* '' */
.icon-pproduct-1:before { content: '\eede'; } /* '' */
.icon-product3-1:before { content: '\eedf'; } /* '' */
.icon-interactions-1:before { content: '\eee0'; } /* '' */
.icon-lifecycle-1:before { content: '\eee1'; } /* '' */
.icon-glyph-324:before { content: '\eee2'; } /* '' */
.icon-glyph-325:before { content: '\eee3'; } /* '' */
.icon-glyph-326:before { content: '\eee4'; } /* '' */
.icon-glyph-327:before { content: '\eee5'; } /* '' */
.icon-glyph-328:before { content: '\eee6'; } /* '' */
.icon-glyph-329:before { content: '\eee7'; } /* '' */
.icon-glyph-330:before { content: '\eee8'; } /* '' */
.icon-glyph-331:before { content: '\eee9'; } /* '' */
.icon-glyph-332:before { content: '\eeea'; } /* '' */
.icon-glyph-333:before { content: '\eeeb'; } /* '' */
.icon-glyph-334:before { content: '\eeec'; } /* '' */
.icon-glyph-335:before { content: '\eeed'; } /* '' */
.icon-glyph-336:before { content: '\eeee'; } /* '' */
.icon-glyph-337:before { content: '\eeef'; } /* '' */
.icon-glyph-338:before { content: '\eef0'; } /* '' */
.icon-wallet_Share-1:before { content: '\eef1'; } /* '' */
.icon-calendar5:before { content: '\eef2'; } /* '' */
.icon-play2:before { content: '\eef3'; } /* '' */
.icon-stop-1:before { content: '\eef4'; } /* '' */
.icon-accesspermission2-1:before { content: '\eef5'; } /* '' */
.icon-accesspermission-1:before { content: '\eef6'; } /* '' */
.icon-adddocument-1:before { content: '\eef7'; } /* '' */
.icon-glyph-339:before { content: '\eef8'; } /* '' */
.icon-glyph-340:before { content: '\eef9'; } /* '' */
.icon-glyph-341:before { content: '\eefa'; } /* '' */
.icon-glyph-342:before { content: '\eefb'; } /* '' */
.icon-DocumentLibrary-1:before { content: '\eefc'; } /* '' */
.icon-Listing-1:before { content: '\eefd'; } /* '' */
.icon-Transfer:before { content: '\eefe'; } /* '' */
.icon-send-agenda:before { content: '\eeff'; } /* '' */
.icon-send-mom-1:before { content: '\ef00'; } /* '' */
.icon-check-out-1:before { content: '\ef01'; } /* '' */
.icon-check-in-1:before { content: '\ef02'; } /* '' */
.icon-absent-1:before { content: '\ef03'; } /* '' */
.icon-default-user-icon-1:before { content: '\ef04'; } /* '' */
.icon-present-1:before { content: '\ef05'; } /* '' */
.icon-category-1:before { content: '\ef06'; } /* '' */
.icon-apis-1:before { content: '\ef07'; } /* '' */
.icon-chooseexisting-1:before { content: '\ef08'; } /* '' */
.icon-database2-1:before { content: '\ef09'; } /* '' */
.icon-dataconnection-1:before { content: '\ef0a'; } /* '' */
.icon-datatransformation-1:before { content: '\ef0b'; } /* '' */
.icon-external-widget-1:before { content: '\ef0c'; } /* '' */
.icon-form-1:before { content: '\ef0d'; } /* '' */
.icon-graph3-1:before { content: '\ef0e'; } /* '' */
.icon-KnowMoreArrow-1:before { content: '\ef0f'; } /* '' */
.icon-lisitng2-1:before { content: '\ef10'; } /* '' */
.icon-msmq-1:before { content: '\ef11'; } /* '' */
.icon-PlusIcon-1:before { content: '\ef12'; } /* '' */
.icon-search4:before { content: '\ef13'; } /* '' */
.icon-validate-1:before { content: '\ef14'; } /* '' */
.icon-widget2-1:before { content: '\ef15'; } /* '' */
.icon-invite-1:before { content: '\ef16'; } /* '' */
.icon-Cogentmeeting-1:before { content: '\ef17'; } /* '' */
.icon-InternalConnections-1:before { content: '\ef18'; } /* '' */
.icon-invite2:before { content: '\ef19'; } /* '' */
.icon-Matrix-1:before { content: '\ef1a'; } /* '' */
.icon-plus2:before { content: '\ef1b'; } /* '' */
.icon-Tabular-1:before { content: '\ef1c'; } /* '' */
.icon-glyph-343:before { content: '\ef1d'; } /* '' */
.icon-glyph-344:before { content: '\ef1e'; } /* '' */
.icon-glyph-345:before { content: '\ef1f'; } /* '' */
.icon-glyph-346:before { content: '\ef20'; } /* '' */
.icon-glyph-347:before { content: '\ef21'; } /* '' */
.icon-glyph-348:before { content: '\ef22'; } /* '' */
.icon-glyph-349:before { content: '\ef23'; } /* '' */
.icon-glyph-350:before { content: '\ef24'; } /* '' */
.icon-glyph-351:before { content: '\ef25'; } /* '' */
.icon-glyph-352:before { content: '\ef26'; } /* '' */
.icon-glyph-353:before { content: '\ef27'; } /* '' */
.icon-glyph-354:before { content: '\ef28'; } /* '' */
.icon-glyph-355:before { content: '\ef29'; } /* '' */
.icon-glyph-356:before { content: '\ef2a'; } /* '' */
.icon-glyph-357:before { content: '\ef2b'; } /* '' */
.icon-glyph-358:before { content: '\ef2c'; } /* '' */
.icon-glyph-359:before { content: '\ef2d'; } /* '' */
.icon-glyph-360:before { content: '\ef2e'; } /* '' */
.icon-widget-Form-1:before { content: '\ef2f'; } /* '' */
.icon-widget-Listing-1:before { content: '\ef30'; } /* '' */
.icon-DataMining-1:before { content: '\ef31'; } /* '' */
.icon-Importcopy-1:before { content: '\ef32'; } /* '' */
.icon-Milestone-1:before { content: '\ef33'; } /* '' */
.icon-parallelactivity-1:before { content: '\ef34'; } /* '' */
.icon-Publish-1:before { content: '\ef35'; } /* '' */
.icon-Recommendations-1:before { content: '\ef36'; } /* '' */
.icon-SelectJob-1:before { content: '\ef37'; } /* '' */
.icon-state-1:before { content: '\ef38'; } /* '' */
.icon-Surveyclose:before { content: '\ef39'; } /* '' */
.icon-Surveydraft:before { content: '\ef3a'; } /* '' */
.icon-surveyopen:before { content: '\ef3b'; } /* '' */
.icon-Whitespace-1:before { content: '\ef3c'; } /* '' */
.icon-XOR-1:before { content: '\ef3d'; } /* '' */
.icon-MiningModel-1:before { content: '\ef3e'; } /* '' */
.icon-ReferenceModel-1:before { content: '\ef3f'; } /* '' */
.icon-SelectAttributes-1:before { content: '\ef40'; } /* '' */
.icon-integration2-1:before { content: '\ef41'; } /* '' */
.icon-DisplayFields:before { content: '\ef42'; } /* '' */
.icon-CrossSellAnalyzer:before { content: '\ef43'; } /* '' */
.icon-offers2:before { content: '\ef44'; } /* '' */
.icon-runcallscript-1:before { content: '\ef45'; } /* '' */
.icon-sendoffers-1:before { content: '\ef46'; } /* '' */
.icon-glyph-361:before { content: '\ef47'; } /* '' */
.icon-glyph-362:before { content: '\ef48'; } /* '' */
.icon-glyph-363:before { content: '\ef49'; } /* '' */
.icon-glyph-364:before { content: '\ef4a'; } /* '' */
.icon-gpsstart-1:before { content: '\ef4b'; } /* '' */
.icon-glyph-365:before { content: '\ef4c'; } /* '' */
.icon-glyph-366:before { content: '\ef4d'; } /* '' */
.icon-glyph-367:before { content: '\ef4e'; } /* '' */
.icon-glyph-368:before { content: '\ef4f'; } /* '' */
.icon-glyph-369:before { content: '\ef50'; } /* '' */
.icon-glyph-370:before { content: '\ef51'; } /* '' */
.icon-glyph-371:before { content: '\ef52'; } /* '' */
.icon-glyph-372:before { content: '\ef53'; } /* '' */
.icon-glyph-373:before { content: '\ef54'; } /* '' */
.icon-glyph-374:before { content: '\ef55'; } /* '' */
.icon-glyph-375:before { content: '\ef56'; } /* '' */
.icon-glyph-376:before { content: '\ef57'; } /* '' */
.icon-glyph-377:before { content: '\ef58'; } /* '' */
.icon-glyph-378:before { content: '\ef59'; } /* '' */
.icon-glyph-379:before { content: '\ef5a'; } /* '' */
.icon-glyph-380:before { content: '\ef5b'; } /* '' */
.icon-glyph-381:before { content: '\ef5c'; } /* '' */
.icon-glyph-382:before { content: '\ef5d'; } /* '' */
.icon-glyph-383:before { content: '\ef5e'; } /* '' */
.icon-glyph-384:before { content: '\ef5f'; } /* '' */
.icon-glyph-385:before { content: '\ef60'; } /* '' */
.icon-glyph-386:before { content: '\ef61'; } /* '' */
.icon-glyph-387:before { content: '\ef62'; } /* '' */
.icon-glyph-388:before { content: '\ef63'; } /* '' */
.icon-glyph-389:before { content: '\ef64'; } /* '' */
.icon-glyph-390:before { content: '\ef65'; } /* '' */
.icon-glyph-391:before { content: '\ef66'; } /* '' */
.icon-glyph-392:before { content: '\ef67'; } /* '' */
.icon-glyph-393:before { content: '\ef68'; } /* '' */
.icon-glyph-394:before { content: '\ef69'; } /* '' */
.icon-glyph-395:before { content: '\ef6a'; } /* '' */
.icon-glyph-396:before { content: '\ef6b'; } /* '' */
.icon-glyph-397:before { content: '\ef6c'; } /* '' */
.icon-glyph-398:before { content: '\ef6d'; } /* '' */
.icon-datapresenter-1:before { content: '\ef6e'; } /* '' */
.icon-glyph-399:before { content: '\ef6f'; } /* '' */
.icon-glyph-400:before { content: '\ef70'; } /* '' */
.icon-glyph-401:before { content: '\ef71'; } /* '' */
.icon-glyph-402:before { content: '\ef72'; } /* '' */
.icon-glyph-403:before { content: '\ef73'; } /* '' */
.icon-paragraph-justify-1:before { content: '\ef74'; } /* '' */
.icon-indent-increase-1:before { content: '\ef75'; } /* '' */
.icon-indent-decrease-1:before { content: '\ef76'; } /* '' */
.icon-share-1:before { content: '\ef77'; } /* '' */
.icon-new-tab-1:before { content: '\ef78'; } /* '' */
.icon-embed-1:before { content: '\ef79'; } /* '' */
.icon-embed2:before { content: '\ef7a'; } /* '' */
.icon-terminal-1:before { content: '\ef7b'; } /* '' */
.icon-share2:before { content: '\ef7c'; } /* '' */
.icon-mail:before { content: '\ef7d'; } /* '' */
.icon-mail2:before { content: '\ef7e'; } /* '' */
.icon-mail3:before { content: '\ef7f'; } /* '' */
.icon-mail4:before { content: '\ef80'; } /* '' */
.icon-youtube-1:before { content: '\ef81'; } /* '' */
.icon-youtube2:before { content: '\ef82'; } /* '' */
.icon-file-pdf:before { content: '\ef83'; } /* '' */
.icon-file-openoffice-1:before { content: '\ef84'; } /* '' */
.icon-file-word:before { content: '\ef85'; } /* '' */
.icon-file-excel:before { content: '\ef86'; } /* '' */
.icon-libreoffice-1:before { content: '\ef87'; } /* '' */
.icon-AdvanceAssignmentsRule_op2-1:before { content: '\ef88'; } /* '' */
.icon-AdvanceAssignmentsRule-1:before { content: '\ef89'; } /* '' */
.icon-AdvanceAssignmentsRule-01-01:before { content: '\ef8a'; } /* '' */
.icon-AssignmentsRule_op2-1:before { content: '\ef8b'; } /* '' */
.icon-AssignmentsRule-1:before { content: '\ef8c'; } /* '' */
.icon-AutoResponse_op2-1:before { content: '\ef8d'; } /* '' */
.icon-AutoResponse-1:before { content: '\ef8e'; } /* '' */
.icon-ConditionalAccess_op2-1:before { content: '\ef8f'; } /* '' */
.icon-ConditionalAccess-1:before { content: '\ef90'; } /* '' */
.icon-ConditionalAlerts_op2-1:before { content: '\ef91'; } /* '' */
.icon-ConditionalAlerts-1:before { content: '\ef92'; } /* '' */
.icon-De-dupeRule_op2-1:before { content: '\ef93'; } /* '' */
.icon-De-dupeRule-1:before { content: '\ef94'; } /* '' */
.icon-executeelement_op2-01:before { content: '\ef95'; } /* '' */
.icon-externaldataservice_op2-1:before { content: '\ef96'; } /* '' */
.icon-fetch_op2-01:before { content: '\ef97'; } /* '' */
.icon-fittodiagram_op2-1:before { content: '\ef98'; } /* '' */
.icon-fullscreencollapsemode_op2-1:before { content: '\ef99'; } /* '' */
.icon-htmltoimage_op2-01:before { content: '\ef9a'; } /* '' */
.icon-htmltopdf_op2-1:before { content: '\ef9b'; } /* '' */
.icon-insertuserproduct_op2-1:before { content: '\ef9c'; } /* '' */
.icon-insertuserteam_op2-01:before { content: '\ef9d'; } /* '' */
.icon-ManageAlertsRule_op2-1:before { content: '\ef9e'; } /* '' */
.icon-ManageAlertsRule-1:before { content: '\ef9f'; } /* '' */
.icon-pdftotiff_op2-1:before { content: '\efa0'; } /* '' */
.icon-recorddeletion_op2-1:before { content: '\efa1'; } /* '' */
.icon-runflow_op2-1:before { content: '\efa2'; } /* '' */
.icon-screenflowicon_op2-1:before { content: '\efa3'; } /* '' */
.icon-validate_op2-1:before { content: '\efa4'; } /* '' */
.icon-widget_op2-1:before { content: '\efa5'; } /* '' */
.icon-glyph-404:before { content: '\efa6'; } /* '' */
.icon-glyph-405:before { content: '\efa7'; } /* '' */
.icon-glyph-406:before { content: '\efa8'; } /* '' */
.icon-glyph-407:before { content: '\efa9'; } /* '' */
.icon-slider-1:before { content: '\efaa'; } /* '' */
.icon-slider_bar-1:before { content: '\efab'; } /* '' */
.icon-zoom_in-1:before { content: '\efac'; } /* '' */
.icon-zoom_out-1:before { content: '\efad'; } /* '' */
.icon-Word:before { content: '\efae'; } /* '' */
.icon-collapse_semi_circle_right:before { content: '\efaf'; } /* '' */
.icon-Cricket2-1:before { content: '\efb0'; } /* '' */
.icon-Badminton-1:before { content: '\efb1'; } /* '' */
.icon-Cricket-1:before { content: '\efb2'; } /* '' */
.icon-Football-1:before { content: '\efb3'; } /* '' */
.icon-Chess-1:before { content: '\efb4'; } /* '' */
.icon-Smoking-1:before { content: '\efb5'; } /* '' */
.icon-Car-1:before { content: '\efb6'; } /* '' */
.icon-Shop-1:before { content: '\efb7'; } /* '' */
.icon-PowerPoint:before { content: '\efb8'; } /* '' */
.icon-PDF-1:before { content: '\efb9'; } /* '' */
.icon-glyph-408:before { content: '\efba'; } /* '' */
.icon-glyph-409:before { content: '\efbb'; } /* '' */
.icon-Excel:before { content: '\efbc'; } /* '' */
.icon-glyph-410:before { content: '\efbd'; } /* '' */
.icon-glyph-411:before { content: '\efbe'; } /* '' */
.icon-Road-1:before { content: '\efbf'; } /* '' */
.icon-restaurent-1:before { content: '\efc0'; } /* '' */
.icon-House2-1:before { content: '\efc1'; } /* '' */
.icon-obj269-1:before { content: '\efc2'; } /* '' */
.icon-show-1:before { content: '\efc3'; } /* '' */
.icon-initiate-1:before { content: '\efc4'; } /* '' */
.icon-job-1:before { content: '\efc5'; } /* '' */
.icon-allocation-1:before { content: '\efc6'; } /* '' */
.icon-obj248-1:before { content: '\efc7'; } /* '' */
.icon-House1:before { content: '\efc8'; } /* '' */
.icon-excel22:before { content: '\efc9'; } /* '' */
.icon-excel3:before { content: '\efca'; } /* '' */
.icon-gif2:before { content: '\efcb'; } /* '' */
.icon-gif-1:before { content: '\efcc'; } /* '' */
.icon-html2:before { content: '\efcd'; } /* '' */
.icon-html:before { content: '\efce'; } /* '' */
.icon-jpg2:before { content: '\efcf'; } /* '' */
.icon-jpg-1:before { content: '\efd0'; } /* '' */
.icon-media2:before { content: '\efd1'; } /* '' */
.icon-media-1:before { content: '\efd2'; } /* '' */
.icon-PDF2-1:before { content: '\efd3'; } /* '' */
.icon-PDF3:before { content: '\efd4'; } /* '' */
.icon-png2:before { content: '\efd5'; } /* '' */
.icon-png-1:before { content: '\efd6'; } /* '' */
.icon-powerpoint2:before { content: '\efd7'; } /* '' */
.icon-powerpoint3:before { content: '\efd8'; } /* '' */
.icon-tiff2:before { content: '\efd9'; } /* '' */
.icon-tiff-1:before { content: '\efda'; } /* '' */
.icon-word22:before { content: '\efdb'; } /* '' */
.icon-word3-1:before { content: '\efdc'; } /* '' */
.icon-ICON_Businessenforcementrule-1:before { content: '\efdd'; } /* '' */
.icon-ICON_Escalationrule-1:before { content: '\efde'; } /* '' */
.icon-glyph-412:before { content: '\efdf'; } /* '' */
.icon-glyph-413:before { content: '\efe0'; } /* '' */
.icon-glyph-414:before { content: '\efe1'; } /* '' */
.icon-glyph-415:before { content: '\efe2'; } /* '' */
.icon-glyph-416:before { content: '\efe3'; } /* '' */
.icon-glyph-417:before { content: '\efe4'; } /* '' */
.icon-glyph-418:before { content: '\efe5'; } /* '' */
.icon-glyph-419:before { content: '\efe6'; } /* '' */
.icon-glyph-420:before { content: '\efe7'; } /* '' */
.icon-ICON_SLA-1:before { content: '\efe8'; } /* '' */
.icon-glyph-421:before { content: '\efe9'; } /* '' */
.icon-glyph-422:before { content: '\efea'; } /* '' */
.icon-glyph-423:before { content: '\efeb'; } /* '' */
.icon-glyph-424:before { content: '\efec'; } /* '' */
.icon-glyph-425:before { content: '\efed'; } /* '' */
.icon-glyph-426:before { content: '\efee'; } /* '' */
.icon-glyph-427:before { content: '\efef'; } /* '' */
.icon-autointelligence-1:before { content: '\eff0'; } /* '' */
.icon-customerdelight-1:before { content: '\eff1'; } /* '' */
.icon-Interaction-1:before { content: '\eff2'; } /* '' */
.icon-glyph-428:before { content: '\eff3'; } /* '' */
.icon-glyph-429:before { content: '\eff4'; } /* '' */
.icon-glyph-430:before { content: '\eff5'; } /* '' */
.icon-glyph-431:before { content: '\eff6'; } /* '' */
.icon-productholding-1:before { content: '\eff7'; } /* '' */
.icon-Car2-1:before { content: '\eff8'; } /* '' */
.icon-Plane-1:before { content: '\eff9'; } /* '' */
.icon-CustomerDelight-1:before { content: '\effa'; } /* '' */
.icon-glyph-432:before { content: '\effb'; } /* '' */
.icon-glyph-433:before { content: '\effc'; } /* '' */
.icon-glyph-434:before { content: '\effd'; } /* '' */
.icon-glyph-435:before { content: '\effe'; } /* '' */
.icon-glyph-436:before { content: '\efff'; } /* '' */
.icon-key2-1:before { content: '\f000'; } /* '' */
.icon-lock-1:before { content: '\f001'; } /* '' */
.icon-unlocked-1:before { content: '\f002'; } /* '' */
.icon-wrench-1:before { content: '\f003'; } /* '' */
.icon-equalizer-1:before { content: '\f004'; } /* '' */
.icon-equalizer2-1:before { content: '\f005'; } /* '' */
.icon-cog-1:before { content: '\f006'; } /* '' */
.icon-cogs-1:before { content: '\f007'; } /* '' */
.icon-hammer-1:before { content: '\f008'; } /* '' */
.icon-aid-kit-1:before { content: '\f009'; } /* '' */
.icon-pie-chart-1:before { content: '\f00a'; } /* '' */
.icon-stats-dots-1:before { content: '\f00b'; } /* '' */
.icon-stats-bars-1:before { content: '\f00c'; } /* '' */
.icon-stats-bars2-1:before { content: '\f00d'; } /* '' */
.icon-meter-1:before { content: '\f00e'; } /* '' */
.icon-meter2-1:before { content: '\f00f'; } /* '' */
.icon-hammer2-1:before { content: '\f010'; } /* '' */
.icon-magnet-1:before { content: '\f011'; } /* '' */
.icon-bin:before { content: '\f012'; } /* '' */
.icon-bin2:before { content: '\f013'; } /* '' */
.icon-briefcase-1:before { content: '\f014'; } /* '' */
.icon-target-1:before { content: '\f015'; } /* '' */
.icon-shield-1:before { content: '\f016'; } /* '' */
.icon-switch-1:before { content: '\f017'; } /* '' */
.icon-clipboard-1:before { content: '\f018'; } /* '' */
.icon-list-numbered-1:before { content: '\f019'; } /* '' */
.icon-list-1:before { content: '\f01a'; } /* '' */
.icon-list2-1:before { content: '\f01b'; } /* '' */
.icon-tree-1:before { content: '\f01c'; } /* '' */
.icon-menu-2:before { content: '\f01d'; } /* '' */
.icon-menu2-1:before { content: '\f01e'; } /* '' */
.icon-menu3-1:before { content: '\f01f'; } /* '' */
.icon-menu4-1:before { content: '\f020'; } /* '' */
.icon-cloud-1:before { content: '\f021'; } /* '' */
.icon-cloud-download-1:before { content: '\f022'; } /* '' */
.icon-cloud-upload-1:before { content: '\f023'; } /* '' */
.icon-cloud-check-1:before { content: '\f024'; } /* '' */
.icon-download2-1:before { content: '\f025'; } /* '' */
.icon-upload2-1:before { content: '\f026'; } /* '' */
.icon-download3-1:before { content: '\f027'; } /* '' */
.icon-upload3-1:before { content: '\f028'; } /* '' */
.icon-sphere-1:before { content: '\f029'; } /* '' */
.icon-earth:before { content: '\f02a'; } /* '' */
.icon-link-1:before { content: '\f02b'; } /* '' */
.icon-flag-1:before { content: '\f02c'; } /* '' */
.icon-attachment:before { content: '\f02d'; } /* '' */
.icon-eye-1:before { content: '\f02e'; } /* '' */
.icon-eye-plus:before { content: '\f02f'; } /* '' */
.icon-eye-minus:before { content: '\f030'; } /* '' */
.icon-eye-blocked-1:before { content: '\f031'; } /* '' */
.icon-bookmark-1:before { content: '\f032'; } /* '' */
.icon-bookmarks-1:before { content: '\f033'; } /* '' */
.icon-sun-1:before { content: '\f034'; } /* '' */
.icon-contrast-1:before { content: '\f035'; } /* '' */
.icon-brightness-contrast-1:before { content: '\f036'; } /* '' */
.icon-star-empty-1:before { content: '\f037'; } /* '' */
.icon-star-half-1:before { content: '\f038'; } /* '' */
.icon-star-full-1:before { content: '\f039'; } /* '' */
.icon-warning-1:before { content: '\f03a'; } /* '' */
.icon-notification-1:before { content: '\f03b'; } /* '' */
.icon-question-1:before { content: '\f03c'; } /* '' */
.icon-plus-1:before { content: '\f03d'; } /* '' */
.icon-minus-1:before { content: '\f03e'; } /* '' */
.icon-info-1:before { content: '\f03f'; } /* '' */
.icon-cancel-circle:before { content: '\f040'; } /* '' */
.icon-blocked-1:before { content: '\f041'; } /* '' */
.icon-cross:before { content: '\f042'; } /* '' */
.icon-checkmark:before { content: '\f043'; } /* '' */
.icon-checkmark2:before { content: '\f044'; } /* '' */
.icon-spell-check-1:before { content: '\f045'; } /* '' */
.icon-enter-1:before { content: '\f046'; } /* '' */
.icon-exit:before { content: '\f047'; } /* '' */
.icon-volume-high-1:before { content: '\f048'; } /* '' */
.icon-volume-medium-1:before { content: '\f049'; } /* '' */
.icon-volume-low-1:before { content: '\f04a'; } /* '' */
.icon-volume-mute-1:before { content: '\f04b'; } /* '' */
.icon-volume-mute2:before { content: '\f04c'; } /* '' */
.icon-volume-increase:before { content: '\f04d'; } /* '' */
.icon-volume-decrease:before { content: '\f04e'; } /* '' */
.icon-loop-1:before { content: '\f04f'; } /* '' */
.icon-loop2:before { content: '\f050'; } /* '' */
.icon-infinite-1:before { content: '\f051'; } /* '' */
.icon-shuffle:before { content: '\f052'; } /* '' */
.icon-arrow-up-left-1:before { content: '\f053'; } /* '' */
.icon-arrow-up-1:before { content: '\f054'; } /* '' */
.icon-arrow-up-right-1:before { content: '\f055'; } /* '' */
.icon-arrow-right-1:before { content: '\f056'; } /* '' */
.icon-arrow-down-right-1:before { content: '\f057'; } /* '' */
.icon-arrow-down-1:before { content: '\f058'; } /* '' */
.icon-arrow-down-left-1:before { content: '\f059'; } /* '' */
.icon-arrow-left-1:before { content: '\f05a'; } /* '' */
.icon-arrow-up-left2-1:before { content: '\f05b'; } /* '' */
.icon-arrow-up2-1:before { content: '\f05c'; } /* '' */
.icon-arrow-up-right2-1:before { content: '\f05d'; } /* '' */
.icon-arrow-right2-1:before { content: '\f05e'; } /* '' */
.icon-arrow-down-right2-1:before { content: '\f05f'; } /* '' */
.icon-arrow-down2-1:before { content: '\f060'; } /* '' */
.icon-arrow-down-left2-1:before { content: '\f061'; } /* '' */
.icon-arrow-left2-1:before { content: '\f062'; } /* '' */
.icon-circle-up:before { content: '\f063'; } /* '' */
.icon-circle-right:before { content: '\f064'; } /* '' */
.icon-circle-down:before { content: '\f065'; } /* '' */
.icon-circle-left:before { content: '\f066'; } /* '' */
.icon-tab-1:before { content: '\f067'; } /* '' */
.icon-sort-amount-asc-1:before { content: '\f068'; } /* '' */
.icon-sort-amount-desc-1:before { content: '\f069'; } /* '' */
.icon-shift-1:before { content: '\f06a'; } /* '' */
.icon-ctrl-1:before { content: '\f06b'; } /* '' */
.icon-checkbox-checked-1:before { content: '\f06c'; } /* '' */
.icon-checkbox-unchecked-1:before { content: '\f06d'; } /* '' */
.icon-radio-checked-1:before { content: '\f06e'; } /* '' */
.icon-radio-checked2-1:before { content: '\f06f'; } /* '' */
.icon-radio-unchecked-1:before { content: '\f070'; } /* '' */
.icon-filter-1:before { content: '\f071'; } /* '' */
.icon-table-1:before { content: '\f072'; } /* '' */
.icon-table2-1:before { content: '\f073'; } /* '' */
.icon-insert-template-1:before { content: '\f074'; } /* '' */
.icon-paragraph-left-1:before { content: '\f075'; } /* '' */
.icon-paragraph-center-1:before { content: '\f076'; } /* '' */
.icon-paragraph-right-1:before { content: '\f077'; } /* '' */
.icon-mailblaster-1:before { content: '\f078'; } /* '' */
.icon-method-1:before { content: '\f079'; } /* '' */
.icon-objectsharing-1:before { content: '\f07a'; } /* '' */
.icon-processcibilrequest-1:before { content: '\f07b'; } /* '' */
.icon-Purgeobject-1:before { content: '\f07c'; } /* '' */
.icon-readmessagequeue-1:before { content: '\f07d'; } /* '' */
.icon-rebuildhierarchy-1:before { content: '\f07e'; } /* '' */
.icon-reportschedule-1:before { content: '\f07f'; } /* '' */
.icon-sendreportscheduler-1:before { content: '\f080'; } /* '' */
.icon-synchronizeDB-1:before { content: '\f081'; } /* '' */
.icon-writemessagequeue-1:before { content: '\f082'; } /* '' */
.icon-administration_setup-1:before { content: '\f083'; } /* '' */
.icon-customization2-1:before { content: '\f084'; } /* '' */
.icon-data_management-1:before { content: '\f085'; } /* '' */
.icon-integration-1:before { content: '\f086'; } /* '' */
.icon-personal_setting:before { content: '\f087'; } /* '' */
.icon-scissors-1:before { content: '\f088'; } /* '' */
.icon-newspaper-1:before { content: '\f089'; } /* '' */
.icon-pencil:before { content: '\f08a'; } /* '' */
.icon-pencil2:before { content: '\f08b'; } /* '' */
.icon-droplet-1:before { content: '\f08c'; } /* '' */
.icon-image-1:before { content: '\f08d'; } /* '' */
.icon-images:before { content: '\f08e'; } /* '' */
.icon-connection-1:before { content: '\f08f'; } /* '' */
.icon-podcast-1:before { content: '\f090'; } /* '' */
.icon-feed-1:before { content: '\f091'; } /* '' */
.icon-mic-1:before { content: '\f092'; } /* '' */
.icon-book-1:before { content: '\f093'; } /* '' */
.icon-books-1:before { content: '\f094'; } /* '' */
.icon-library-1:before { content: '\f095'; } /* '' */
.icon-file-text-1:before { content: '\f096'; } /* '' */
.icon-profile-1:before { content: '\f097'; } /* '' */
.icon-file-empty-1:before { content: '\f098'; } /* '' */
.icon-files-empty-1:before { content: '\f099'; } /* '' */
.icon-file-text2:before { content: '\f09a'; } /* '' */
.icon-file-picture-1:before { content: '\f09b'; } /* '' */
.icon-file-music-1:before { content: '\f09c'; } /* '' */
.icon-file-play-1:before { content: '\f09d'; } /* '' */
.icon-file-video-1:before { content: '\f09e'; } /* '' */
.icon-file-zip-1:before { content: '\f09f'; } /* '' */
.icon-copy-1:before { content: '\f0a0'; } /* '' */
.icon-paste-1:before { content: '\f0a1'; } /* '' */
.icon-stack-1:before { content: '\f0a2'; } /* '' */
.icon-folder-1:before { content: '\f0a3'; } /* '' */
.icon-folder-open-1:before { content: '\f0a4'; } /* '' */
.icon-folder-plus-1:before { content: '\f0a5'; } /* '' */
.icon-folder-minus-1:before { content: '\f0a6'; } /* '' */
.icon-folder-download-1:before { content: '\f0a7'; } /* '' */
.icon-folder-upload-1:before { content: '\f0a8'; } /* '' */
.icon-price-tag-1:before { content: '\f0a9'; } /* '' */
.icon-price-tags-1:before { content: '\f0aa'; } /* '' */
.icon-barcode-1:before { content: '\f0ab'; } /* '' */
.icon-credit-card-1:before { content: '\f0ac'; } /* '' */
.icon-phone-1:before { content: '\f0ad'; } /* '' */
.icon-phone-hang-up-1:before { content: '\f0ae'; } /* '' */
.icon-address-book:before { content: '\f0af'; } /* '' */
.icon-envelop:before { content: '\f0b0'; } /* '' */
.icon-pushpin-1:before { content: '\f0b1'; } /* '' */
.icon-location-1:before { content: '\f0b2'; } /* '' */
.icon-location2-1:before { content: '\f0b3'; } /* '' */
.icon-compass-1:before { content: '\f0b4'; } /* '' */
.icon-compass2-1:before { content: '\f0b5'; } /* '' */
.icon-map-1:before { content: '\f0b6'; } /* '' */
.icon-map2-1:before { content: '\f0b7'; } /* '' */
.icon-history-1:before { content: '\f0b8'; } /* '' */
.icon-clock-1:before { content: '\f0b9'; } /* '' */
.icon-clock2:before { content: '\f0ba'; } /* '' */
.icon-alarm-1:before { content: '\f0bb'; } /* '' */
.icon-bell:before { content: '\f0bc'; } /* '' */
.icon-stopwatch-1:before { content: '\f0bd'; } /* '' */
.icon-calendar-1:before { content: '\f0be'; } /* '' */
.icon-printer:before { content: '\f0bf'; } /* '' */
.icon-keyboard-1:before { content: '\f0c0'; } /* '' */
.icon-display-1:before { content: '\f0c1'; } /* '' */
.icon-laptop-1:before { content: '\f0c2'; } /* '' */
.icon-mobile-1:before { content: '\f0c3'; } /* '' */
.icon-mobile2:before { content: '\f0c4'; } /* '' */
.icon-tablet-1:before { content: '\f0c5'; } /* '' */
.icon-tv-1:before { content: '\f0c6'; } /* '' */
.icon-drawer-1:before { content: '\f0c7'; } /* '' */
.icon-drawer2:before { content: '\f0c8'; } /* '' */
.icon-box-add-1:before { content: '\f0c9'; } /* '' */
.icon-box-remove-1:before { content: '\f0ca'; } /* '' */
.icon-download-1:before { content: '\f0cb'; } /* '' */
.icon-upload-1:before { content: '\f0cc'; } /* '' */
.icon-floppy-disk-1:before { content: '\f0cd'; } /* '' */
.icon-drive-1:before { content: '\f0ce'; } /* '' */
.icon-database-1:before { content: '\f0cf'; } /* '' */
.icon-undo:before { content: '\f0d0'; } /* '' */
.icon-redo:before { content: '\f0d1'; } /* '' */
.icon-undo2-1:before { content: '\f0d2'; } /* '' */
.icon-redo2-1:before { content: '\f0d3'; } /* '' */
.icon-forward-1:before { content: '\f0d4'; } /* '' */
.icon-reply-1:before { content: '\f0d5'; } /* '' */
.icon-bubble-1:before { content: '\f0d6'; } /* '' */
.icon-bubbles-1:before { content: '\f0d7'; } /* '' */
.icon-bubbles2-1:before { content: '\f0d8'; } /* '' */
.icon-bubble2-1:before { content: '\f0d9'; } /* '' */
.icon-bubbles3-1:before { content: '\f0da'; } /* '' */
.icon-bubbles4-1:before { content: '\f0db'; } /* '' */
.icon-user-1:before { content: '\f0dc'; } /* '' */
.icon-users-1:before { content: '\f0dd'; } /* '' */
.icon-user-plus-1:before { content: '\f0de'; } /* '' */
.icon-user-minus-1:before { content: '\f0df'; } /* '' */
.icon-user-check-1:before { content: '\f0e0'; } /* '' */
.icon-user-tie-1:before { content: '\f0e1'; } /* '' */
.icon-quotes-left-1:before { content: '\f0e2'; } /* '' */
.icon-quotes-right-1:before { content: '\f0e3'; } /* '' */
.icon-hour-glass-1:before { content: '\f0e4'; } /* '' */
.icon-spinner5-1:before { content: '\f0e5'; } /* '' */
.icon-spinner11:before { content: '\f0e6'; } /* '' */
.icon-binoculars-1:before { content: '\f0e7'; } /* '' */
.icon-search-1:before { content: '\f0e8'; } /* '' */
.icon-zoom-in-1:before { content: '\f0e9'; } /* '' */
.icon-zoom-out-1:before { content: '\f0ea'; } /* '' */
.icon-enlarge-1:before { content: '\f0eb'; } /* '' */
.icon-shrink-1:before { content: '\f0ec'; } /* '' */
.icon-enlarge2:before { content: '\f0ed'; } /* '' */
.icon-shrink2:before { content: '\f0ee'; } /* '' */
.icon-key-1:before { content: '\f0ef'; } /* '' */
.icon-mass-unshare-1:before { content: '\f0f0'; } /* '' */
.icon-mass-update:before { content: '\f0f1'; } /* '' */
.icon-members:before { content: '\f0f2'; } /* '' */
.icon-merge-1:before { content: '\f0f3'; } /* '' */
.icon-more-links-1:before { content: '\f0f4'; } /* '' */
.icon-my-file-1:before { content: '\f0f5'; } /* '' */
.icon-my-links:before { content: '\f0f6'; } /* '' */
.icon-my-notifications-1:before { content: '\f0f7'; } /* '' */
.icon-my-reports-1:before { content: '\f0f8'; } /* '' */
.icon-new-report:before { content: '\f0f9'; } /* '' */
.icon-linkedin3:before { content: '\f0fa'; } /* '' */
.icon-listing-1:before { content: '\f0fb'; } /* '' */
.icon-list-view-1:before { content: '\f0fc'; } /* '' */
.icon-location3-1:before { content: '\f0fd'; } /* '' */
.icon-log-1:before { content: '\f0fe'; } /* '' */
.icon-logout-icon-1:before { content: '\f0ff'; } /* '' */
.icon-mailing-list2:before { content: '\f100'; } /* '' */
.icon-mailing-list22:before { content: '\f101'; } /* '' */
.icon-manage-category-1:before { content: '\f102'; } /* '' */
.icon-manage-report:before { content: '\f103'; } /* '' */
.icon-marketing-1:before { content: '\f104'; } /* '' */
.icon-history2:before { content: '\f105'; } /* '' */
.icon-home-1:before { content: '\f106'; } /* '' */
.icon-inventory-1:before { content: '\f107'; } /* '' */
.icon-Issue-1:before { content: '\f108'; } /* '' */
.icon-key-info-1:before { content: '\f109'; } /* '' */
.icon-key-information-1:before { content: '\f10a'; } /* '' */
.icon-keypad-1:before { content: '\f10b'; } /* '' */
.icon-lead-pipeline-1:before { content: '\f10c'; } /* '' */
.icon-less-than-1:before { content: '\f10d'; } /* '' */
.icon-like-1:before { content: '\f10e'; } /* '' */
.icon-link2:before { content: '\f10f'; } /* '' */
.icon-file-1:before { content: '\f110'; } /* '' */
.icon-follow-up-info-1:before { content: '\f111'; } /* '' */
.icon-forecast-1:before { content: '\f112'; } /* '' */
.icon-goal-1:before { content: '\f113'; } /* '' */
.icon-goal-chart-1:before { content: '\f114'; } /* '' */
.icon-googleplus:before { content: '\f115'; } /* '' */
.icon-greaterthan-1:before { content: '\f116'; } /* '' */
.icon-greater-than:before { content: '\f117'; } /* '' */
.icon-grid-view-1:before { content: '\f118'; } /* '' */
.icon-group-1:before { content: '\f119'; } /* '' */
.icon-header-search-bar-icon:before { content: '\f11a'; } /* '' */
.icon-header-user-icon:before { content: '\f11b'; } /* '' */
.icon-help-1:before { content: '\f11c'; } /* '' */
.icon-doublearrowdown-1:before { content: '\f11d'; } /* '' */
.icon-doublearrowleft-1:before { content: '\f11e'; } /* '' */
.icon-doublearrowright-1:before { content: '\f11f'; } /* '' */
.icon-doublearrowtop-1:before { content: '\f120'; } /* '' */
.icon-drop-chart-compare-1:before { content: '\f121'; } /* '' */
.icon-edit-1:before { content: '\f122'; } /* '' */
.icon-equal-1:before { content: '\f123'; } /* '' */
.icon-equals:before { content: '\f124'; } /* '' */
.icon-expense-1:before { content: '\f125'; } /* '' */
.icon-facebook4-1:before { content: '\f126'; } /* '' */
.icon-favourite-1:before { content: '\f127'; } /* '' */
.icon-favourite-list-1:before { content: '\f128'; } /* '' */
.icon-feedback-1:before { content: '\f129'; } /* '' */
.icon-content-library-1:before { content: '\f12a'; } /* '' */
.icon-contract:before { content: '\f12b'; } /* '' */
.icon-courier-1:before { content: '\f12c'; } /* '' */
.icon-currency-convertor-1:before { content: '\f12d'; } /* '' */
.icon-custom-dashboard-1:before { content: '\f12e'; } /* '' */
.icon-custom-link:before { content: '\f12f'; } /* '' */
.icon-dashboard-1:before { content: '\f130'; } /* '' */
.icon-date-view:before { content: '\f131'; } /* '' */
.icon-delete-1:before { content: '\f132'; } /* '' */
.icon-details:before { content: '\f133'; } /* '' */
.icon-dispatch-1:before { content: '\f134'; } /* '' */
.icon-document-1:before { content: '\f135'; } /* '' */
.icon-call-script-1:before { content: '\f136'; } /* '' */
.icon-campaign-1:before { content: '\f137'; } /* '' */
.icon-cancel-1:before { content: '\f138'; } /* '' */
.icon-case-1:before { content: '\f139'; } /* '' */
.icon-charge-1:before { content: '\f13a'; } /* '' */
.icon-chart-1:before { content: '\f13b'; } /* '' */
.icon-clone-1:before { content: '\f13c'; } /* '' */
.icon-comments-1:before { content: '\f13d'; } /* '' */
.icon-competition-1:before { content: '\f13e'; } /* '' */
.icon-contact:before { content: '\f13f'; } /* '' */
.icon-contact-info-1:before { content: '\f140'; } /* '' */
.icon-content-1:before { content: '\f141'; } /* '' */
.icon-arrow-left3-1:before { content: '\f142'; } /* '' */
.icon-arrow-right3-1:before { content: '\f143'; } /* '' */
.icon-arrow-top-1:before { content: '\f144'; } /* '' */
.icon-assets-1:before { content: '\f145'; } /* '' */
.icon-glyph-437:before { content: '\f146'; } /* '' */
.icon-glyph-438:before { content: '\f147'; } /* '' */
.icon-glyph-439:before { content: '\f148'; } /* '' */
.icon-glyph-440:before { content: '\f149'; } /* '' */
.icon-glyph-441:before { content: '\f14a'; } /* '' */
.icon-glyph-442:before { content: '\f14b'; } /* '' */
.icon-glyph-443:before { content: '\f14c'; } /* '' */
.icon-glyph-444:before { content: '\f14d'; } /* '' */
.icon-glyph-445:before { content: '\f14e'; } /* '' */
.icon-glyph-446:before { content: '\f14f'; } /* '' */
.icon-glyph-447:before { content: '\f150'; } /* '' */
.icon-glyph-448:before { content: '\f151'; } /* '' */
.icon-glyph-449:before { content: '\f152'; } /* '' */
.icon-glyph-450:before { content: '\f153'; } /* '' */
.icon-badge-1:before { content: '\f154'; } /* '' */
.icon-big-deal-alert-1:before { content: '\f155'; } /* '' */
.icon-Biometric-1:before { content: '\f156'; } /* '' */
.icon-budget-1:before { content: '\f157'; } /* '' */
.icon-build-1:before { content: '\f158'; } /* '' */
.icon-bulletempty-1:before { content: '\f159'; } /* '' */
.icon-bulletfilled-1:before { content: '\f15a'; } /* '' */
.icon-calendar2:before { content: '\f15b'; } /* '' */
.icon-atozsorter-1:before { content: '\f15c'; } /* '' */
.icon-account-1:before { content: '\f15d'; } /* '' */
.icon-achievement-1:before { content: '\f15e'; } /* '' */
.icon-additional-info-1:before { content: '\f15f'; } /* '' */
.icon-add-to-Fav-1:before { content: '\f160'; } /* '' */
.icon-add-to-quick-link-1:before { content: '\f161'; } /* '' */
.icon-userprofile:before { content: '\f162'; } /* '' */
.icon-externalreport:before { content: '\f163'; } /* '' */
.icon-reportcategory:before { content: '\f164'; } /* '' */
.icon-alerts:before { content: '\f165'; } /* '' */
.icon-reports-1:before { content: '\f166'; } /* '' */
.icon-appointment:before { content: '\f167'; } /* '' */
.icon-appointments-1:before { content: '\f168'; } /* '' */
.icon-approval-1:before { content: '\f169'; } /* '' */
.icon-arrow-bottom-1:before { content: '\f16a'; } /* '' */
.icon-archivedata-1:before { content: '\f16b'; } /* '' */
.icon-bars-1:before { content: '\f16c'; } /* '' */
.icon-cibilenquiry-1:before { content: '\f16d'; } /* '' */
.icon-clearcaching:before { content: '\f16e'; } /* '' */
.icon-command-1:before { content: '\f16f'; } /* '' */
.icon-delay-1:before { content: '\f170'; } /* '' */
.icon-deleteuserproduct-1:before { content: '\f171'; } /* '' */
.icon-Deleteuserteams-1:before { content: '\f172'; } /* '' */
.icon-deleteuserterritorry-1:before { content: '\f173'; } /* '' */
.icon-disableindexes-1:before { content: '\f174'; } /* '' */
.icon-enableindexes:before { content: '\f175'; } /* '' */
.icon-externaldataservices-1:before { content: '\f176'; } /* '' */
.icon-ForecastRebuild-1:before { content: '\f177'; } /* '' */
.icon-importCFData-1:before { content: '\f178'; } /* '' */
.icon-importobject-1:before { content: '\f179'; } /* '' */
.icon-insertuserterritorry-1:before { content: '\f17a'; } /* '' */
.icon-jobs-1:before { content: '\f17b'; } /* '' */
.icon-gauge-1:before { content: '\f17c'; } /* '' */
.icon-line-1:before { content: '\f17d'; } /* '' */
.icon-pie-1:before { content: '\f17e'; } /* '' */
.icon-tutorials-1:before { content: '\f17f'; } /* '' */
.icon-twitter4-1:before { content: '\f180'; } /* '' */
.icon-unlike-1:before { content: '\f181'; } /* '' */
.icon-username:before { content: '\f182'; } /* '' */
.icon-view:before { content: '\f183'; } /* '' */
.icon-warehouse:before { content: '\f184'; } /* '' */
.icon-wealth-1:before { content: '\f185'; } /* '' */
.icon-worknext-1:before { content: '\f186'; } /* '' */
.icon-workspace-1:before { content: '\f187'; } /* '' */
.icon-status-1:before { content: '\f188'; } /* '' */
.icon-stock-audit-1:before { content: '\f189'; } /* '' */
.icon-summary-1:before { content: '\f18a'; } /* '' */
.icon-survey:before { content: '\f18b'; } /* '' */
.icon-switcher-active-1:before { content: '\f18c'; } /* '' */
.icon-switcher-non-active-1:before { content: '\f18d'; } /* '' */
.icon-target2-1:before { content: '\f18e'; } /* '' */
.icon-task-1:before { content: '\f18f'; } /* '' */
.icon-sms:before { content: '\f190'; } /* '' */
.icon-task-n:before { content: '\f191'; } /* '' */
.icon-task-new-1:before { content: '\f192'; } /* '' */
.icon-collapse_semi_circle_left:before { content: '\f193'; } /* '' */
.icon-template-1:before { content: '\f194'; } /* '' */
.icon-timesheet-1:before { content: '\f195'; } /* '' */
.icon-toolbox-1:before { content: '\f196'; } /* '' */
.icon-top-collaspe:before { content: '\f197'; } /* '' */
.icon-training-1:before { content: '\f198'; } /* '' */
.icon-review-1:before { content: '\f199'; } /* '' */
.icon-sales-1:before { content: '\f19a'; } /* '' */
.icon-save-1:before { content: '\f19b'; } /* '' */
.icon-save-and-new:before { content: '\f19c'; } /* '' */
.icon-scheduling-1:before { content: '\f19d'; } /* '' */
.icon-scheme-1:before { content: '\f19e'; } /* '' */
.icon-search2:before { content: '\f19f'; } /* '' */
.icon-service-1:before { content: '\f1a0'; } /* '' */
.icon-set-filter-1:before { content: '\f1a1'; } /* '' */
.icon-share3-1:before { content: '\f1a2'; } /* '' */
.icon-smart-filter-1:before { content: '\f1a3'; } /* '' */
.icon-social-crm-1:before { content: '\f1a4'; } /* '' */
.icon-solution-1:before { content: '\f1a5'; } /* '' */
.icon-product-1:before { content: '\f1a6'; } /* '' */
.icon-product-holding-1:before { content: '\f1a7'; } /* '' */
.icon-profile2:before { content: '\f1a8'; } /* '' */
.icon-project-1:before { content: '\f1a9'; } /* '' */
.icon-pulse-1:before { content: '\f1aa'; } /* '' */
.icon-quota-1:before { content: '\f1ab'; } /* '' */
.icon-quote-1:before { content: '\f1ac'; } /* '' */
.icon-recent-activities-1:before { content: '\f1ad'; } /* '' */
.icon-re-order-point-1:before { content: '\f1ae'; } /* '' */
.icon-report-1:before { content: '\f1af'; } /* '' */
.icon-report-category:before { content: '\f1b0'; } /* '' */
.icon-report-designer:before { content: '\f1b1'; } /* '' */
.icon-requirement-1:before { content: '\f1b2'; } /* '' */
.icon-password-1:before { content: '\f1b3'; } /* '' */
.icon-payment-1:before { content: '\f1b4'; } /* '' */
.icon-planning-1:before { content: '\f1b5'; } /* '' */
.icon-glyph-451:before { content: '\f1b6'; } /* '' */
.icon-glyph-452:before { content: '\f1b7'; } /* '' */
.icon-poll-1:before { content: '\f1b8'; } /* '' */
.icon-poll-pulse:before { content: '\f1b9'; } /* '' */
.icon-pool-1:before { content: '\f1ba'; } /* '' */
.icon-portfolio-1:before { content: '\f1bb'; } /* '' */
.icon-post-1:before { content: '\f1bc'; } /* '' */
.icon-post-button-1:before { content: '\f1bd'; } /* '' */
.icon-pricebook-1:before { content: '\f1be'; } /* '' */
.icon-print-1:before { content: '\f1bf'; } /* '' */
.icon-procurement-1:before { content: '\f1c0'; } /* '' */
.icon-obj349-1:before { content: '\f1c1'; } /* '' */
.icon-obj350-1:before { content: '\f1c2'; } /* '' */
.icon-obj351-1:before { content: '\f1c3'; } /* '' */
.icon-obj359-1:before { content: '\f1c4'; } /* '' */
.icon-obj361-1:before { content: '\f1c5'; } /* '' */
.icon-obj362-1:before { content: '\f1c6'; } /* '' */
.icon-obj363-1:before { content: '\f1c7'; } /* '' */
.icon-obj367-1:before { content: '\f1c8'; } /* '' */
.icon-obj390-1:before { content: '\f1c9'; } /* '' */
.icon-obj397-1:before { content: '\f1ca'; } /* '' */
.icon-offers-1:before { content: '\f1cb'; } /* '' */
.icon-opportunity-1:before { content: '\f1cc'; } /* '' */
.icon-order-1:before { content: '\f1cd'; } /* '' */
.icon-obj278-1:before { content: '\f1ce'; } /* '' */
.icon-obj288-1:before { content: '\f1cf'; } /* '' */
.icon-obj290-1:before { content: '\f1d0'; } /* '' */
.icon-obj291-1:before { content: '\f1d1'; } /* '' */
.icon-obj292-1:before { content: '\f1d2'; } /* '' */
.icon-obj293-1:before { content: '\f1d3'; } /* '' */
.icon-obj299-1:before { content: '\f1d4'; } /* '' */
.icon-obj307-1:before { content: '\f1d5'; } /* '' */
.icon-glyph-453:before { content: '\f1d6'; } /* '' */
.icon-glyph-454:before { content: '\f1d7'; } /* '' */
.icon-glyph-455:before { content: '\f1d8'; } /* '' */
.icon-glyph-456:before { content: '\f1d9'; } /* '' */
.icon-glyph-457:before { content: '\f1da'; } /* '' */
.icon-glyph-458:before { content: '\f1db'; } /* '' */
.icon-glyph-459:before { content: '\f1dc'; } /* '' */
.icon-glyph-460:before { content: '\f1dd'; } /* '' */
.icon-glyph-461:before { content: '\f1de'; } /* '' */
.icon-glyph-462:before { content: '\f1df'; } /* '' */
.icon-glyph-463:before { content: '\f1e0'; } /* '' */
.icon-glyph-464:before { content: '\f1e1'; } /* '' */
.icon-glyph-465:before { content: '\f1e2'; } /* '' */
.icon-glyph-466:before { content: '\f1e3'; } /* '' */
.icon-obj322-1:before { content: '\f1e4'; } /* '' */
.icon-obj324-1:before { content: '\f1e5'; } /* '' */
.icon-obj326-1:before { content: '\f1e6'; } /* '' */
.icon-obj334-1:before { content: '\f1e7'; } /* '' */
.icon-obj196-1:before { content: '\f1e8'; } /* '' */
.icon-obj199-1:before { content: '\f1e9'; } /* '' */
.icon-obj200-1:before { content: '\f1ea'; } /* '' */
.icon-obj203-1:before { content: '\f1eb'; } /* '' */
.icon-obj205-1:before { content: '\f1ec'; } /* '' */
.icon-obj206-1:before { content: '\f1ed'; } /* '' */
.icon-obj207-1:before { content: '\f1ee'; } /* '' */
.icon-obj227-1:before { content: '\f1ef'; } /* '' */
.icon-obj242-1:before { content: '\f1f0'; } /* '' */
.icon-obj258-1:before { content: '\f1f1'; } /* '' */
.icon-obj259-1:before { content: '\f1f2'; } /* '' */
.icon-obj276-1:before { content: '\f1f3'; } /* '' */
.icon-obj277-1:before { content: '\f1f4'; } /* '' */
.icon-obj56-1:before { content: '\f1f5'; } /* '' */
.icon-obj58-1:before { content: '\f1f6'; } /* '' */
.icon-obj67-1:before { content: '\f1f7'; } /* '' */
.icon-obj74-1:before { content: '\f1f8'; } /* '' */
.icon-obj84-1:before { content: '\f1f9'; } /* '' */
.icon-obj90-1:before { content: '\f1fa'; } /* '' */
.icon-obj96-1:before { content: '\f1fb'; } /* '' */
.icon-obj97-1:before { content: '\f1fc'; } /* '' */
.icon-obj108-1:before { content: '\f1fd'; } /* '' */
.icon-obj162-1:before { content: '\f1fe'; } /* '' */
.icon-obj175-1:before { content: '\f1ff'; } /* '' */
.icon-obj186-1:before { content: '\f200'; } /* '' */
.icon-obj188-1:before { content: '\f201'; } /* '' */
.icon-obj9-1:before { content: '\f202'; } /* '' */
.icon-obj10-1:before { content: '\f203'; } /* '' */
.icon-obj11-1:before { content: '\f204'; } /* '' */
.icon-obj12-1:before { content: '\f205'; } /* '' */
.icon-glyph-467:before { content: '\f206'; } /* '' */
.icon-glyph-468:before { content: '\f207'; } /* '' */
.icon-glyph-469:before { content: '\f208'; } /* '' */
.icon-obj19-1:before { content: '\f209'; } /* '' */
.icon-obj22-1:before { content: '\f20a'; } /* '' */
.icon-obj28-1:before { content: '\f20b'; } /* '' */
.icon-obj34-1:before { content: '\f20c'; } /* '' */
.icon-obj36-1:before { content: '\f20d'; } /* '' */
.icon-obj38-1:before { content: '\f20e'; } /* '' */
.icon-obj41-1:before { content: '\f20f'; } /* '' */
.icon-obj52-1:before { content: '\f210'; } /* '' */
.icon-note-1:before { content: '\f211'; } /* '' */
.icon-not-equal-1:before { content: '\f212'; } /* '' */
.icon-notify-message-1:before { content: '\f213'; } /* '' */
.icon-notify-phone:before { content: '\f214'; } /* '' */
.icon-obj2-1:before { content: '\f215'; } /* '' */
.icon-obj3-1:before { content: '\f216'; } /* '' */
.icon-obj4-1:before { content: '\f217'; } /* '' */
.icon-obj5-1:before { content: '\f218'; } /* '' */
.icon-obj7-1:before { content: '\f219'; } /* '' */
.icon-mass-print-1:before { content: '\f21a'; } /* '' */
.icon-mass-share:before { content: '\f21b'; } /* '' */
.icon-glyph-470:before { content: '\f21c'; } /* '' */
.icon-glyph-471:before { content: '\f21d'; } /* '' */
.icon-glyph-472:before { content: '\f21e'; } /* '' */
.icon-glyph-473:before { content: '\f21f'; } /* '' */
.icon-glyph-474:before { content: '\f220'; } /* '' */
.icon-glyph-475:before { content: '\f221'; } /* '' */
.icon-glyph-476:before { content: '\f222'; } /* '' */
.icon-glyph-477:before { content: '\f223'; } /* '' */
.icon-glyph-478:before { content: '\f224'; } /* '' */
.icon-glyph-479:before { content: '\f225'; } /* '' */
.icon-redo4-1:before { content: '\f226'; } /* '' */
.icon-rulereferance-1:before { content: '\f227'; } /* '' */
.icon-save3:before { content: '\f228'; } /* '' */
.icon-score-1:before { content: '\f229'; } /* '' */
.icon-screen-1:before { content: '\f22a'; } /* '' */
.icon-settoback-1:before { content: '\f22b'; } /* '' */
.icon-settings-1:before { content: '\f22c'; } /* '' */
.icon-sleep-1:before { content: '\f22d'; } /* '' */
.icon-sleep2-1:before { content: '\f22e'; } /* '' */
.icon-smartalign-1:before { content: '\f22f'; } /* '' */
.icon-snapping-1:before { content: '\f230'; } /* '' */
.icon-startevent-1:before { content: '\f231'; } /* '' */
.icon-switch2-1:before { content: '\f232'; } /* '' */
.icon-undo4-1:before { content: '\f233'; } /* '' */
.icon-verticalalignment-1:before { content: '\f234'; } /* '' */
.icon-webservice:before { content: '\f235'; } /* '' */
.icon-xml-1:before { content: '\f236'; } /* '' */
.icon-addfile-1:before { content: '\f237'; } /* '' */
.icon-approved-1:before { content: '\f238'; } /* '' */
.icon-arrowup-1:before { content: '\f239'; } /* '' */
.icon-arrows:before { content: '\f23a'; } /* '' */
.icon-attachment2:before { content: '\f23b'; } /* '' */
.icon-bin3-1:before { content: '\f23c'; } /* '' */
.icon-calc-1:before { content: '\f23d'; } /* '' */
.icon-calendar4:before { content: '\f23e'; } /* '' */
.icon-cancel3:before { content: '\f23f'; } /* '' */
.icon-copy2-1:before { content: '\f240'; } /* '' */
.icon-document5-1:before { content: '\f241'; } /* '' */
.icon-edit4:before { content: '\f242'; } /* '' */
.icon-excel2-1:before { content: '\f243'; } /* '' */
.icon-file5-1:before { content: '\f244'; } /* '' */
.icon-folder3:before { content: '\f245'; } /* '' */
.icon-folder1-1:before { content: '\f246'; } /* '' */
.icon-graph2-1:before { content: '\f247'; } /* '' */
.icon-key3-1:before { content: '\f248'; } /* '' */
.icon-list4-1:before { content: '\f249'; } /* '' */
.icon-list22-1:before { content: '\f24a'; } /* '' */
.icon-lock3:before { content: '\f24b'; } /* '' */
.icon-mail5:before { content: '\f24c'; } /* '' */
.icon-member6:before { content: '\f24d'; } /* '' */
.icon-note2-1:before { content: '\f24e'; } /* '' */
.icon-pdf2-1:before { content: '\f24f'; } /* '' */
.icon-powerpoint-1:before { content: '\f250'; } /* '' */
.icon-saveandnew-1:before { content: '\f251'; } /* '' */
.icon-save4:before { content: '\f252'; } /* '' */
.icon-search3-1:before { content: '\f253'; } /* '' */
.icon-stamp2-1:before { content: '\f254'; } /* '' */
.icon-star:before { content: '\f255'; } /* '' */
.icon-unvisible-1:before { content: '\f256'; } /* '' */
.icon-upload4-1:before { content: '\f257'; } /* '' */
.icon-visible-1:before { content: '\f258'; } /* '' */
.icon-word2-1:before { content: '\f259'; } /* '' */
.icon-glyph-480:before { content: '\f25a'; } /* '' */
.icon-glyph-481:before { content: '\f25b'; } /* '' */
.icon-glyph-482:before { content: '\f25c'; } /* '' */
.icon-glyph-483:before { content: '\f25d'; } /* '' */
.icon-glyph-484:before { content: '\f25e'; } /* '' */
.icon-line2-1:before { content: '\f25f'; } /* '' */
.icon-obj13-1:before { content: '\f260'; } /* '' */
.icon-attendance:before { content: '\f261'; } /* '' */
.icon-obj316-1:before { content: '\f262'; } /* '' */
.icon-obj401-1:before { content: '\f263'; } /* '' */
.icon-step:before { content: '\f264'; } /* '' */
.icon-stepmanagement:before { content: '\f265'; } /* '' */
.icon-close-1:before { content: '\f266'; } /* '' */
.icon-facebook2-1:before { content: '\f267'; } /* '' */
.icon-googlebase2-1:before { content: '\f268'; } /* '' */
.icon-linkedin2:before { content: '\f269'; } /* '' */
.icon-twitter2:before { content: '\f26a'; } /* '' */
.icon-newtab-1:before { content: '\f26b'; } /* '' */
.icon-obj366-1:before { content: '\f26c'; } /* '' */
.icon-obj395-1:before { content: '\f26d'; } /* '' */
.icon-testcase-1:before { content: '\f26e'; } /* '' */
.icon-member-1:before { content: '\f26f'; } /* '' */
.icon-obj44-1:before { content: '\f270'; } /* '' */
.icon-obj132-1:before { content: '\f271'; } /* '' */
.icon-obj202-1:before { content: '\f272'; } /* '' */
.icon-obj267-1:before { content: '\f273'; } /* '' */
.icon-obj340-1:before { content: '\f274'; } /* '' */
.icon-offer:before { content: '\f275'; } /* '' */
.icon-contentlib-1:before { content: '\f276'; } /* '' */
.icon-doc-1:before { content: '\f277'; } /* '' */
.icon-forecastmanagement-1:before { content: '\f278'; } /* '' */
.icon-newtab_13-1:before { content: '\f279'; } /* '' */
.icon-poolmanagement-1:before { content: '\f27a'; } /* '' */
.icon-reports2:before { content: '\f27b'; } /* '' */
.icon-wealthmanagement-1:before { content: '\f27c'; } /* '' */
.icon-bar-1:before { content: '\f27d'; } /* '' */
.icon-doughnut-1:before { content: '\f27e'; } /* '' */
.icon-funnel-1:before { content: '\f27f'; } /* '' */
.icon-switch-2:before { content: '\f280'; } /* '' */
.icon-task-2:before { content: '\f281'; } /* '' */
.icon-transportfile-1:before { content: '\f282'; } /* '' */
.icon-uncompressfile-1:before { content: '\f283'; } /* '' */
.icon-update-01:before { content: '\f284'; } /* '' */
.icon-uploadfile-1:before { content: '\f285'; } /* '' */
.icon-verifyfile-1:before { content: '\f286'; } /* '' */
.icon-wait-1:before { content: '\f287'; } /* '' */
.icon-xor-1:before { content: '\f288'; } /* '' */
.icon-approval2-1:before { content: '\f289'; } /* '' */
.icon-bargraph-1:before { content: '\f28a'; } /* '' */
.icon-blog-1:before { content: '\f28b'; } /* '' */
.icon-book2-1:before { content: '\f28c'; } /* '' */
.icon-cards-1:before { content: '\f28d'; } /* '' */
.icon-collape-1:before { content: '\f28e'; } /* '' */
.icon-document2-1:before { content: '\f28f'; } /* '' */
.icon-email:before { content: '\f290'; } /* '' */
.icon-expand-1:before { content: '\f291'; } /* '' */
.icon-grid-1:before { content: '\f292'; } /* '' */
.icon-link3:before { content: '\f293'; } /* '' */
.icon-lock2:before { content: '\f294'; } /* '' */
.icon-member2:before { content: '\f295'; } /* '' */
.icon-member1:before { content: '\f296'; } /* '' */
.icon-mobile3-1:before { content: '\f297'; } /* '' */
.icon-pin-1:before { content: '\f298'; } /* '' */
.icon-power-1:before { content: '\f299'; } /* '' */
.icon-print2:before { content: '\f29a'; } /* '' */
.icon-product2-2:before { content: '\f29b'; } /* '' */
.icon-pulse2:before { content: '\f29c'; } /* '' */
.icon-recent-1:before { content: '\f29d'; } /* '' */
.icon-tag-1:before { content: '\f29e'; } /* '' */
.icon-trophy-1:before { content: '\f29f'; } /* '' */
.icon-checkbox:before { content: '\f2a0'; } /* '' */
.icon-clock3-1:before { content: '\f2a1'; } /* '' */
.icon-document3-1:before { content: '\f2a2'; } /* '' */
.icon-emptycheckbox:before { content: '\f2a3'; } /* '' */
.icon-member3:before { content: '\f2a4'; } /* '' */
.icon-not-equal-to-1:before { content: '\f2a5'; } /* '' */
.icon-print3:before { content: '\f2a6'; } /* '' */
.icon-tick-1:before { content: '\f2a7'; } /* '' */
.icon-approval3:before { content: '\f2a8'; } /* '' */
.icon-Edit-1:before { content: '\f2a9'; } /* '' */
.icon-edit_connections-1:before { content: '\f2aa'; } /* '' */
.icon-image2:before { content: '\f2ab'; } /* '' */
.icon-Linegraph-1:before { content: '\f2ac'; } /* '' */
.icon-Pie-1:before { content: '\f2ad'; } /* '' */
.icon-redo3-1:before { content: '\f2ae'; } /* '' */
.icon-smiley-1:before { content: '\f2af'; } /* '' */
.icon-undo3-1:before { content: '\f2b0'; } /* '' */
.icon-align-1:before { content: '\f2b1'; } /* '' */
.icon-cal-1:before { content: '\f2b2'; } /* '' */
.icon-document4-1:before { content: '\f2b3'; } /* '' */
.icon-edit2:before { content: '\f2b4'; } /* '' */
.icon-exclation-1:before { content: '\f2b5'; } /* '' */
.icon-export-1:before { content: '\f2b6'; } /* '' */
.icon-Fav-1:before { content: '\f2b7'; } /* '' */
.icon-icon1-1:before { content: '\f2b8'; } /* '' */
.icon-member4-1:before { content: '\f2b9'; } /* '' */
.icon-message-1:before { content: '\f2ba'; } /* '' */
.icon-play-1:before { content: '\f2bb'; } /* '' */
.icon-rupees-1:before { content: '\f2bc'; } /* '' */
.icon-save2:before { content: '\f2bd'; } /* '' */
.icon-tick2:before { content: '\f2be'; } /* '' */
.icon-addtofav-1:before { content: '\f2bf'; } /* '' */
.icon-arrow:before { content: '\f2c0'; } /* '' */
.icon-cancel2:before { content: '\f2c1'; } /* '' */
.icon-designer-1:before { content: '\f2c2'; } /* '' */
.icon-equals2-1:before { content: '\f2c3'; } /* '' */
.icon-excel-1:before { content: '\f2c4'; } /* '' */
.icon-file2-1:before { content: '\f2c5'; } /* '' */
.icon-file1-1:before { content: '\f2c6'; } /* '' */
.icon-filter2:before { content: '\f2c7'; } /* '' */
.icon-icon12-1:before { content: '\f2c8'; } /* '' */
.icon-icon2-1:before { content: '\f2c9'; } /* '' */
.icon-Larrow:before { content: '\f2ca'; } /* '' */
.icon-lisitng-1:before { content: '\f2cb'; } /* '' */
.icon-notequals-1:before { content: '\f2cc'; } /* '' */
.icon-nottaget-1:before { content: '\f2cd'; } /* '' */
.icon-pdf-1:before { content: '\f2ce'; } /* '' */
.icon-print4:before { content: '\f2cf'; } /* '' */
.icon-question2-1:before { content: '\f2d0'; } /* '' */
.icon-rarrow:before { content: '\f2d1'; } /* '' */
.icon-recyclebin:before { content: '\f2d2'; } /* '' */
.icon-refresh:before { content: '\f2d3'; } /* '' */
.icon-stamp-1:before { content: '\f2d4'; } /* '' */
.icon-target3-1:before { content: '\f2d5'; } /* '' */
.icon-usersettings:before { content: '\f2d6'; } /* '' */
.icon-word-1:before { content: '\f2d7'; } /* '' */
.icon-bargraph2-1:before { content: '\f2d8'; } /* '' */
.icon-folder2-1:before { content: '\f2d9'; } /* '' */
.icon-list3-1:before { content: '\f2da'; } /* '' */
.icon-additionalinfo-1:before { content: '\f2db'; } /* '' */
.icon-call:before { content: '\f2dc'; } /* '' */
.icon-fileexport-1:before { content: '\f2dd'; } /* '' */
.icon-file3-1:before { content: '\f2de'; } /* '' */
.icon-info2-1:before { content: '\f2df'; } /* '' */
.icon-maillisitng:before { content: '\f2e0'; } /* '' */
.icon-toggle1:before { content: '\f2e1'; } /* '' */
.icon-toggle2:before { content: '\f2e2'; } /* '' */
.icon-add:before { content: '\f2e3'; } /* '' */
.icon-a-z-1:before { content: '\f2e4'; } /* '' */
.icon-calendar3:before { content: '\f2e5'; } /* '' */
.icon-email2:before { content: '\f2e6'; } /* '' */
.icon-favourite2-1:before { content: '\f2e7'; } /* '' */
.icon-file4-1:before { content: '\f2e8'; } /* '' */
.icon-graph-1:before { content: '\f2e9'; } /* '' */
.icon-member5:before { content: '\f2ea'; } /* '' */
.icon-mobile4-1:before { content: '\f2eb'; } /* '' */
.icon-notaligned-1:before { content: '\f2ec'; } /* '' */
.icon-pin2-1:before { content: '\f2ed'; } /* '' */
.icon-recyclebin2:before { content: '\f2ee'; } /* '' */
.icon-refresh2:before { content: '\f2ef'; } /* '' */
.icon-assignrule-1:before { content: '\f2f0'; } /* '' */
.icon-assign-1:before { content: '\f2f1'; } /* '' */
.icon-assignments-1:before { content: '\f2f2'; } /* '' */
.icon-bringtofront-1:before { content: '\f2f3'; } /* '' */
.icon-case2-1:before { content: '\f2f4'; } /* '' */
.icon-clone2-1:before { content: '\f2f5'; } /* '' */
.icon-conditions-1:before { content: '\f2f6'; } /* '' */
.icon-connections1-1:before { content: '\f2f7'; } /* '' */
.icon-connections2-1:before { content: '\f2f8'; } /* '' */
.icon-decision-1:before { content: '\f2f9'; } /* '' */
.icon-edit3:before { content: '\f2fa'; } /* '' */
.icon-elseif-1:before { content: '\f2fb'; } /* '' */
.icon-else-1:before { content: '\f2fc'; } /* '' */
.icon-grid2-1:before { content: '\f2fd'; } /* '' */
.icon-horizontalalignment-1:before { content: '\f2fe'; } /* '' */
.icon-if-1:before { content: '\f2ff'; } /* '' */
.icon-lookup-1:before { content: '\f300'; } /* '' */
.icon-new-1:before { content: '\f301'; } /* '' */
.icon-notification2:before { content: '\f302'; } /* '' */
.icon-panning-1:before { content: '\f303'; } /* '' */
.icon-payments-1:before { content: '\f304'; } /* '' */
.icon-pointer-1:before { content: '\f305'; } /* '' */
.icon-preview:before { content: '\f306'; } /* '' */
.icon-print5:before { content: '\f307'; } /* '' */
.icon-publish-1:before { content: '\f308'; } /* '' */
.icon-readdatasource-1:before { content: '\f309'; } /* '' */
.icon-recordcreate:before { content: '\f30a'; } /* '' */
.icon-recordlookup:before { content: '\f30b'; } /* '' */
.icon-obj209-1:before { content: '\f30c'; } /* '' */
.icon-obj210-1:before { content: '\f30d'; } /* '' */
.icon-obj314-1:before { content: '\f30e'; } /* '' */
.icon-arrow3:before { content: '\f30f'; } /* '' */
.icon-cardview-1:before { content: '\f310'; } /* '' */
.icon-tabview-1:before { content: '\f311'; } /* '' */
.icon-graphchange2-1:before { content: '\f312'; } /* '' */
.icon-manageview-1:before { content: '\f313'; } /* '' */
.icon-rss-1:before { content: '\f314'; } /* '' */
.icon-refresh3:before { content: '\f315'; } /* '' */
.icon-comment-1:before { content: '\f316'; } /* '' */
.icon-dateview:before { content: '\f317'; } /* '' */
.icon-favourite3-1:before { content: '\f318'; } /* '' */
.icon-gridview-1:before { content: '\f319'; } /* '' */
.icon-listview-1:before { content: '\f31a'; } /* '' */
.icon-notes-1:before { content: '\f31b'; } /* '' */
.icon-share4:before { content: '\f31c'; } /* '' */
.icon-bulk-1:before { content: '\f31d'; } /* '' */
.icon-cab_clone:before { content: '\f31e'; } /* '' */
.icon-demographic-1:before { content: '\f31f'; } /* '' */
.icon-mass:before { content: '\f320'; } /* '' */
.icon-import:before { content: '\f321'; } /* '' */
.icon-configure-1:before { content: '\f322'; } /* '' */
.icon-popup:before { content: '\f323'; } /* '' */
.icon-advanced-1:before { content: '\f324'; } /* '' */
.icon-geography-1:before { content: '\f325'; } /* '' */
.icon-scope:before { content: '\f326'; } /* '' */
.icon-inactive:before { content: '\f327'; } /* '' */
.icon-active:before { content: '\f328'; } /* '' */
.icon-action:before { content: '\f329'; } /* '' */
.icon-coach:before { content: '\f32a'; } /* '' */
.icon-convert:before { content: '\f32b'; } /* '' */
.icon-create:before { content: '\f32c'; } /* '' */
.icon-customer-1:before { content: '\f32d'; } /* '' */
.icon-enable:before { content: '\f32e'; } /* '' */
.icon-reject-1:before { content: '\f32f'; } /* '' */
.icon-remove:before { content: '\f330'; } /* '' */
.icon-schedule:before { content: '\f331'; } /* '' */
.icon-send:before { content: '\f332'; } /* '' */
.icon-sendemail:before { content: '\f333'; } /* '' */
.icon-test-1:before { content: '\f334'; } /* '' */
.icon-widget:before { content: '\f335'; } /* '' */
.icon-format_painter-1:before { content: '\f336'; } /* '' */
.icon-fullscreen-1:before { content: '\f337'; } /* '' */
.icon-update_assign:before { content: '\f338'; } /* '' */
.icon-amount:before { content: '\f339'; } /* '' */
.icon-applicationmanagement-1:before { content: '\f33a'; } /* '' */
.icon-customize-1:before { content: '\f33b'; } /* '' */
.icon-layout:before { content: '\f33c'; } /* '' */
.icon-manage-1:before { content: '\f33d'; } /* '' */
.icon-obj403-1:before { content: '\f33e'; } /* '' */
.icon-process-1:before { content: '\f33f'; } /* '' */
.icon-qualify-1:before { content: '\f340'; } /* '' */
.icon-Area-1:before { content: '\f341'; } /* '' */
.icon-Barometer-1:before { content: '\f342'; } /* '' */
.icon-Composite-1:before { content: '\f343'; } /* '' */
.icon-custom-menu-1:before { content: '\f344'; } /* '' */
.icon-Menu-1:before { content: '\f345'; } /* '' */
.icon-StackBar-1:before { content: '\f346'; } /* '' */
.icon-StackColumn-1:before { content: '\f347'; } /* '' */
.icon-drag-1:before { content: '\f348'; } /* '' */
.icon-hidetoolbar-01:before { content: '\f349'; } /* '' */
.icon-palettesstylestab-1:before { content: '\f34a'; } /* '' */
.icon-screenstemplatestab-1:before { content: '\f34b'; } /* '' */
.icon-settings-01:before { content: '\f34c'; } /* '' */
.icon-showtoolbar-01:before { content: '\f34d'; } /* '' */
.icon-toolboxpopout-01:before { content: '\f34e'; } /* '' */
.icon-and-1:before { content: '\f34f'; } /* '' */
.icon-compressfile-1:before { content: '\f350'; } /* '' */
.icon-datawatcher-1:before { content: '\f351'; } /* '' */
.icon-deletefile-1:before { content: '\f352'; } /* '' */
.icon-delete-01:before { content: '\f353'; } /* '' */
.icon-email-01:before { content: '\f354'; } /* '' */
.icon-export-2:before { content: '\f355'; } /* '' */
.icon-externalcall-1:before { content: '\f356'; } /* '' */
.icon-fieldupdate-01:before { content: '\f357'; } /* '' */
.icon-filetostream-1:before { content: '\f358'; } /* '' */
.icon-filewatcher-1:before { content: '\f359'; } /* '' */
.icon-flownotification-1:before { content: '\f35a'; } /* '' */
.icon-ftp-1:before { content: '\f35b'; } /* '' */
.icon-htmltopdf-1:before { content: '\f35c'; } /* '' */
.icon-import-01:before { content: '\f35d'; } /* '' */
.icon-insertuserteams-1:before { content: '\f35e'; } /* '' */
.icon-insert-1:before { content: '\f35f'; } /* '' */
.icon-integrationjob-01:before { content: '\f360'; } /* '' */
.icon-loggers-1:before { content: '\f361'; } /* '' */
.icon-movefile-1:before { content: '\f362'; } /* '' */
.icon-or-1:before { content: '\f363'; } /* '' */
.icon-parallel-1:before { content: '\f364'; } /* '' */
.icon-pass-1:before { content: '\f365'; } /* '' */
.icon-payments2-1:before { content: '\f366'; } /* '' */
.icon-pdfnotification-01:before { content: '\f367'; } /* '' */
.icon-pdftoimage-01:before { content: '\f368'; } /* '' */
.icon-pdftotiff-01:before { content: '\f369'; } /* '' */
.icon-pool-2:before { content: '\f36a'; } /* '' */
.icon-readfromdatasource2-1:before { content: '\f36b'; } /* '' */
.icon-sequencial-1:before { content: '\f36c'; } /* '' */
.icon-start-01:before { content: '\f36d'; } /* '' */
.icon-stop-01:before { content: '\f36e'; } /* '' */
.icon-swimlane-1:before { content: '\f36f'; } /* '' */
.icon-fetch:before { content: '\f370'; } /* '' */
.icon-glyph-485:before { content: '\f371'; } /* '' */
.icon-AddMember-1:before { content: '\f372'; } /* '' */
.icon-dms-1:before { content: '\f373'; } /* '' */
.icon-setup-1:before { content: '\f374'; } /* '' */
.icon-obj273:before { content: '\f375'; } /* '' */
.icon-move-1:before { content: '\f376'; } /* '' */
.icon-twitter20-1:before { content: '\f377'; } /* '' */
.icon-horizontal-menu-1:before { content: '\f378'; } /* '' */
.icon-vertical-menu-1:before { content: '\f379'; } /* '' */
.icon-obj208-1:before { content: '\f37a'; } /* '' */
.icon-SharedCalendar-1:before { content: '\f37b'; } /* '' */
.icon-sort-1:before { content: '\f37c'; } /* '' */
.icon-leads-conversions-1:before { content: '\f37d'; } /* '' */
.icon-call-autoflow-1:before { content: '\f37e'; } /* '' */
.icon-next-1:before { content: '\f37f'; } /* '' */
.icon-SaveProceed:before { content: '\f380'; } /* '' */
.icon-Reset-1:before { content: '\f381'; } /* '' */
.icon-AutomatchA-1:before { content: '\f382'; } /* '' */
.icon-AutomatchB-1:before { content: '\f383'; } /* '' */
.icon-trigger-1:before { content: '\f384'; } /* '' */
.icon-trigger_01-1:before { content: '\f385'; } /* '' */
.icon-trigger_data-source-1:before { content: '\f386'; } /* '' */
.icon-trigger_prepare-safe-1:before { content: '\f387'; } /* '' */
.icon-trigger_prepopulate-1:before { content: '\f388'; } /* '' */
.icon-trigger_update:before { content: '\f389'; } /* '' */
.icon-SetParameters-1:before { content: '\f38a'; } /* '' */
.icon-credit03:before { content: '\f38b'; } /* '' */
.icon-facility01:before { content: '\f38c'; } /* '' */
.icon-facility02:before { content: '\f38d'; } /* '' */
.icon-security01:before { content: '\f38e'; } /* '' */
.icon-security02:before { content: '\f38f'; } /* '' */
.icon-credit02:before { content: '\f390'; } /* '' */
.icon-credit01:before { content: '\f391'; } /* '' */
.icon-icon-obj100031:before { content: '\f392'; } /* '' */
.icon-icon-obj100032:before { content: '\f393'; } /* '' */
.icon-icon-obj100029:before { content: '\f394'; } /* '' */
.icon-tree-disabled-1:before { content: '\f395'; } /* '' */
.icon-tree-disabled:before { content: '\f396'; } /* '' */
.icon-model:before { content: '\f397'; } /* '' */
.icon-modelmaster:before { content: '\f398'; } /* '' */
.icon-releasenotes:before { content: '\f399'; } /* '' */
.icon-riskrating:before { content: '\f39a'; } /* '' */
.icon-security:before { content: '\f39b'; } /* '' */
.icon-securitymaster:before { content: '\f39c'; } /* '' */
.icon-corporatebanking:before { content: '\f39d'; } /* '' */
.icon-facilitymaster:before { content: '\f39e'; } /* '' */
.icon-neutral:before { content: '\f39f'; } /* '' */
.icon-sad:before { content: '\f3a0'; } /* '' */
.icon-happy:before { content: '\f3a1'; } /* '' */
.icon-add-01:before { content: '\f3a2'; } /* '' */
.icon-equal-01:before { content: '\f3a3'; } /* '' */
.icon-is-not-specified-01:before { content: '\f3a4'; } /* '' */
.icon-is-specified-01:before { content: '\f3a5'; } /* '' */
.icon-like-01:before { content: '\f3a6'; } /* '' */
.icon-not-equal-to-01:before { content: '\f3a7'; } /* '' */
.icon-notin-01:before { content: '\f3a8'; } /* '' */
.icon-relationship:before { content: '\f3a9'; } /* '' */
.icon-opportunity-:before { content: '\f3aa'; } /* '' */
.icon-today-1:before { content: '\f3ab'; } /* '' */
.icon-predictive:before { content: '\f3ac'; } /* '' */
.icon-flow-merge:before { content: '\f3ad'; } /* '' */
.icon-line-2:before { content: '\f3ae'; } /* '' */
.icon-model-selective:before { content: '\f3af'; } /* '' */
.icon-predictive-1:before { content: '\f3b0'; } /* '' */
.icon-slow-model:before { content: '\f3b1'; } /* '' */
.icon-split:before { content: '\f3b2'; } /* '' */
.icon-whatsapp:before { content: '\f3b3'; } /* '' */
.icon-dataset:before { content: '\f3b4'; } /* '' */
.icon-fast-model:before { content: '\f3b5'; } /* '' */
.icon-flow-split:before { content: '\f3b6'; } /* '' */
.icon-greaterequal-01:before { content: '\f3b7'; } /* '' */
.icon-lesser-01:before { content: '\f3b8'; } /* '' */
.icon-lesserequal-01:before { content: '\f3b9'; } /* '' */
.icon-between-01:before { content: '\f3ba'; } /* '' */
.icon-greater-01:before { content: '\f3bb'; } /* '' */
.icon-score-card:before { content: '\f3bc'; } /* '' */
.icon-in-01:before { content: '\f3bd'; } /* '' */
.icon-start-with-01:before { content: '\f3be'; } /* '' */
.icon-split-2:before { content: '\f3bf'; } /* '' */
.icon-teller-activities-01:before { content: '\f3c0'; } /* '' */
.icon-vault-activities-01:before { content: '\f3c1'; } /* '' */
.icon-vault-master-01:before { content: '\f3c2'; } /* '' */
.icon-buy:before { content: '\f3c3'; } /* '' */
.icon-myexit:before { content: '\f3c4'; } /* '' */
.icon-myinterview:before { content: '\f3c5'; } /* '' */
.icon-facebook-logo:before { content: '\f3c6'; } /* '' */
.icon-google-plus-logo:before { content: '\f3c7'; } /* '' */
.icon-twitter-logo:before { content: '\f3c8'; } /* '' */
.icon-Individual:before { content: '\f3c9'; } /* '' */
.icon-Subordinate:before { content: '\f3ca'; } /* '' */
.icon-Team:before { content: '\f3cb'; } /* '' */
.icon-Company:before { content: '\f3cc'; } /* '' */
.icon-myskill:before { content: '\f3cd'; } /* '' */
.icon-mytarget:before { content: '\f3ce'; } /* '' */
.icon-myattendance:before { content: '\f3cf'; } /* '' */
.icon-myconversation:before { content: '\f3d0'; } /* '' */
.icon-costing:before { content: '\f3d1'; } /* '' */
.icon-employee:before { content: '\f3d2'; } /* '' */
.icon-myleave:before { content: '\f3d3'; } /* '' */
.icon-myportfolio:before { content: '\f3d4'; } /* '' */
.icon-mypromotion:before { content: '\f3d5'; } /* '' */
.icon-action-center:before { content: '\f3d6'; } /* '' */
.icon-service-request:before { content: '\f3d7'; } /* '' */
.icon-balancesheet:before { content: '\f3d8'; } /* '' */
.icon-balancesheet_factor:before { content: '\f3d9'; } /* '' */
.icon-balancesheet_settings:before { content: '\f3da'; } /* '' */
.icon-woman:before { content: '\f3db'; } /* '' */
.icon-male:before { content: '\f3dc'; } /* '' */
.icon-no-phone:before { content: '\f3dd'; } /* '' */
.icon-no-water:before { content: '\f3de'; } /* '' */
.icon-phone-2:before { content: '\f3df'; } /* '' */
.icon-water:before { content: '\f3e0'; } /* '' */
.icon-datasource:before { content: '\f3e1'; } /* '' */
.icon-databox:before { content: '\f3e2'; } /* '' */
.icon-widget-designer:before { content: '\f3e3'; } /* '' */
.icon-area:before { content: '\f3e4'; } /* '' */
.icon-bubble-graph:before { content: '\f3e5'; } /* '' */
.icon-dashlets:before { content: '\f3e6'; } /* '' */
.icon-matrix:before { content: '\f3e7'; } /* '' */
.icon-progress-bar:before { content: '\f3e8'; } /* '' */
.icon-split-1:before { content: '\f3e9'; } /* '' */
.icon-embbded:before { content: '\f3ea'; } /* '' */
.icon-upoad:before { content: '\f3eb'; } /* '' */
.icon-new-browser-window:before { content: '\f3ec'; } /* '' */
.icon-minimize:before { content: '\f3ed'; } /* '' */
.icon-maximize:before { content: '\f3ee'; } /* '' */
.icon-google:before { content: '\f3ef'; } /* '' */
.icon-module:before { content: '\f3f0'; } /* '' */
.icon-workunit:before { content: '\f3f1'; } /* '' */
.icon-industry:before { content: '\f3f2'; } /* '' */
.icon-mdm:before { content: '\f3f3'; } /* '' */
.icon-balancesheet-1:before { content: '\f3f4'; } /* '' */
.icon-balancesheet_factor-1:before { content: '\f3f5'; } /* '' */
.icon-balancesheet_settings-1:before { content: '\f3f6'; } /* '' */
.icon-david_master:before { content: '\f3f7'; } /* '' */
.icon-insta:before { content: '\f3f8'; } /* '' */
.icon-advance-search:before { content: '\f3f9'; } /* '' */
.icon-kanban_settings:before { content: '\f3fa'; } /* '' */
.icon-kanban:before { content: '\f3fb'; } /* '' */
.icon-data-cloud:before { content: '\f3fc'; } /* '' */
.icon-dataconnection-2:before { content: '\f3fd'; } /* '' */
.icon-dataflow-definition:before { content: '\f3fe'; } /* '' */
.icon-dataset-1:before { content: '\f3ff'; } /* '' */
.icon-analytics-studio:before { content: '\f400'; } /* '' */
.icon-connector:before { content: '\f401'; } /* '' */
.icon-dashbaord-designer:before { content: '\f402'; } /* '' */
.icon-search-thin:before { content: '\f403'; } /* '' */
.icon-filter-2:before { content: '\f404'; } /* '' */
.icon-gridmenu:before { content: '\f405'; } /* '' */
.icon-lock-thin:before { content: '\f406'; } /* '' */
.icon-note-coach:before { content: '\f407'; } /* '' */
.icon-notification-thin:before { content: '\f408'; } /* '' */
.icon-plus-thin:before { content: '\f409'; } /* '' */
.icon-position:before { content: '\f40a'; } /* '' */
.icon-candidate:before { content: '\f40b'; } /* '' */
.icon-job-2:before { content: '\f40c'; } /* '' */
.icon-onboarding:before { content: '\f40d'; } /* '' */
.icon-partner:before { content: '\f40e'; } /* '' */
.icon-aiflow:before { content: '\f40f'; } /* '' */
.icon-aimodel:before { content: '\f410'; } /* '' */
.icon-datahub:before { content: '\f411'; } /* '' */
.icon-datasystem:before { content: '\f412'; } /* '' */
.icon-systementity:before { content: '\f413'; } /* '' */
.icon-connections:before { content: '\f414'; } /* '' */
.icon-kit:before { content: '\f415'; } /* '' */
.icon-promotion:before { content: '\f416'; } /* '' */
.icon-customer-2:before { content: '\f417'; } /* '' */
.icon-merchant:before { content: '\f418'; } /* '' */
.icon-partner-1:before { content: '\f419'; } /* '' */
.icon-pos:before { content: '\f41a'; } /* '' */
.icon-program:before { content: '\f41b'; } /* '' */
.icon-voucher:before { content: '\f41c'; } /* '' */
.icon-score-board:before { content: '\f41d'; } /* '' */
.icon-temp1:before { content: '\f41e'; } /* '' */
.icon-temp2:before { content: '\f41f'; } /* '' */
.icon-temp3:before { content: '\f420'; } /* '' */
.icon-temp4:before { content: '\f421'; } /* '' */
.icon-temp5:before { content: '\f422'; } /* '' */
.icon-temp6:before { content: '\f423'; } /* '' */
.icon-temp7:before { content: '\f424'; } /* '' */
.icon-temp8:before { content: '\f425'; } /* '' */
.icon-temp9:before { content: '\f426'; } /* '' */
.icon-temp10:before { content: '\f427'; } /* '' */
.icon-voucher-1:before { content: '\f428'; } /* '' */
.icon-add-point:before { content: '\f429'; } /* '' */
.icon-cashback:before { content: '\f42a'; } /* '' */
.icon-cheque:before { content: '\f42b'; } /* '' */
.icon-discount:before { content: '\f42c'; } /* '' */
.icon-level-up:before { content: '\f42d'; } /* '' */
.icon-mode-payments:before { content: '\f42e'; } /* '' */
.icon-number-transactions:before { content: '\f42f'; } /* '' */
.icon-online:before { content: '\f430'; } /* '' */
.icon-pos-1:before { content: '\f431'; } /* '' */
.icon-product-category:before { content: '\f432'; } /* '' */
.icon-product-sub-category:before { content: '\f433'; } /* '' */
.icon-transaction-amount:before { content: '\f434'; } /* '' */
.icon-transaction-location:before { content: '\f435'; } /* '' */
.icon-plane:before { content: '\f436'; } /* '' */
.icon-hotel:before { content: '\f437'; } /* '' */
.icon-fuel:before { content: '\f438'; } /* '' */
.icon-market:before { content: '\f439'; } /* '' */
.icon-top-arrow:before { content: '\f43a'; } /* '' */
.icon-transaction:before { content: '\f43b'; } /* '' */
.icon-trophy-2:before { content: '\f43c'; } /* '' */
.icon-whatsapp-1:before { content: '\f43d'; } /* '' */
.icon-fuel-1:before { content: '\f43e'; } /* '' */
.icon-chat-1:before { content: '\f43f'; } /* '' */
.icon-group-2:before { content: '\f440'; } /* '' */
.icon-hotel-1:before { content: '\f441'; } /* '' */
.icon-mall:before { content: '\f442'; } /* '' */
.icon-points:before { content: '\f443'; } /* '' */
.icon-semicircular:before { content: '\f444'; } /* '' */
.icon-diagram:before { content: '\f445'; } /* '' */
.icon-gantt:before { content: '\f446'; } /* '' */
.icon-multiple:before { content: '\f447'; } /* '' */
.icon-radar-chart:before { content: '\f448'; } /* '' */
.icon-pyramid:before { content: '\f449'; } /* '' */
.icon-sankey:before { content: '\f44a'; } /* '' */
.icon-dialer:before { content: '\f44b'; } /* '' */
.icon-chat:before { content: '\f44c'; } /* '' */
.icon-notes-2:before { content: '\f44d'; } /* '' */
.icon-callscript:before { content: '\f44e'; } /* '' */
.icon-geo-location:before { content: '\f44f'; } /* '' */
.icon-knowledge:before { content: '\f450'; } /* '' */
.icon-masterattribute:before { content: '\f451'; } /* '' */
.icon-master-management:before { content: '\f452'; } /* '' */
.icon-social:before { content: '\f453'; } /* '' */
.icon-vaultmaster:before { content: '\f454'; } /* '' */
.icon-ai-flow:before { content: '\f455'; } /* '' */
.icon-analytical:before { content: '\f456'; } /* '' */
.icon-knowledge-1:before { content: '\f457'; } /* '' */
.icon-masterattribute-1:before { content: '\f458'; } /* '' */
.icon-package:before { content: '\f459'; } /* '' */
.icon-geo-location-1:before { content: '\f45a'; } /* '' */
.icon-datanext-scheduling:before { content: '\f45b'; } /* '' */
.icon-datanextjob1:before { content: '\f45c'; } /* '' */
.icon-dialer1:before { content: '\f45d'; } /* '' */
.icon-datanextjob:before { content: '\f45e'; } /* '' */
.icon-bubble-2:before { content: '\f45f'; } /* '' */
.icon-chat-group:before { content: '\f460'; } /* '' */
.icon-chat-policy:before { content: '\f461'; } /* '' */
.icon-meet:before { content: '\f462'; } /* '' */
.icon-notrespond:before { content: '\f463'; } /* '' */
.icon-zoom:before { content: '\f464'; } /* '' */
.icon-slight-positive:before { content: '\f465'; } /* '' */
.icon-pulse-2:before { content: '\f466'; } /* '' */
.icon-leaderboard:before { content: '\f467'; } /* '' */
.icon-game:before { content: '\f468'; } /* '' */
.icon-leaderboardconsole:before { content: '\f469'; } /* '' */
.icon-analytical-1:before { content: '\f46a'; } /* '' */
.icon-canned-response:before { content: '\f46b'; } /* '' */
.icon-overview:before { content: '\f46c'; } /* '' */
.icon-manage-categories:before { content: '\f46d'; } /* '' */
.icon-data-context:before { content: '\f46e'; } /* '' */
.icon-data-studio:before { content: '\f46f'; } /* '' */
.icon-data-studio-original:before { content: '\f470'; } /* '' */
.icon-data-system:before { content: '\f471'; } /* '' */
.icon-system-entity:before { content: '\f472'; } /* '' */
.icon-workspace-2:before { content: '\f473'; } /* '' */
.icon-ai:before { content: '\f474'; } /* '' */
.icon-connection-2:before { content: '\f475'; } /* '' */
.icon-data-box:before { content: '\f476'; } /* '' */
.icon-data-cloud-1:before { content: '\f477'; } /* '' */
.icon-motionchart:before { content: '\f478'; } /* '' */
.icon-map-chart:before { content: '\f479'; } /* '' */
.icon-histogram:before { content: '\f47a'; } /* '' */
.icon-gantt-charts:before { content: '\f47b'; } /* '' */
.icon-workspace-3:before { content: '\f47c'; } /* '' */
.icon-bullet-chart:before { content: '\f47d'; } /* '' */
.icon-box-and-whisker-plots:before { content: '\f47e'; } /* '' */
.icon-working-capital:before { content: '\f47f'; } /* '' */
.icon-accounts:before { content: '\f480'; } /* '' */
.icon-cash-management:before { content: '\f481'; } /* '' */
.icon-conversion-rate:before { content: '\f482'; } /* '' */
.icon-credit:before { content: '\f483'; } /* '' */
.icon-deposit:before { content: '\f484'; } /* '' */
.icon-downward-graph:before { content: '\f485'; } /* '' */
.icon-funded:before { content: '\f486'; } /* '' */
.icon-kyc:before { content: '\f487'; } /* '' */
.icon-limit:before { content: '\f488'; } /* '' */
.icon-new-opportunity:before { content: '\f489'; } /* '' */
.icon-open-cases:before { content: '\f48a'; } /* '' */
.icon-satisfaction:before { content: '\f48b'; } /* '' */
.icon-touch-rate:before { content: '\f48c'; } /* '' */
.icon-upward-graph:before { content: '\f48d'; } /* '' */
.icon-fb:before { content: '\f48e'; } /* '' */
.icon-borrow:before { content: '\f48f'; } /* '' */
.icon-cart:before { content: '\f490'; } /* '' */
.icon-insure:before { content: '\f491'; } /* '' */
.icon-invest:before { content: '\f492'; } /* '' */
.icon-pay:before { content: '\f493'; } /* '' */
.icon-save-2:before { content: '\f494'; } /* '' */
.icon-whatsapp-template:before { content: '\f495'; } /* '' */
.icon-inputfield:before { content: '\f496'; } /* '' */
.icon-predictive-2:before { content: '\f497'; } /* '' */
.icon-smile1:before { content: '\f498'; } /* '' */
.icon-smile2:before { content: '\f499'; } /* '' */
.icon-smile3:before { content: '\f49a'; } /* '' */
.icon-smile4:before { content: '\f49b'; } /* '' */
.icon-smile5:before { content: '\f49c'; } /* '' */
.icon-skillmappingrule:before { content: '\f49d'; } /* '' */
.icon-clock3:before { content: '\f49e'; } /* '' */
.icon-allocationpolicy:before { content: '\f49f'; } /* '' */
.icon-allocationqueue:before { content: '\f4a0'; } /* '' */
.icon-channelprovider:before { content: '\f4a1'; } /* '' */
.icon-chatagent:before { content: '\f4a2'; } /* '' */
.icon-chatwidget:before { content: '\f4a3'; } /* '' */
.icon-conversation:before { content: '\f4a4'; } /* '' */
.icon-online-1:before { content: '\f4a5'; } /* '' */
.icon-presencesetting:before { content: '\f4a6'; } /* '' */
.icon-presencesetting1:before { content: '\f4a7'; } /* '' */
.icon-presencestatus:before { content: '\f4a8'; } /* '' */
.icon-routingbuilder:before { content: '\f4a9'; } /* '' */
.icon-skill:before { content: '\f4aa'; } /* '' */
.icon-allocationchannel:before { content: '\f4ab'; } /* '' */
.icon-settingnew:before { content: '\f4ac'; } /* '' */
.icon-declined-2:before { content: '\f4ad'; } /* '' */
.icon-multi-tab-chart:before { content: '\f4ae'; } /* '' */
.icon-tentative-01:before { content: '\f4af'; } /* '' */
.icon-accepted:before { content: '\f4b0'; } /* '' */
.icon-teams:before { content: '\f4b5'; } /* '' */
.icon-heatmap:before { content: '\f4b6'; } /* '' */
.icon-neutral-1:before { content: '\f4b7'; } /* '' */
.icon-very-negative:before { content: '\f4b8'; } /* '' */
.icon-negative:before { content: '\f4ba'; } /* '' */
.icon-positive:before { content: '\f4bb'; } /* '' */
.icon-rankstage_0:before { content: '\f4be'; } /* '' */
.icon-rankstage_1:before { content: '\f4bf'; } /* '' */
.icon-rankstage_3:before { content: '\f4c0'; } /* '' */
.icon-reward:before { content: '\f4c1'; } /* '' */
.icon-syncnow:before { content: '\f4c2'; } /* '' */
.icon-syncnow2:before { content: '\f4c3'; } /* '' */
.icon-waterfall-chart:before { content: '\f4cb'; } /* '' */
.icon-distribution-plotchart:before { content: '\f4cc'; } /* '' */
.icon-pareto-charts:before { content: '\f4cd'; } /* '' */
.icon-time-closure-01:before { content: '\f4ce'; } /* '' */
.icon-transcript-extraction-01:before { content: '\f4cf'; } /* '' */
.icon-bot-extraction-01-01:before { content: '\f4d0'; } /* '' */
.icon-churn-probability-01:before { content: '\f4d1'; } /* '' */
.icon-email-extraction-01:before { content: '\f4d2'; } /* '' */
.icon-entity-extraction-01:before { content: '\f4d3'; } /* '' */
.icon-image-extraction-01:before { content: '\f4d4'; } /* '' */
.icon-lead-score-01:before { content: '\f4d5'; } /* '' */
.icon-sentiment-analysis-01:before { content: '\f4d6'; } /* '' */
.icon-predictive-analytics-01:before { content: '\f4d7'; } /* '' */
.icon-postgres:before { content: '\f4d8'; } /* '' */
.icon-stream:before { content: '\f4d9'; } /* '' */
.icon-batch:before { content: '\f4da'; } /* '' */
.icon-batch2:before { content: '\f4db'; } /* '' */
.icon-cassandra:before { content: '\f4dc'; } /* '' */
.icon-maria-db:before { content: '\f4dd'; } /* '' */
.icon-file1-2:before { content: '\f4de'; } /* '' */
.icon-mssql:before { content: '\f4df'; } /* '' */
.icon-mongo-db:before { content: '\f4e0'; } /* '' */
.icon-oracle:before { content: '\f4e1'; } /* '' */
.icon-signals:before { content: '\f4e2'; } /* '' */
.icon-ai-flow-1:before { content: '\f4e3'; } /* '' */
.icon-analytics-dashboard:before { content: '\f4e4'; } /* '' */
.icon-data-flow:before { content: '\f4e5'; } /* '' */
.icon-bigint:before { content: '\f4e6'; } /* '' */
.icon-computed:before { content: '\f4e7'; } /* '' */
.icon-text:before { content: '\f4e8'; } /* '' */
.icon-ageing:before { content: '\f4e9'; } /* '' */
.icon-aggregation:before { content: '\f4ea'; } /* '' */
.icon-compositecomputed:before { content: '\f4eb'; } /* '' */
.icon-decimal:before { content: '\f4ec'; } /* '' */
.icon-extended-multipicker:before { content: '\f4ed'; } /* '' */
.icon-keypairfield:before { content: '\f4ee'; } /* '' */
.icon-language:before { content: '\f4ef'; } /* '' */
.icon-longtext:before { content: '\f4f0'; } /* '' */
.icon-multipicker:before { content: '\f4f1'; } /* '' */
.icon-multipicker-withid:before { content: '\f4f2'; } /* '' */
.icon-multivalue-with-check-box:before { content: '\f4f3'; } /* '' */
.icon-regex:before { content: '\f4f4'; } /* '' */
.icon-secure-text-box:before { content: '\f4f5'; } /* '' */
.icon-sla:before { content: '\f4f6'; } /* '' */
.icon-otpfield:before { content: '\f4f7'; } /* '' */
.icon-crmnext:before { content: '\f4f8'; } /* '' */
.icon-baby:before { content: '\f4f9'; } /* '' */
.icon-bike:before { content: '\f4fa'; } /* '' */
.icon-birth:before { content: '\f4fb'; } /* '' */
.icon-car:before { content: '\f4fc'; } /* '' */
.icon-graduation:before { content: '\f4fd'; } /* '' */
.icon-marriage:before { content: '\f4ff'; } /* '' */
.icon-property:before { content: '\f500'; } /* '' */
.icon-retirement:before { content: '\f501'; } /* '' */
.icon-job-work:before { content: '\f502'; } /* '' */
.icon-promedia:before { content: '\f503'; } /* '' */
.icon-servicedocument:before { content: '\f504'; } /* '' */
.icon-echannel:before { content: '\f507'; } /* '' */
.icon-featurebenefit:before { content: '\f508'; } /* '' */
.icon-globalbanner:before { content: '\f509'; } /* '' */
.icon-marketingprogram:before { content: '\f50a'; } /* '' */
.icon-data-set:before { content: '\f50b'; } /* '' */
.icon-channelservice:before { content: '\f50c'; } /* '' */
.icon-entity-lightup:before { content: '\f50d'; } /* '' */
.icon-serviceguideline:before { content: '\f50e'; } /* '' */
.icon-classification:before { content: '\f50f'; } /* '' */
.icon-entityextraction:before { content: '\f510'; } /* '' */
