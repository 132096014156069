@import "../../style/variables";

.noPending {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: $lightGrayColor;
  padding: 50px;
  text-align: center;
  opacity: 0.5;
}
