.notification_section {
  .setting_option {
    width: 50%;
    margin-bottom: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 60%;
      .title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 8px;
      }
      .para {
        font-weight: 400;
        font-size: 16px;
        opacity: 0.5;
      }
    }
    .right{
      
    }
  }
}
