.complaint_card_container {
  .filter_container {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .property_detail_dd {
    // opacity: 0.3;
    padding: 7px 12px !important;
  }
  .cards_request {
    display: flex;
    gap: 20px;
    max-height: 332px;
    overflow: scroll;
    overflow-x: hidden;
    flex-wrap: wrap;
    width: 101.5%;
    .slider_card {
      width: 438px;
      padding: 20px 20px;
      border: 1px solid #c9d8df;
      border-radius: 4px;
      background: rgba(159, 199, 156, 0.2);
      .slide_heading {
        display: flex;
        justify-content: space-between;
        .slide_header {
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
          .header_side_part {
            padding-left: 9px;
            width: 60px;
            border-left: 1px solid #0820174a;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
            opacity: 0.7;
          }
        }
        .slide_heading_ticket {
          font-weight: 400;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 100%;
          text-align: right;
          gap: 5px;
          color: #082017;
        }
      }
      .slide_name {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        margin-top: 10px;
        opacity: 0.5;
      }
      .slide_content {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: "pnum" on, "lnum" on;
        margin-top: 24px;
        color: #082017;
      }
    }
  }
}

.request_drawer {
  .request_header {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.1);
    .request_head_name {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 20px */

      display: flex;
      align-items: center;
      font-feature-settings: "pnum" on, "lnum" on;

      color: #082017;
      gap: 10px;
    }
    .closeBtn {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
  .ticket {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    .ticket_no {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: right;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
    .ticket_time {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-align: right;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.5;
    }
  }
  .address_name {
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #082017;
  }
  .sub_address_name {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #082017;
    opacity: 0.5;
  }
  .name {
    margin-top: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #082017;
  }
  .number {
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .request_line {
    border: 0.3px dashed #08201745;
    margin-top: 32px;
  }
  .food_specifications {
    margin-top: 32px;
    width: 132px;
    height: 30px;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #321712;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ede6e0;
  }
  .food_content {
    width: 100%;
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #082017;
  }
  .food_time_date {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
    opacity: 0.5;
  }
  .add_response {
    margin-top: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .textarea {
    width: 100%;
    margin-top: 12px;
    outline: none;
    resize: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #082017;
    opacity: 0.6;
    border: 1px solid #082017;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 16px;
    padding-right: 30px;
    height: 110px;
  }
  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .requestbuttons {
    width: 190px;
    height: 40px;
  }
}

.modal_content {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  width: 60%;
  color: #082017;
}

.complaint_property {
  background: rgba(201, 216, 223, 0.1) !important;
  /* Primary/Blue Sky */
  border: 1px solid #c9d8df !important;
  border-radius: 4px !important;
  margin: 0 !important;
  max-width: 438px !important;
}

.property_detail_date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}
.clear_selected {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-bottom: 1px solid #082017;
  color: #082017;
  opacity: 0.8;
  cursor: pointer;  z-index: 11;
}
