.visit_history {
  width: 100%;
  .dropdown_groups {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .date_drop_down {
      width: 175px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      border: 1px solid #e0e2e1;
      border-radius: 2px;
      cursor: pointer;

      .select_date {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #98a29d;
        gap: 10px;
        align-items: center;
        img {
          opacity: 0.4;
        }
      }
      .know_more_icon {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #98a29d;
      }
    }
    .add_monthly_btn {
      width: 180px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #082017;
    }
  }
  .vist_card_group {
    overflow: scroll;
    margin-top: 24px;
    overflow-x: hidden;
    max-height: 360px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .visit_cards {
      width: 48%;
      padding: 24px 24px;
      margin-top: 24px;
      height: 330px;
      background: rgba(201, 216, 223, 0.1);
      border-radius: 4px;
      border: 1px solid #c9d8df;
      .vist_head {
        display: flex;
        justify-content: space-between;
        .head_left {
          display: flex;
          gap: 12px;
          .profile_icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            background: #e5dbd3;
            align-items: center;
            justify-content: center;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .head_name {
            position: relative;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #082017;
            .head_name_part {
              width: 100px;
              position: absolute;
              top: 60%;
              left: 0;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              display: flex;
              align-items: center;
              font-feature-settings: "pnum" on, "lnum" on;
              color: #082017;
              opacity: 0.5;
            }
          }
        }
        .head_right {
          position: relative;
          .menu_btn {
            cursor: pointer;
          }
          .menu_list_show {
            position: absolute;
            z-index: 12;
            top: 40px;
            left: -170px;
            filter: drop-shadow(0px 5px 5px rgba(8, 32, 23, 0.03))
              drop-shadow(0px 14px 14px rgba(8, 32, 23, 0.04))
              drop-shadow(0px 35px 33px rgba(8, 32, 23, 0.04));
            border-radius: 4px;
            .menu_list {
              height: 40px;
              display: flex;
              padding: 16px;
              background: #ffffff;
              border-radius: 0px;

              color: #082017;
              span {
                display: flex;
                cursor: pointer;
                align-items: center;
                gap: 15px;
              }
              transition: 0.3s ease-in;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #082017;
              width: 190px;

              &:hover {
                background: rgb(214, 237, 248);
              }
            }
            .divider {
              opacity: 0.1;
              border: 0.8px solid #082017;
            }
          }
        }
      }
      .visit_container {
        margin-top: 32px;
        .container_head {
          font-size: 14px;
          line-height: 100%;
          font-feature-settings: "pnum" on, "lnum" on;
          color: #082017;
        }
        .task {
          margin-top: 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          font-feature-settings: "pnum" on, "lnum" on;
          color: hsl(158, 60%, 8%);
          .high_task {
            display: flex;
            gap: 25px;
            .task1 {
              display: flex;
              gap: 10px;
              align-items: center;
              color: #08201752;
            }
            .task2 {
              display: flex;
              gap: 10px;
              align-items: center;
              color: #08201752;
            }
          }
          .task3 {
            margin-top: 16px;
            display: flex;
            color: #08201752;
            gap: 10px;
            align-items: center;
          }
          .task4 {
            margin-top: 16px;
            display: flex;
            color: #08201752;
            gap: 10px;
            align-items: center;
          }
        }
      }
      .visit_footer {
        margin-top: 40px;
        display: flex;
        gap: 100px;
        .footer_left {
          .footer_name {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            /* identical to box height, or 14px */

            display: flex;
            align-items: center;
            font-feature-settings: "pnum" on, "lnum" on;

            /* Primary/Deep Forest */

            color: #082017;
          }
          .star_icon {
            margin-top: 10px;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }
}
.modal_head {
  font-size: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 135.19%;
  color: #082017;
  font-weight: 600;
}

.modal_content {
  margin-top: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #082017;
}
.group_btn {
  display: flex;
  gap: 20px;
  margin-top: 32px;
  .cancel_btn {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 2px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    cursor: pointer;

    color: #082017;
  }
  .del_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 40px;
    background: #932a24;
    border-radius: 2px;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}
.trash_img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #932a24;
}
.monthly_drawer {
  padding: 24px;
  .monthly_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.1);

    .monthly_head_name {
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
  }
  .date {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #082017;
  }
  .date_input {
    margin-top: 12px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    outline: none;

    color: #98a29d;
  }

  .completed {
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #082017;
  }
  .dropdown_task {
    margin-top: 12px;
    padding: 14px 12px;
    border: 1px solid #e0e2e1;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropdown_select {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #98a29d;
    }
    .dropdown_icon {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #98a29d;
    }
  }

  .staff {
    margin-top: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #082017;
  }

  .pattern {
    display: flex;
    gap: 30px;
    margin-top: 26px;
    .star_pattern {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.4;
      .star_icon {
        font-size: 40px;
        color: #ffffff;

        &.on {
          color: #ffc900;
        }
        &.off {
          color: #082017;
        }
      }
    }
  }
  .add_response {
    margin-top: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .textarea {
    width: 100%;
    margin-top: 12px;
    outline: none;
    resize: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #082017;
    opacity: 0.6;
    border: 1px solid #082017;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 16px;
    padding-right: 30px;
    height: 110px;
  }
  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;

  }
  .requestbuttons {
    width: 190px;
    height: 40px;
  }
}

.date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}
