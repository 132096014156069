.complaint_page {
  padding: 65px 55px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.5);
    padding-bottom: 32px;
    margin-bottom: 25px;
    .left {
      font-weight: 600;
      font-size: 24px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 8px;
      .property_detail_dd {
        padding: 7px 12px !important;
        border: 1px solid #80a07d;
      }
    }
  }
  .filter_select {
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.5);
    padding-bottom: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    .filter_box {
      border: 1px solid #80a07d;
      border-radius: 2px;
      padding: 12px 16px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      gap: 10px;
      img {
        cursor: pointer;
      }
    }
  }
  .request_card_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  .sorting_filters {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .property_detail_dd {
      // border: 1px solid #80a07d;
    }
    .property_sorting_dd {
      // opacity: 0.5;
    }
  }
  .date_dd_style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #98a29d;
    border-radius: 2px;
    font-weight: 400;
    background-color: #98a29d00;
    font-size: 16px;
    cursor: pointer;
  }
}

.picker_dd {
  width: 200px !important;
  padding: 11px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #98a29d;
  border-radius: 2px;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5;
  color: #082017;
}

.selectedOwner {
  width: 100%;
  margin-bottom: 32px;
  border-bottom: 0.5px solid rgba(8, 32, 23, 0.5);
}
.clear_selected {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-bottom: 1px solid #082017;
  color: #082017;
  opacity: 0.8;
  cursor: pointer;  z-index: 11;
}