.why_avg_rating_container {
  .error {
   margin-top: 8px;
    color: red;
  }
  .body {
    margin: 12px 0;
    .card_container {
      padding: 5px;
      display: inline-block;
      &.active {
        .card {
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          background: #082017;
          color: #ffffff;
          border: 1px solid #082017;
          border-radius: 2px;
          padding: 10px;
        }
      }
      .card {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        background: #fafbfc;
        border: 1px solid #80a07d;
        border-radius: 2px;
        padding: 10px;
      }
    }
  }
}

.staff,
.why_avg_rating {
  margin-top: 32px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #082017;
}

.pattern {
  display: flex;
  gap: 30px;
  margin-top: 26px;
  .star_pattern {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;

    .star_icon {
      font-size: 40px;
      color: #ffffff;
      img {
        height: 20px;
        width: 20px;
      }
      &.on {
        .start_inactive {
          display: none;
        }
        .start_active {
          display: block;
        }
      }
      &.off {
        .start_inactive {
          display: block;
        }
        .start_active {
          display: none;
        }
      }
    }
  }
}
.error {
  margin-top: 8px;
   color: red;
 }