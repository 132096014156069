.property_direction_container {
  .input_group {
    margin-bottom: 32px;
    .title {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 16px;
    }
    .input_field {
      position: relative;
      .address_field {
        font-size: 16px;
        width: 70%;
      }
      input,
      textarea {
        padding: 11px 12px;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #e0e2e1;
        border-radius: 2px;
      }
      textarea {
        min-height: 50px;
        height: auto;
      }
      button {
        cursor: pointer;
        padding: 11px 19px;
        font-weight: 600;
        font-size: 16px;
        border: 1px solid #082017;
        border-radius: 2px;
        text-align: center;

        display: inline;
        background-color: transparent;
      }
      &.flex_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        input {
          font-size: 16px;
          width: 70%;
        }
        // button {
        //   width: 30%;
        //   background-color: transparent;
        // }
      }
      .select_input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
      }
      .select_box {
        height: 91px;
        padding: 8px 0px;
        border: 1px dashed #082017;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .location_detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 9px auto;
      gap: 16px;
      .img_box_uplaod {
        position: relative;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #e0e2e1;
        border-radius: 2px;
        font-size: 16px;
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .detail_name {
        width: 183px;
        height: 44px;
        padding: 6px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #e0e2e1;
        border-radius: 2px;
        font-weight: 500;
        font-size: 16px;
      }
      input {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        flex-grow: 2;
        gap: 251px;
        height: 44px;
        border: 1px solid #e0e2e1;
        border-radius: 2px;
        font-weight: 400;
        font-size: 16px;
      }
      .remove {
        font-weight: 600;
        font-size: 16px;
        width: 54px;
        height: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .distance {
      width: 69%;
      margin-left: 14%;
      height: 44px;
      padding: 6px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #e0e2e1;
      border-radius: 2px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.drop_down_container_direction {
  width: 100%;
  position: relative;
  .drop_down_selected {
    padding: 11px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #98a29d;
    border-radius: 2px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.5;
    color: #082017;
    .rotate_icon {
      transform: rotate(180deg);
    }
    &.dd_active {
      opacity: 1;
    }
  }
  .drop_down_box {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
      drop-shadow(-8px 8px 16px rgba(0, 0, 0, 0.08));
    border-radius: 4px;

    background: #ffffff;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    z-index: 10;
    .menu {
      padding: 12px;
      padding-right: 6px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      gap: 8px;
      color: #98a29d;
      cursor: pointer;
      .active_circle {
        display: none;
      }
      .inactive_circle {
        display: block;
      }
      &.active {
        color: #082017;
        .active_circle {
          display: block;
        }
        .inactive_circle {
          display: none;
        }
      }
    }
    .divider {
      margin: 0 12px;
      height: 0.3px;
      background-color: #98a29d;
    }
    .clear {
      padding: 12px;
      padding-right: 6px;
      text-align: right;
      font-weight: 500;
      font-size: 16px;
      color: #082017;
      border-top: 0.3px solid #98a29d;
      cursor: pointer;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
  }
}

.drop_down_items {
  .divider {
    &:last-child {
      display: none;
    }
  }
}
.error {
  margin-top: 8px;
  color: red;
  display: block;
}