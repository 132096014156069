.upcoming_visit {
  width: 49%;
  padding: 24px 24px;
  height: 100px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c9d8df;
  .name {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
    opacity: 0.7;
  }
  .add_btn {
    margin-top: -34px;
    cursor: pointer;
    float: right;
    width: 120px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #082017;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #082017;
  }
  .date {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
}
