.modal_container {

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #082017;
    opacity: 0.5;
    z-index: 9;
  }
  padding: 30px;
  .modal_add_owner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 4px;
    width: 657px;
    z-index: 12;
    padding: 48px;
    .close_btn {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .header {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 35px;
    }
    .divider {
      text-align: center;
      position: relative;
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-weight: 500;
        font-size: 20px;
        background-color: #fff;
        margin: 0 auto;
        z-index: 2;
      }
      .line {
        width: 250px;
        height: 0px;
        opacity: 0.2;
        border: 1px solid #082017;
      }
    }
    .body {
      .input_group {
        margin-bottom: 24px;
        .title {
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 16px;
        }
        .input_field {
          position: relative;
          input {
            padding: 6px 12px;
            width: 100%;
            height: 40px;
            border: 1px solid #80a07d;
            border-radius: 2px;
          }
          .input_error{
            font-size:11px;
            color: #c02929;
            padding-top: 2px;
          }
          &.error{
            input{
              border: 1px solid #c02929;
              
            }
          }
          button {
            padding: 8px 0px;
            border: 1px solid #082017;
            border-radius: 2px;
            text-align: center;
            width: 100%;
          }
          &.flex_box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            input {
              width: 70%;
            }
            button {
              width: 30%;
              background-color: transparent;
            }
          }
          .select_input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
          .select_box {
            height: 91px;
            padding: 8px 0px;
            border: 1px dashed #082017;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .property_detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 9px auto;
            .img_box_uplaod {
              position: relative;
              width: 44px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px dashed #e0e2e1;
              border-radius: 2px;
              font-size: 16px;
              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
              }
            }
            .detail_name {
              width: 183px;
              height: 44px;
              padding: 6px 12px;
              display: flex;
              flex-direction: row;
              align-items: center;
              border: 1px solid #e0e2e1;
              border-radius: 2px;
              font-weight: 500;
              font-size: 16px;
            }
            input {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 6px 12px;
              gap: 251px;
              width: 72px;
              height: 44px;
              border: 1px solid #e0e2e1;
              border-radius: 2px;
              font-weight: 400;
              font-size: 16px;
            }
            .cross {
              font-weight: 400;
              font-size: 12px;
            }
            .remove {
              font-weight: 600;
              font-size: 16px;
              width: 54px;
              height: 18px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .action_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        .btn_action {
          width: 108.67px;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px 8px;
          border: 1px solid #082017;
          border-radius: 2px;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
          background-color: #fff;
          &.submit {
            background: #082017;
            color: #fff;
          }
        }
      }
    }
    .drop_down_container {
      margin-bottom: 45px;
      position: relative;
      .select_input {
        width: 100%;
        height: 40px;
        padding: 6px 12px;
        border: 1px solid #98a29d;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          transform: rotate(180deg);
        }
        &.active {
          transform: rotate(0);
        }
      }
      .drop_down {
        position: absolute;
        top: 55px;
        left: 0;
        padding: 16px 12px;
        width: 100%;
        max-height: 424px;
        overflow-y: scroll;
        z-index: 10;
        background: #ffffff;
        filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))
          drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
          drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
        border-radius: 2px;
        z-index: 10;
        .search_box {
          position: relative;
          margin-bottom: 12px;
          input {
            font-weight: 400;
            font-size: 16px;
            border: 1px solid #98a29d;
            border-radius: 2px;
            padding: 6px 12px;
            display: block;
            width: 100%;
          }
          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 19.8px;
          }
        }
        .options {
          font-weight: 500;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          border-top: 0.3px solid #98a29d;
          border-bottom: 0.3px solid #98a29d;
          padding: 14px 0;
          cursor: pointer;
          .circle_active {
            display: none;
          }
          &.active {
            .circle_inactive {
              display: none;
            }
            .circle_active {
              display: block;
            }
          }
        }
      }
      .overlayDD {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 9;
      }
    }
    .error_msg{
      position: absolute;
      top: 10px;
      left: 10px;
      display: inline-block;
      padding: 3px 10px;
      background-color: rgba(255, 0, 0, 0.635);
      color: white;
      border-radius: 5px;
    }
  }
  .custom_dd {
    padding: 6px 12px;
    width: 100%;
    height: 40px;
    border: 1px solid #80a07d;
    border-radius: 2px;
    width: 426%;
    .drop_down_container {
      width: 426%;
    }
  }
}
