@import "../style/variables";

.sidebarWrapper {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.pageviewWrapper {
  width: calc(100% - 101px);
  background: #f8f8f7;
  min-height: calc(100vh - 66px);
}

.sticky-align-header {
  position: sticky;
  top: 64px;
}

.icon-search {
  width: 20px;
  height: 20px;
}

.remove-title {
  display: none;
}
