.add_btn_prop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #082017;
  border-radius: 2px;
  padding: 8px 0px;
  margin-bottom: 32px;
}
.divider {
  opacity: 0.15;
  border: 0.8px solid #000000;
  width: 100%;
  margin-bottom: 35px;
}
.activity_add_contaier {
  margin-bottom: 20px;
  background: rgba(201, 216, 223, 0.1);
  border: 1px solid #c9d8df;
  border-radius: 2px;
  .header {
    display: flex;
    align-items: center;
    padding: 21px 16px;
    gap: 20px;
    border-bottom: 1px solid #c9d8df;
    margin-bottom: 23px;
    &.noborder {
      border: none;
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
    }
    input {
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 16px;
      color: #082017;
      opacity: 0.6;
    }
  }
  .property_info {
    padding: 16px;
    .input_group {
      margin-bottom: 32px;
      .title {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
      }
      .input_field {
        position: relative;
        input, textarea {
          padding: 6px 12px;
          width: 100%;
          height: 40px;
          border: 1px solid #e0e2e1;
          border-radius: 2px;
        }
        textarea{
          height: auto;
          min-height: 50px;
        }
        button {
          padding: 8px 0px;
          border: 1px solid #082017;
          border-radius: 2px;
          text-align: center;
          width: 100%;
          background: #fff;
          cursor: pointer;
        }
        &.flex_box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          input {
            width: 70%;
          }
          button {
            width: 30%;
            background-color: transparent;
          }
        }
        .select_input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
        }
        .select_box {
          height: 91px;
          padding: 8px 0px;
          border: 1px dashed #082017;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .file_container {
        margin: 22px 0;
        display: flex;
        align-items: center;
        gap: 23.6px;
        .file {
          width: 55.72px;
          height: 36px;
          position: relative;
          align-items: center;
          justify-content: center;
          display: flex;
          border: 1px solid #ddd;
          .file_img {
            width: 100%;
            height: 100%;
          }
          .close_btn {
            position: absolute;
            right: 0;
            right: -7px;
            top: -7px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.remove {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
