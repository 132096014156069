.booking_days_card {
  border: 1px solid #c9d8df;
  border-radius: 4px;
  margin-bottom: 16px;
  .booking_days {
    background: #ffffff;
    border-bottom: 1px solid #c9d8df;
    padding: 32px 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 16px;
    .from,
    .to {
      gap: 11px;
      display: flex;
      justify-content: center;
      text-align: center;
      .date_box {
        .date {
          font-weight: 500;
          font-size: 18px;
        }
        .year {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
    .days_number {
      align-self: flex-end;
      position: relative;
      // &::before {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   border: 0.5px dashed #321712;
      // }
    }
    // .days_number::before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   border: 0.5px dashed #321712;
    // }
    .from {
      .circle_box {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 1200%;
          top: 10.5px;
          left: 50%;
          border: 0.5px dashed #321712;
        }
      }
    }
  }
  .days_left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
  }
}

// .days_number::before {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   border: 0.5px dashed #321712;
// }
