.modal_container {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #082017;
    opacity: 0.5;
    z-index: 10;
  }
  padding: 30px;
  .modal_add_suspend_profile {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 4px;
    width: 657px;
    z-index: 12;
    padding: 48px;
    .close_btn {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }
    .header {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 56px;
    }
    .profile_picture_cont {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      .img_box {
        img {
          display: block;
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
      .profile_name_img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid;
        border-color: #80a07d;
        font-size: 18px;
      }
    }
    .body {
      .msg {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 32px;
      }
      .action_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        .btn_action {
          width: 108.67px;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px 8px;
          border: 1px solid #082017;
          border-radius: 2px;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
          background-color: #fff;
          &.suspend {
            background: #932a24;
            color: #fff;
          }
        }
      }
    }
  }
}
