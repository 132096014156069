.main_title {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 16px;
  color: #082017;
  font-family: 'FreightDisp Pro'
}
.sub_title {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 56px;
  color: #082017;
  opacity: 0.5;
}
.input_container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 50px;
  position: relative;
  .isd {
    position: absolute;
    top: 7.9px;
    left: 12px;
    font-weight: 400;
    font-size: 16px;
  }
  input {
    width: 347px;
    height: 40px;
    border: 1px solid #e0e2e1;
    border-radius: 2px;
    padding: 12px;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;

    color: #082017;
    &.error {
      border-color: #932a24;
    }
    &::-webkit-input-placeholder {
      color: #98a29d;
    }
    &:focus {
      border: 1px solid #80a07d !important;
      outline: none;
    }
    &:focus-visible {
      border: 1px solid #80a07d !important;
      outline: none;
    }
    .isd_active {
      padding: 12px 40px;
    }
  }

  .submit_btn {
    width: 347px;
    height: 40px;
    background: #082017;
    opacity: 0.2;
    border: none;
    outline: none;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    &.active {
      opacity: 1;
    }
  }
  .status_msg {
    position: absolute;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #932a24;
  }
}
