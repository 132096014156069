.ant-switch{
  background: #5b47474d !important;
  height: 28px !important;
  transform: scale(0.8) !important;
}
.ant-switch-handle{
  transform: scale(0.8);
}
.ant-switch-handle::before{
  transform: translateY(2px) !important; 
}
.ant-switch-checked{
  background: #80A07D !important;
  border: 1px solid #80A07D !important;
}