.chekbox_list_req {
    display: flex;
    height: 30px;
    align-items: center;
    gap: 13px;

    .radio_btn_options {
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: 0.5;
      cursor: pointer;
      .radio_btn_input_container {
        .radio_active {
          display: none;
        }
      }
      .label {
        font-weight: 600;
        font-size: 16px;
        color: #082017;
      }
      &.active {
        opacity: 1;
        .radio_active {
          display: block;
        }
        .radio_inactive {
          display: none;
        }
      }
    }
  }