.monthly_drawer {
  padding: 24px;
  .error {
    margin-top: 8px;
    color: red;
  }
  .monthly_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.1);
    .closeBtn {
      cursor: pointer;
    }
    .monthly_head_name {
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
  }
  .date {
    margin-top: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #082017;
  }
  .date_input {
    margin-top: 12px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    outline: none;
    color: #98a29d;
    .date_picker {
      padding: 10px;
      width: 100%;
    }
  }

  .completed {
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #082017;
  }
  .dropdown_task {
    margin-top: 12px;
  }

  .staff,
  .why_avg_rating {
    margin-top: 32px;
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #082017;
  }

  .pattern {
    display: flex;
    gap: 30px;
    margin-top: 26px;
    .star_pattern {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
      opacity: 0.4;
      .star_icon {
        font-size: 40px;
        color: #ffffff;
        img {
          height: 20px;
          width: 20px;
        }
        &.on {
          .start_inactive {
            display: none;
          }
          .start_active {
            display: block;
          }
        }
        &.off {
          .start_inactive {
            display: block;
          }
          .start_active {
            display: none;
          }
        }
      }
    }
  }
  .add_response {
    margin-top: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #082017;
  }
  .textarea {
    width: 100%;
    margin-top: 12px;
    outline: none;
    resize: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #082017;
    opacity: 0.6;
    border: 1px solid #082017;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 16px;
    padding-right: 30px;
    height: 110px;
  }
  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    button {
      font-weight: 600;
      font-size: 16px;
      background-color: #fff;
      cursor: pointer;
    }
  }
  .requestbuttons {
    width: 190px;
    height: 40px;
  }

  .tasks_container {
    margin-top: 20px;

    .tasklist_card_container {
      padding: 2px;
      display: inline-block;
      .task_card {
        background: #fafbfc;
        border: 1px solid #80a07d;
        border-radius: 2px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        img {
          height: 12px;
          width: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.why_avg_rating_container {
  .body {
    margin-top: 24px;
    .card_container {
      padding: 5px;
      display: inline-block;
      .card {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        background: #fafbfc;
        border: 1px solid #80a07d;
        border-radius: 2px;
        padding: 10px;
        &.active {
          background: #082017;
          color: #ffffff;
          border: 1px solid #082017;
          border-radius: 2px;
        }
      }
    }
  }
}

.modal_content {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  width: 60%;
  color: #082017;
}
