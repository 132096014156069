.property_page {
  padding: 55px;
  .header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 32px;
  }
  .drop_down_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .add_property {
      display: flex;
      justify-content: center;
      gap: 13px;
      align-items: center;
      padding: 12px 16px;
      border: 1px solid #082017;
      border-radius: 2px;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .property_custom {
      margin-right: 0;
      width: 100%;
    }
    .property_card_container {
      width: calc(33% - 13px);
    }
  }
}
