.select_owner {
  display: table;
  .owner_box {
    padding: 7px 16px;
    display: inline-flex;
    // cursor: pointer;
    align-items: center;
    border: 1px solid rgba(128, 160, 125, 1);
    direction: ltr;
    gap: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    .textName {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #082017;
      width: 100px;
      white-space: nowrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .closeIcon {
      cursor: pointer;
    }
  }
  .clear_selected {  z-index: 11;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    border-bottom: 1px solid #082017;
    color: #082017;
    opacity: 0.8;
    cursor: pointer;
  }
}
