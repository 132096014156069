.sidebar {
  height: 100%;
  .sidebar-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 14px 16px 14px;
    cursor: pointer;



    &.active {
      position: relative;
      background: #80a07d1a;
      &::before{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: #80a07d;
      }
     
    }
    &.dashboard {
      color: #7a869a;
    }
  }

  .sidebard--option-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    opacity: 0.4;

  }

  .sidebard--option-label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #7a869a;
  }
}
