.profile_page {
  .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 22px;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 32px;
  }
  .profile_body {
    padding: 64px 56px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 24px;
    .img_upload_container {
      display: flex;
      align-items: center;
      gap: 44px;
      margin-bottom: 45px;
      .upload_image {
        border-radius: 50%;
        width: 130px;
        height: 130px;
        object-fit: cover;
      }
      .action_btn_profile {
        .upload_img {
          position: relative;
          border: 1px solid #082017;
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          padding: 5px 12px;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
          }
        }
        .delet_img {
          cursor: pointer;
          font-weight: 600;
          font-size: 18px;
          color: #932a24;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
    .profile_detail_contaner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 24px;
      padding-bottom: 56px;
      .input_box {
        width: 48%;
        .title {
          font-weight: 400;
          font-size: 20px;
          margin-bottom: 12px;
        }
        input {
          display: block;
          border: 1px solid #80a07d;
          border-radius: 2px;
          padding: 6px 12px;
          font-weight: 500;
          font-size: 18px;
          width: 100%;
        }
      }
    }
    .action_btn {
      padding: 0 32px;
      padding-top: 32px;
      border-top: 0.8px solid rgba(8, 32, 23, 0.15);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      .btn,
      .btnblk {
        cursor: pointer;
        width: 111px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #082017;
        border-radius: 2px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        gap: 16px;
      }
      .btnblk {
        background: #082017;
        border-radius: 2px;
        color: #ffffff;
      }
    }
  }
  .logout_container {
    padding: 32px 56px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 16px;
      }
      .para {
        color: #082017;
        font-weight: 400;
        font-size: 16px;
        opacity: 0.5;
      }
    }
    .right {
      cursor: pointer;
      .logout_btn {
        font-weight: 600;
        font-size: 16px;
        color: #932a24;
        border: 1px solid #932a24;
        border-radius: 2px;
        width: 125px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
