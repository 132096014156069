.service_drawer {
  padding: 24px;
  .error {
    margin-top: 8px;
    color: red;
    display: block;
  }
  .service_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgba(8, 32, 23, 0.1);
    .closeBtn {
      cursor: pointer;
    }
    .service_head_name {
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #082017;
    }
  }
  .service_body {
    padding: 20px 0;
    .date {
      margin-top: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #082017;
    }
    .date_input {
      margin-top: 12px;
      width: 100%;
      //   display: flex;
      align-items: center;

      outline: none;
      color: #98a29d;
      .date_picker {
        padding: 10px;
        width: 100%;
        font-size: 16px;
      }
      margin-bottom: 20px;
    }
    .input_group {
      margin-bottom: 20px;
      .title {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
      }
      .input_field {
        position: relative;
        input {
          padding: 10px;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          border: 1px solid #e0e2e1;
          border-radius: 2px;
          height: 40px;
        }
        button {
          padding: 8px 0px;
          border: 1px solid #082017;
          border-radius: 2px;
          text-align: center;
          width: 100%;
          background: #fff;
          cursor: pointer;
        }
        &.flex_box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          input {
            width: 70%;
          }
          button {
            width: 30%;
            background-color: transparent;
          }
        }
        .select_input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
        }
        .select_box {
          height: 91px;
          padding: 8px 0px;
          border: 1px dashed #082017;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .property_detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 9px auto;
          .img_box_uplaod {
            position: relative;
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px dashed #e0e2e1;
            border-radius: 2px;
            font-size: 16px;
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
          .detail_name {
            width: 183px;
            height: 44px;
            padding: 6px 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid #e0e2e1;
            border-radius: 2px;
            font-weight: 500;
            font-size: 16px;
          }

          .cross {
            font-weight: 400;
            font-size: 12px;
          }
          .remove {
            font-weight: 600;
            font-size: 16px;
            width: 54px;
            height: 18px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .service_dd {
    width: 100% !important;
    height: 40px;
    opacity: 0.5;
  }
  .buttongroup {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    button {
      font-weight: 600;
      font-size: 16px;
      background-color: #fff;
      cursor: pointer;
    }
  }
  .requestbuttons {
    width: 190px;
    height: 40px;
  }
}

.modal_content {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  width: 60%;
  color: #082017;
}
