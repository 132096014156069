.up_coming_visit {
  width: 100%;

  .dropdown_groups {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .add_monthly_btn {
      width: 181px;
      height: 40px;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      border: 1px solid #082017;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      margin-right: 10px;
      color: #082017;
    }
 
  }
  .upcoming_cards {
    margin-top: 24px;
    flex-wrap: wrap;
    display: flex;
    gap: 16px;
    height: 340px;
    overflow-y: scroll;
    .upcoming_visit {
      width: 49%;
      padding: 24px 24px;
      height: 100px;
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #c9d8df;
      .name {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
        opacity: 0.7;
      }
      .add_btn {
        margin-top: -34px;
        cursor: pointer;
        float: right;
        width: 120px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #082017;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #082017;
      }
      .date {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        display: flex;
        align-items: center;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #082017;
      }
    }
  }
}
.date_picker {
  color: #98a29d;
  border: 1px solid #e0e2e1;
  border-radius: 2px;
  width: 175px !important;
  padding: 8px 12px;
}


