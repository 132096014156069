.property_card_detail {
  padding: 55px;
  .tool_tip {
    // background: #082017;
    // border-radius: 8px;
    // padding: 12px;
    // color: #ffffff;
    // font-weight: 500;
    // font-size: 14px;
  }

  .card_detail_header {
    .property_name {
      font-weight: 600;
      font-size: 24px;
      display: flex;
      align-items: center;
      gap: 16px;
      img {
        display: block;
        height: 32px;
        width: 32px;
        cursor: pointer;
      }
    }
    .location {
      font-weight: 400;
      font-size: 20px;
      padding-left: 36px;
    }
  }
  .care_taker_info_section {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 32px;
    .care_taker {
      width: 70%;
      height: 236px;
      border-radius: 4px;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      .details {
        width: 258.26px;

        background: #ffffff;
        border-radius: 4px;
        position: absolute;
        bottom: 24.5px;
        left: 24px;
        padding: 8px 12px;
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #082017;
          opacity: 0.6;
          margin-bottom: 12px;
        }
        .care_taker_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    .owner_part {
      width: 30%;
      .owners {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02),
          0px 2px 4px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        padding: 17px 24px;
        margin-bottom: 16px;
        .title {
          font-weight: 600;
          font-size: 16px;
          opacity: 0.8;
          margin-bottom: 9px;
        }
        .owners_complete {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .onwer_imgs {
            display: flex;
            align-items: center;
            .owner_name_img {
              background-color: #fcfcfc;
              display: flex !important;
              justify-content: center !important;
              align-items: center !important;
            }
            img,
            .owner_name_img {
              display: block;
              height: 32px;
              width: 32px;
              border-radius: 50%;
              border: 1px solid #80a07d;
            }
          }
          .view_all {
            font-weight: 500;
            font-size: 16px;
            color: #80a07d;
            cursor: pointer;
          }
        }
      }
      .upcomming_booking {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02),
          0px 2px 4px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        padding: 17px 24px;
        .title {
          font-weight: 600;
          font-size: 16px;
          opacity: 0.8;
          margin-bottom: 9px;
        }
        .view_all {
          background: #80a07d;
          border-radius: 4px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          padding: 12px 12px 10px;
          border: none;
          outline: none;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
