.property-card-main {
  width: 100% !important;
  cursor: pointer;
  height: 380px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: inline-block;
  margin-top: 26px;
  overflow: hidden;
  position: relative;
  .slider_number {
    font-weight: 600;
    font-size: 22px;
  }
  .menu_container_property {
    right: 16px;
    position: absolute;
    top: 16px;
    width: 40%;
    .vertical_menu_property {
      cursor: pointer;
      display: block;
      margin-left: auto;
    }
    .menu_container {
      position: absolute;
      background: #ffffff;
      border-radius: 2px;
      padding: 16px 12px;
      color: #082017;
      width: 100%;
      right: 0px;
      .menu {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
      }
      .divider {
        margin: 12px 0;
        width: 100%;
        border: 0.3px solid #98a29d;
      }
    }
  }

  .banner_img_property {
    width: 100%;
    object-fit: cover;
    height: 233px;
  }
  .slider_bookings {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    background: #ffffff;
    height: 62px;
    border-radius: 2px;
    top: 150px;
    left: 20px;
    padding: 0 15px;
    .slider_data {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40%;
      .slider_booking_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        gap: 9.75px;
        display: flex;
        align-items: center;
        color: rgba(8, 32, 23, 0.8);
        .booking_img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .slider_data_request {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 45%;
      .slider_booking_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        gap: 9.75px;
        display: flex;
        align-items: center;
        color: rgba(8, 32, 23, 0.8);
        .booking_img {
          width: 14px;
          height: 14px;
        }
      }
      border-right: 0.6px solid #08201733;
      border-left: 0.6px solid #08201733;
    }
  }
  .slider_heading {
    margin-left: 16px;
    margin-top: 16px;
    margin-right: 16px;
    .slider_head {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      align-items: center;
      color: #082017;
      margin-bottom: 8px;
    }
    .slider_sub_head {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      gap: 4.5px;
      align-items: center;
      color: #082017;
      margin-bottom: 8px;
      font-size: 14px;
      color: #082017;
      opacity: 0.5;
    }
  }
  .slider_footer {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
    height: auto;
    border-top: 1px solid #08201749;
    .footer_care_taker {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #082017;
      opacity: 0.5;
      margin-top: 16px;
    }
    .footer_detail {
      display: flex;
      justify-content: space-between;
      .footer_care_taker_name {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #082017;
      }
      .footer_care_taker_num {
        display: flex;
        align-items: center;
        gap: 5.33px;
        .footer_img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
