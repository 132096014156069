.setting_page {
  padding: 40px 55px;
  .header {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 50px;
  }
  .tabs_container {
    display: flex;
    align-items: center;
    gap: 40px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 55px;
    padding-bottom: 21px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .menu {
        cursor: pointer;
      position: relative;
      opacity: 0.6;
      transition: 0.2s all ;
      &::before {
        position: absolute;
        content: "";
        bottom: -22px;
        left: 0;
        width:0;
        height: 4px;
        background: #80a07d;
        border-radius: 4px;
        transition: 0.2s all ;
        cursor: pointer;
      }
     
    }
    .menu.active{
        opacity: 1;
        &::before{
            width: 100%;
        }
      }
  }
  .tab_pannel_container{
    .tab_pannel{
        display: none;
        &.active{
            display: block;
        }
    }
  }
}
