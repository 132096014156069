.user_card_parent {
  width: 33.33%;
  padding: 0 10px;
  float: left;
  .user_card {
    background: #ffffff;
    border: 1px solid #c9d8df;
    border-radius: 4px;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    height: 440px;

    .body {
      .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 13px;
        padding-top: 13px;
        margin-bottom: 0;
        img {
          cursor: pointer;
        }
      }
      .upper {
        display: flex;
        gap: 16px;
        width: 270px;
        margin: 0 20px;
        padding: 0 13px;
        .img_box {
          img {
            display: block;
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
        }
        .profile_name_img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid;
          border-color: #80a07d;
          font-size: 18px;
        }
        .name {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 12px;
        }
        .phone,
        .mail {
          gap: 8px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          text-decoration: none;
          opacity: 0.8;
          color: #082017;
          a {
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            color: #082017;
          }
        }
        .phone {
          margin-bottom: 8px;
        }
        .mail {
          margin-bottom: 31px;
        }
      }
      .divider {
        opacity: 0.08;
        border: 0.8px solid #082017;
        margin-bottom: 24px;
      }
      .lower {
        .upcomming_booking {
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 16px;
        }
        .booking_days {
          background: #ffffff;
          border: 1px solid #c9d8df;
          border-radius: 4px;
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 283.35px;
          margin: 0 auto;
          margin-bottom: 16px;
          .from,
          .to {
            gap: 11px;
            display: flex;
            justify-content: center;
            text-align: center;
            .date_box {
              .date {
                font-weight: 500;
                font-size: 18px;
              }
              .year {
                font-weight: 300;
                font-size: 14px;
              }
            }
          }
          .days_number {
            align-self: flex-end;
            width: 55%;
          }
          .from {
            .circle_box {
              position: relative;
              &::before {
                position: absolute;
                content: "";
                width: 6.9vw;
                top: 12px;
                left: 50%;
                border: 0.5px dashed #321712;
              }
            }
          }
        }
        .days_left {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          margin-bottom: 24px;
        }
      }
    }
    .footer {
      width: 100%;
      padding: 16px 24px;
      text-align: center;
      background: #082017;
      border: 1px solid #000000;
      border-radius: 0px 0px 4px 4px;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    .more_option {
      z-index: 11;
      padding: 12px;
      background: #ffffff;
      border-radius: 0px;
      width: 182px;
      position: absolute;
      /* Dropdown */
      top: 42px;
      right: 9px;
      filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))
        drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
      border-radius: 2px;
      .option {
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }

      .divider {
        border: 0.3px solid #98a29d;
        width: 100%;
        margin: 12px 0;
      }
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
    }
  }
}
